import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide
} from "@material-ui/core";
import { render } from 'react-dom'
import "_metronic/_assets/sass/zen_reminder/confirm_dialog.scss";


const Transition3 = React.forwardRef(function Transition5(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ConfirmDialog(props) {
  const [showDialog, setDialog] = useState(true);

  const okAction =() => {
    setDialog(false)
    props.okAction()
  }

  const closeAction = () => {
    setDialog(false)
    props.closeAction()
  }

  const thirdAction = () => {
    setDialog(false);
    props.thirdAction()
  }

  const fourthAction = () => {
    setDialog(false);
    props.fourthAction()
  }
  return (
    <div className='react-confirm-popup'>
      <Dialog
        classes={{ root: 'confirm-popup-container'}}
        open={showDialog}
        TransitionComponent={Transition3}
        keepMounted
        onClose={closeAction}
        maxWidth={props.maxWidth}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {props.title}
        </DialogTitle>
        {props.description && <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.description}
          </DialogContentText>
        </DialogContent>}
        <DialogActions>
          {props.cancelLabel && <Button onClick={closeAction} color="secondary" className="kt-font-transform-c">
            {props.cancelLabel}
          </Button>}
          {props.fourthLabel && <Button onClick={fourthAction} color="primary">
            {props.fourthLabel}
          </Button>}
          {props.thirdLabel && <Button onClick={thirdAction} color="primary">
            {props.thirdLabel}
          </Button>}
          <Button onClick={okAction} color="primary">
            {props.okLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmDialog.defaultProps = {
  title: "Title",
  okLabel: "Agree",
  closeAction: () => null,
  okAction: () => null,
  thirdAction: () => null,
  fourthAction: () => null,
  maxWidth: "xs"
}

export function ConfirmationPopup(props) {
  let divTarget = document.getElementById('react-confirm-popup')
  if (!divTarget){
    // Mount the ReactConfirmAlert component
    document.body.children[0].classList.add('react-confirm-popup-blur')
    divTarget = document.createElement('div')
    divTarget.id = 'react-confirm-popup'
    document.body.appendChild(divTarget)
  }
  render(<ConfirmDialog key={new Date()} {...props} />, divTarget)
}
