import firebase from "firebase/app";
import "firebase/messaging";
import axios from "axios";
import { firebaseConfig } from "firebase_db";

export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
};

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();

    await axios.post("/users/create_devices", {
      device_id: token,
      device_type: "browser",
    });

    return token;
  } catch (error) {
    console.error(error);
  }
};
