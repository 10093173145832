import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { metronic } from "../../../_metronic";
import {
  Portlet,
  PortletBody,
  PortletHeader
} from "app/partials/content/Portlet";
import { useIntl } from "react-intl";
import ProfileForm from 'app/widgets/ProfileForm'
import { Col } from "react-bootstrap";
import '_metronic/_assets/sass/zen_reminder/profile_form.scss';
import * as auth from "app/store/ducks/auth.duck";

export function ProfilePage(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { layoutConfig } = useSelector(
    ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
    shallowEqual
  );

  useEffect(() => {
    props.requestUser();
    layoutConfig.subheader.display = true
    dispatch(metronic.builder.actions.setLayoutConfigs(layoutConfig));
  });
  return (
    <>
      <div className="zen-profile-container">
        <div className="zen-profile row">
          <Col md={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }} className="card">
            <Portlet fluidHeight={true}>
              <PortletHeader
                title={intl.formatMessage({ id: "MENU.MY_PROFILE" })}
              />
              <PortletBody>
                <ProfileForm />
              </PortletBody>
            </Portlet>
          </Col>
        </div>
      </div>
    </>
  );
}

export default connect(
  null,
  auth.actions
)(ProfilePage);
