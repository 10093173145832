/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../utils/utils";
import * as builder from "../../ducks/builder";
import { appMetaData } from "../../../../package.json";
import { FormattedMessage } from "react-intl";
import { Image } from "react-bootstrap";
import { ReactComponent as AppStore } from "_metronic/layout/assets/layout-svg-icons/app-store-badge.svg";

class Footer extends React.Component {
  render() {
    const today = new Date().getFullYear();
    const {
      footerSelfLayoutIsExtended,
      footerClasses,
      footerContainerClasses
    } = this.props;
    return (
      <div
        className={`kt-footer ${footerClasses} kt-grid__item`}
        id="kt_footer"
        style={{backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-2.jpg")})`}}
      >
        {footerSelfLayoutIsExtended && false && (
          <div className="kt-footer__top">
            <div className={`kt-container ${footerContainerClasses}`}>
              <div className="row">
                <div className="col-lg-4">
                  <div className="kt-footer__section">
                    <h3 className="kt-footer__title">About</h3>
                    <div className="kt-footer__content">
                      Whatever method you chosse,these nine will make you
                      re-think theway you craft healines Why? Because they all
                      have points in that are worth from a point of view.
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="kt-footer__section">
                    <h3 className="kt-footer__title">Quick Links</h3>
                    <div className="kt-footer__content">
                      <div className="kt-footer__nav">
                        <div className="kt-footer__nav-section">
                          <a href="#">General Reports</a>
                          <a href="#">Dashboart Widgets</a>
                          <a href="#">Custom Pages</a>
                        </div>
                        <div className="kt-footer__nav-section">
                          <a href="#">User Setting</a>
                          <a href="#">Custom Pages</a>
                          <a href="#">Intranet Settings</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="kt-footer__section">
                    <h3 className="kt-footer__title">Get In Touch</h3>
                    <div className="kt-footer__content">
                      <form action="" className="kt-footer__subscribe">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Email"
                          />
                          <div className="input-group-append">
                            <button className="btn btn-brand" type="button">
                              Join
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="kt-footer__bottom" style={{backgroundColor: "transparent"}}>
          <div className={`kt-container ${footerContainerClasses}`}>
            <div className="kt-footer__wrapper">
              <div className="kt-footer__logo flex-shrink-0">
                <Link to={"/"} className="kt-header__brand-logo">
                  <img
                    alt="Logo"
                    className="kt-header__brand-logo-sticky"
                    src={toAbsoluteUrl("/media/logos/logo-4-sm.png")}
                  />
                </Link>
                <div className="kt-footer__copyright">
                  <div className="my-1">
                    {today}&nbsp;&copy;&nbsp;
                    <a href={appMetaData.siteUrl} target="_blank">
                      { appMetaData.orgName }
                    </a>
                  </div>
                  <a href={appMetaData.siteUrl + 'privacy_policy'} className="footer-mobile-link my-1" target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="MENU.PRIVACY_POLICY" />
                  </a>
                  <a href={appMetaData.siteUrl + 'contact_us'} className="footer-mobile-link my-1" target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="MENU.CONTACT" />
                  </a>
                </div>
              </div>
              <div className="kt-footer__menu align-items-center flex-wrap">
                <a href={'https://apps.apple.com/app/id1510814181'} target="_blank" rel="noopener noreferrer">
                  <AppStore className="kt-svg-icon app-store-badge" />
                </a>
                <a href={'https://play.google.com/store/apps/details?id=com.francium.zen_reminder&hl=en'} target="_blank" rel="noopener noreferrer">
                  <Image
                    className="google-pay-badge"
                    src={toAbsoluteUrl("/media/logos/google-play-badge.png")}
                  />
                </a>
                <a href={appMetaData.siteUrl + 'privacy_policy'} className="footer-desktop-link" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="MENU.PRIVACY_POLICY" />
                </a>
                <a href={appMetaData.siteUrl + 'contact_us'} className="footer-desktop-link" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="MENU.CONTACT" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  footerSelfLayoutIsExtended:
    objectPath.get(store.builder.layoutConfig, "footer.self.layout") ===
    "extended",
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true
  })
});

export default connect(mapStateToProps)(Footer);
