import React from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { metronic } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { updateLocale } from "../../crud/auth.crud";
import { languages } from "app/pages/auth/Registration";
import * as auth from "app/store/ducks/auth.duck"

export class LanguageSelector extends React.Component {
  render() {
    const { lang, setLanguage } = this.props;
    const currentLanguage = languages.find(x => x.lang === lang);
    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--langs"
        drop="down" alignRight
      >
        <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
          <span
            className="kt-header__topbar-icon"
          >
            <b>{currentLanguage.lang.toUpperCase()}</b>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround py-2">
            {languages.map(language => (
              <Dropdown.Item
                key={language.lang}
                className="kt-nav__item kt-nav__link-text"
                active={language.lang === currentLanguage.lang}
                onClick={() => {
                  updateLocale(language.lang)
                    .then(() => {
                      setLanguage(language.lang);
                      this.props.requestUser();
                    })
                    .catch((error) => {
                      console.log(error)
                    });
                }}
              >
                {language.name}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ i18n }) => ({ lang: i18n.lang });

const mapDispatchToProps = {
  ...auth.actions,
  ...metronic.i18n.actions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelector);
