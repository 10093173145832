export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboard",
        class: "mobile-icon",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        icon: 'flaticon-clipboard'
      },
      {
        title: "Calendar",
        class: "mobile-icon",
        root: true,
        alignment: "left",
        page: "calendar",
        translate: "CALENDAR.CALENDAR",
        icon: 'flaticon-calendar'
      },
      {
        title: "InvitePage",
        class: "mobile-icon",
        root: true,
        alignment: "left",
        page: "invite_page",
        translate: "MENU.PENDING_INVITES",
        icon: 'fas fa-envelope-open-text'
      },

      {
        title: "MyProfile",
        class: "only-mobile",
        root: true,
        alignment: "left",
        page: "my_profile",
        translate: "MENU.MY_PROFILE",
        icon: 'flaticon2-user-1'
      },
      {
        title: "GroupsPage",
        class: "mobile-icon",
        root: true,
        alignment: "left",
        page: "groups",
        translate: "MENU.GROUPS",
        icon: 'fas fa-user-friends'
      },

      // {
      //   title: "Custom",
      //   root: true,
      //   alignment: "left",
      //   toggle: "click",
      //   page: "builder",
      //   submenu: [
      //     {
      //       title: "Error Pages",
      //       bullet: "dot",
      //       icon: "flaticon2-warning",
      //       page: "error/error-v6",
      //       submenu: [
      //         {
      //           title: "Error 6",
      //           page: "error/error-v6"
      //         }
      //       ]
      //     }
      //   ]
      // }
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "My Profile",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "my_profile",
        translate: "MENU.MY_PROFILE",
        bullet: "dot"
      },
      // {
      //   title: "Error Pages",
      //   root: true,
      //   bullet: "dot",
      //   icon: "flaticon-danger",
      //   page: "error/error-v1",
      //   submenu: [
      //     {
      //       title: "Error Page - 6",
      //       page: "error/error-v6"
      //     }
      //   ]
      // }
    ]
  }
};
