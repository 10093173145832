import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../_metronic";
import Login from "./Login";
import Registration from "./Registration";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
import { appMetaData } from "../../../../package.json"
import { metronic } from "../../../_metronic";
import { connect } from "react-redux";
import { Image } from "react-bootstrap";
import { ReactComponent as AppStore } from "_metronic/layout/assets/layout-svg-icons/app-store-badge.svg";

function AuthPage(props) {
  const [background, setBackground] = useState('/media/bg/sign-in.jpg');
  const { lang, setLanguage } = props;
  useEffect(() => {
    if(lang !== "en") {
      setLanguage("en");
    }
    // eslint-disable-next-line
  }, []);
  const changeBackground =(path) => {
    setBackground(path)
  }
  return (
      <>
        <div className="d-flex flex-column align-items-center zen-auth-page" style={{background: `url(${background})`}}>
          <div className="zen-auth-content d-flex flex-column justify-content-center">
            <div className="align-self-center w-100 m-4">
              <div className="card zen-card-shadow">
                <img
                  alt="Logo"
                  height="80"
                  width="80"
                  src={toAbsoluteUrl("/media/logos/logo-mini-md.png")}
                  className="mb-4 align-self-center"
                />
                <Switch>
                  <Route path="/auth/login" render={routeProps => (
                    <Login {...routeProps} changeBackground={changeBackground}/>
                  )}/>
                  <Route path="/auth/registration" render={routeProps => (
                    <Registration {...routeProps} changeBackground={changeBackground}/>
                  )}/>
                  <Route path="/auth/reset-password" render={routeProps => (
                    <ResetPassword {...routeProps} changeBackground={changeBackground}/>
                  )}/>
                  <Route path="/auth/forgot-password" render={routeProps => (
                    <ForgotPassword {...routeProps} changeBackground={changeBackground}/>
                  )}/>
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <a href={'https://play.google.com/store/apps/details?id=com.francium.zen_reminder&hl=en'} target="_blank" rel="noopener noreferrer">
                <Image
                  className="google-pay-badge"
                  src={toAbsoluteUrl("/media/logos/google-play-badge.png")}
                />
              </a>
              <a href={'https://apps.apple.com/app/id1510814181'} target="_blank" rel="noopener noreferrer">
                <AppStore className="kt-svg-icon app-store-badge" />
              </a>
            </div>
          </div>

          <div className="zen-auth-footer my-4">
            <div className="text-white d-flex justify-content-between">
              <div className="w-50">
                <h6>&copy; 2020 { appMetaData.orgName }</h6>
              </div>
              <a href={appMetaData.siteUrl + 'privacy_policy'} target="_blank" rel="noopener noreferrer" className="kt-link kt-link--light text-white">
                <h6>Privacy</h6>
              </a>
              <a href={appMetaData.siteUrl + 'contact_us'} target="_blank" rel="noopener noreferrer" className="kt-link kt-link--light text-white">
                <h6>Contact</h6>
              </a>
            </div>
          </div>
        </div>
      </>
  );
}

const mapStateToProps = ({ i18n }) => ({ lang: i18n.lang });

export default injectIntl(
  connect(
    mapStateToProps,
    metronic.i18n.actions
  )(AuthPage)
);
