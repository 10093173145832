import React, { useEffect } from "react";
import HomeCalendar from "app/widgets/HomeCalendar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { metronic } from "../../../_metronic";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { layoutConfig } = useSelector(
    ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
    shallowEqual
  );

  useEffect(() => {
    layoutConfig.subheader.display = false
    dispatch(metronic.builder.actions.setLayoutConfigs(layoutConfig));
  });
  return (
    <>
      <HomeCalendar view='agenda'/>
    </>
  );
}
