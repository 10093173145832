import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

const CustomToolbar = (props) => {

  const { intl } = props;
  let pageNav;
  let viewToday = null;
  let dateRange = null;
  if (props.calView === 'agenda') {
    pageNav = (
      <div className="btn-group btn-group-toggle flex-child page_nav agenda_toolbar c">
        <label className="btn btn-outline-light btn-pill">
          <input type="radio" name="options" onClick={props.goToCurrent} />
          {intl.formatMessage({ id: "CALENDAR.TODAY" })}
        </label>
        <label className="btn btn-outline-light btn-pill">
          <input type="radio" name="options" onClick={props.goToBack} />
          {intl.formatMessage({ id: "CALENDAR.PREVIOUS" })}
        </label>
        <label className="btn btn-outline-light btn-pill">
          <input type="radio" name="options" onClick={props.goToNext} />
          {intl.formatMessage({ id: "CALENDAR.NEXT" })}
        </label>
      </div>
    )
    dateRange = props.dateRange
  } else {
    pageNav = (
      <div className="flex-child page_nav">
        <label className="nav_month" onClick={props.goToBack}>
          {'<'}
        </label>
        <label className="month_txt">
          {props.month}&nbsp;&nbsp;&nbsp;{props.year}
        </label>
        <label className="nav_month" onClick={props.goToNext}>
          {'>'}
        </label>
      </div>
    )
    viewToday = (
      <button
        type="button"
        className="cal_today btn btn-outline-light btn-pill"
        onClick={props.goToCurrent}
      >
        {intl.formatMessage({ id: "CALENDAR.TODAY" })}
      </button>
    )
  }
  return (
    <div className="cal_toolbar">
      <div className="row_2">
        {pageNav}
        <div className="flex-grow-1 date_range">
          {dateRange}
        </div>
        <div className={props.calView !== 'agenda'? "d-flex": ""}>
          {viewToday}
          <button
            type="button"
            className="add_event_btn btn btn-outline-light btn-pill"
            onClick={() => props.addNewEvent() }
          >
            <span>+</span>
            <FormattedMessage id="CALENDAR.NEW_EVENT" />
          </button>
        </div>
      </div>
    </div >
  );
};

const mapStateToProps = (value) => ({ lang: value?.i18n?.lang });

export default injectIntl(connect(mapStateToProps, null)(CustomToolbar));
