import React, { useEffect, useState } from "react";
import HomeCalendar from "app/widgets/HomeCalendar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { metronic } from "../../../_metronic";

export default function CalendarNotification(props) {
  const dispatch = useDispatch();
  const { layoutConfig } = useSelector(
    ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
    shallowEqual
  );
  const [reminderId, setReminderId] = useState('');
  const [eventDate, setEventDate] = useState('');

  useEffect(() => {
    layoutConfig.subheader.display = false
    dispatch(metronic.builder.actions.setLayoutConfigs(layoutConfig));
    setReminderId(props.match.params.reminder_id);
    setEventDate(props.match.params.event_date);
  }, [props.match.params.reminder_id, props.match.params.event_date, dispatch, layoutConfig]);

  const changeUrlBackToCalendar = () => {
    window.history.pushState({}, null, "/calendar");
  }

  return (
    <>
      <HomeCalendar view='month' reminder_id={reminderId} eventDate={eventDate} urlChange={changeUrlBackToCalendar} />
    </>
  );
}
