import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import { Notifier } from "@airbrake/browser";
import airbrakeMiddleware from "redux-airbrake";
import LogRocket from "logrocket";

import { rootReducer, rootSaga } from "./rootDuck";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const airbrake = new Notifier({
  projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID || "dummy_id",
  projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY || "dummy_key",
  environment: process.env.NODE_ENV,
});

const errorMiddleware = airbrakeMiddleware(airbrake, {
  sendLastAction: true,
  sendState: true,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      errorMiddleware,
      LogRocket.reduxMiddleware(),
      sagaMiddleware
    )
  )
);

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;
