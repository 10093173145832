/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";

class UserProfile extends React.Component {
  render() {
    const { user, showHi, showAvatar, showBadge } = this.props;
    const profilePage = this.props.location.pathname === "/my_profile"

    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div
            className="kt-header__topbar-wrapper"
            data-toggle="dropdown"
            data-offset="10px,0px"
          >
            {showAvatar && <img alt="Pic" src={user.pic} />}

            {showBadge && (
              <span className="kt-header__topbar-icon">
                {/* TODO: Should get from currentUser */}
                <b>{user && user.full_name[0]}</b>
              </span>
            )}
            {showHi && (
              <Link
                to="/my_profile"
                className={`kt-header__topbar-username ${clsx(
                  {
                    "show": profilePage,
                  }
                )}`}
              >
                {user.first_name}
              </Link>
            )}

            <span className="kt-header__topbar-username mr-0 p-0">
              <Link
                to="/logout"
                className="btn btn-pill btn-outline-light sign_out_btn"
              >
                <FormattedMessage id="MENU.SIGN_OUT" />
              </Link>
            </span>
          </div>
        </Dropdown.Toggle>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default withRouter(connect(mapStateToProps)(UserProfile));
