import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export function reminderDisplayKey(is_group_reminder, is_shared_reminder) {
  if(is_group_reminder)
    return "CALENDAR.CREATED_BY"
  else if (is_shared_reminder)
    return "CALENDAR.SHARED_BY"
  else
    return false
};

export function toInputLowercase(e) {
  e.target.value = e.target.value.toLowerCase();
};

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const passwordRegexCheck = (value) => {
  let error = null
  if (!value) {
    error =  "AUTH.VALIDATION.REQUIRED_FIELD"
  }else if(value.length < 8){
    error = "AUTH.VALIDATION.PASSWORD_MAX_CHAR"
  }else if (
    /\s/.test(value)
  ) {
    error = "AUTH.VALIDATION.PASSWORD_SPACE"
  }else if (
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value)
  ) {
    error = "AUTH.VALIDATION.PASSWORD_REGEX"
  }
  return error;
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const colors = [
  { name: "color-0", code: "#422798", iconColor: "blue-shade-6" },
  { name: "color-1", code: "#e6494e", iconColor: "danger" },
  { name: "color-2", code: "#0076a3", iconColor: "green-shade-7" },
  { name: "color-3", code: "#f57d58", iconColor: "orangish" },
  { name: "color-4", code: "#992B7B", iconColor: "purple-color" },
  { name: "color-5", code: "#00a99d", iconColor: "success" },
];

export function emailValidator(email) {
  return /^$|^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
}

export function setupAxios(axios, airbrake) {
  axios.defaults.baseURL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_BASE_URL + "/api"
      : "http://localhost:3000/api";
  axios.defaults.withCredentials = true;
  axios.defaults.headers["Cache-Control"] = "no-cache";
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(function(config) {
    config.headers.TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  });

  const renderLogin = async () => {
    return window.location.replace("/logout");
  };

  axios.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      if (
        history.location.pathname !== "/auth/reset-password" &&
        history.location.pathname !== "/auth/login" &&
        history.location.pathname !== "/logout" &&
        window.location.pathname !== "/auth/login" &&
        error.response.status === 401
      ) {
        renderLogin();
      } else if (
        error.response.data.message === "Different Time Zone" &&
        error.response.status === 409
      ) {
        window.location = "/my_profile";
      } else {
        airbrake.notify(error);
        return Promise.reject(error);
      }
    }
  );
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from localStorage: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
        key +
        "] in localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}
