import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login, getCurrentUser } from "../../crud/auth.crud";
import LogRocket from 'logrocket';
import { metronic, toAbsoluteUrl } from "../../../_metronic";
import cogoToast from "cogo-toast";
import { Flip } from 'react-reveal';
import { toInputLowercase } from "_metronic/utils/utils";

function Login(props) {
  const { intl, setLanguage } = props;
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });

  const formikInititalSatus =
    props.location.state === undefined ? "" : props.location.state.message;

  useEffect(() => {
    const locale = (new URLSearchParams(window.location.search)).get("locale")
    if (locale)
      setLanguage(locale)
    var params = (new URLSearchParams(window.location.search)).get("email_confirmed")
    if(params === 'true') {
      cogoToast.success(intl.formatMessage({ id: "AUTH.EMAIL_CONFIRMATION.SUCESS" }), {
        heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
    }
    else if(params === 'false') {
      cogoToast.error(intl.formatMessage({ id: "AUTH.EMAIL_CONFIRMATION.FAILURE" }), {
        heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
    }
    props.requestUser();
    props.changeBackground('/media/bg/sign-in.jpg')
  }, [props, intl, setLanguage]);

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  return (
    <>
      <div>
        <div className="text-dark text-center mb-3">
          <h3 className="font-weight-bold">
            {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
        </div>
        <Formik
          initialStatus={formikInititalSatus}
          initialValues={{
            email: "",
            password: "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.email) {
              // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_FIELD",
              });
            }

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            enableLoading();
            login(values.email, values.password)
              .then((response) => {
                disableLoading();
                props.login(response.data.data.attributes.remember_token);
                if (process.env.NODE_ENV === "production") {
                  getCurrentUser().then((res)=>{
                    LogRocket.identify(response.data.data.attributes.id, {
                      name: response.data.data.attributes.full_name,
                      email: response.data.data.attributes.email,
                      time_zone: response.data.data.attributes.time_zone
                    });
                  });
                }
                setLanguage(response.data.data.attributes.locale)
              })
              .catch((error) => {
                disableLoading();
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: error.response?.data?.message !== undefined ? error.response?.data?.message : "GENERAL.ERROR_MESSAGE"
                  })
                );
              });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Flip top>
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group mb-0">
                  <TextField
                    type="email"
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onInput={toInputLowercase}
                    value={values.email}
                    helperText={errors.email ? errors.email : " "}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    type={showPassword ? "text" : "password"}
                    margin="normal"
                    label="Password"
                    variant="outlined"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password ? errors.password : " "}
                    error={Boolean(touched.password && errors.password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={handleShowPassword}
                          >
                            {showPassword ? (
                              <i className="la la-eye" />
                            ) : (
                              <i className="la la-eye-slash" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                {/* <div className="kt-login__actions"> */}
                <div className="form-group px-3">
                  <Link
                    to="/auth/forgot-password"
                    className="kt-link kt-link--blue-shade-6 text-primary"
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>
                </div>

                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={isSubmitting}
                  className={`w-100 btn btn-pill btn-primary btn-elevate ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                    }
                  )}`}
                  style={loadingButtonStyle}
                >
                  <h4>Sign In</h4>
                </button>
              </form>
            </Flip>
          )}
        </Formik>

        <div className="my-3 text-center text-dark">
          <span >
            Don't have an account yet?
          </span>
          &nbsp;&nbsp;
          <Link to="/auth/registration" className="kt-link kt-link--primary text-primary ">
            Sign Up!
          </Link>
        </div>
        <div className="my-3">
          <div className="kt-divider">
            <span />
            <span>OR</span>
            <span />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          {/* <Link to="http://facebook.com" className="btn btn-primary kt-btn">
            <i className="fab fa-facebook-f" />
            Facebook
          </Link>
          <Link to="http://twitter.com" className="btn btn-info kt-btn">
            <i className="fab fa-twitter" />
            Twitter
          </Link> */}
          <a
            href={process.env.REACT_APP_BASE_URL + "/auth/google"}
          >
            <img
              alt="Logo"
              height="30"
              width="30"
              src={toAbsoluteUrl("/media/icons/google-icon.png")}
              className="align-self-center mr-4"
            />
          </a>
          <a
            href={process.env.REACT_APP_BASE_URL + "/auth/apple"}
          >
            <img
              alt="Logo"
              height="30"
              width="30"
              src={toAbsoluteUrl("/media/icons/apple-icon.png")}
              className="align-self-center ml-4"
            />
          </a>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  ...auth.actions,
  ...metronic.i18n.actions,
};

export default injectIntl(connect(null, mapDispatchToProps)(Login));
