import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { requestPassword } from "../../crud/auth.crud";
import { Flip } from 'react-reveal';
import { toInputLowercase } from "_metronic/utils/utils";

class ForgotPassword extends Component {
  state = { hasError: true }
  componentDidMount() {
    this.props.changeBackground('/media/bg/forgot-password.jpg')
  }

  render() {
    const { intl } = this.props;

    return (
      <div>
        <div className="text-center text-dark mb-3">
          <h3 className="font-weight-bold">
            <FormattedMessage id="AUTH.FORGOT.TITLE" />
          </h3>
        </div>

        <Formik
            initialValues={{ email: "" }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              requestPassword(values.email)
                  .then((res) => {
                    setSubmitting(false);
                    this.setState({hasError: false})
                    setStatus(
                        intl.formatMessage({ id: res.data.message })
                    );
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    this.setState({hasError: true})
                    setStatus(
                        intl.formatMessage(
                            { id: error.response.data.message },
                            { name: values.email }
                        )
                    );
                  });
            }}
        >
          {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Flip top>
                <form onSubmit={handleSubmit} className="kt-form">
                  {status && (
                    <div role="alert" className={`alert alert-${this.state.hasError ? 'danger' : 'success'}`}>
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group mb-2">
                    <TextField
                      type="email"
                      label="Email"
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onInput={toInputLowercase}
                      value={values.email}
                      helperText={touched.email && errors.email ? errors.email : " "}
                      error={Boolean(touched.email && errors.email)}
                    />
                  </div>

                  <div className="d-flex zen-custom-button">
                    <Link to="/auth" className="flex-fill mr-3">
                      <button
                        type="button"
                        className="btn btn-pill btn-outline-dark btn-elevate kt-login__btn-secondary w-100"
                      >
                        Back
                      </button>
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-pill btn-primary btn-elevate kt-login__btn-primary flex-fill ml-3"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </Flip>
            )
          }
        </Formik>
      </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
