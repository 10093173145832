import React from "react";
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import {
  Portlet,
  PortletBody
} from "app/partials/content/Portlet";
import axios from "axios";
import CalendarForm from "app/widgets/CalendarForm";
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/de';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomToolbar from './CustomToolbar';
import EventList from './EventList';
import SkeletonLoader from "tiny-skeleton-loader-react";
import { colors, toAbsoluteUrl } from "_metronic/utils/utils"
import { askForPermissioToReceiveNotifications } from "_metronic/utils/push_notification";
import { Dialog, DialogTitle, DialogContent, Zoom, Badge } from "@material-ui/core";
import { reminderDisplayKey } from "_metronic/utils/utils";

const localizer = momentLocalizer(moment);
const todaysEvents = 'CALENDAR.TODAYS_EVENTS'
const eventsLabel = 'CALENDAR.EVENTS'
let showDefaultDate = true;
const Transition = React.forwardRef(function Transition5(props, ref) {
  return <Zoom ref={ref} {...props} />;
});
let formKey = 0;
let shareKey = 0;

class HomeCalendar extends React.Component {
  state = { data: [], showModal: false, selectedDate: null, event: {}, range: {}, dateRange: '', start: null, end: null, pastEvent: false, filteredEvents: [], eventTitle: todaysEvents, currentDate: null, currentMonth: '', defaultDate: new Date(), monthLabel: '', loading: true, currentYear: '', tabModal: 'edit' }
  async componentDidMount() {
    await this.fetchData({}, true);
    await this.openNotification();
    askForPermissioToReceiveNotifications();
  }


  addDatatoState = (value) => {
    let data = this.convertDateFormat(value)
    this.setState(previousState => ({
      data: [...previousState.data, ...data]
    }));
  }

  openNotification = () => {
    const { reminder_id, eventDate } = this.props;
    if(!!reminder_id && !!eventDate) {
      const notifiedEvent = this.state.data.find(r => r.id === reminder_id)
      if(!!notifiedEvent) {
        this.setState({ showModal: true, event: notifiedEvent, selectedDate: new Date(eventDate), pastEvent: true });
        this.props.urlChange();
      }
    }
  }

  convertDateFormat = (values) => {
    return values.map(event => {
      return {
        id: event.attributes.id,
        title: event.attributes.title,
        start: new Date(event.attributes.start),
        end: new Date(event.attributes.end),
        start_date: new Date(event.attributes.start_date),
        end_date: event.attributes.end_date ? new Date(event.attributes.end_date) : null,
        group_id: event.attributes.group_id,
        category_id: event.attributes.category_id,
        provider_id: event.attributes.provider_id,
        group_name: event.attributes.group_name,
        category_name: event.attributes.category_name,
        repeat_type: event.attributes.repeat_type,
        trigger_period: event.attributes.trigger_period,
        notify_via_email: event.attributes.notify_via_email,
        notify_via_telegram: event.attributes.notify_via_telegram,
        color: event.attributes.color,
        is_shared_reminder: event.attributes.is_shared_reminder,
        is_group_reminder: event.attributes.is_group_reminder,
        user_full_name: event.attributes.user_full_name,
        shares_count: event.attributes.shares_count,
        custom_recurrence: event.attributes.custom_recurrence
      }
    })
  }

  isPastEvent = (event_date) => {
    return event_date < new Date();
  };

  handleSelect = ({ start, end, action }) => {
    if (action === undefined) {
      return;
    }
    if (action === 'doubleClick') {
      if(start >= moment().startOf('day')){
        this.setState({ selectedDate: this.initialStartDate(start), showModal: true, event: {} })
      }
    } else {
      showDefaultDate = true;
      this.setState({defaultDate: start})
      this.setEventList(start);
    }
  };

  setEventList = (start) => {
    let currentDate = moment(start).format("DD/MM/YYYY")
    let filteredEvents = []
    if (showDefaultDate) {
      filteredEvents = this.getEventList(currentDate)
    }
    else {
      [filteredEvents, currentDate] = this.dateWithEvents(currentDate)
      showDefaultDate = true
    }
    let title;
    if (currentDate === moment().format("DD/MM/YYYY")) {
      title = todaysEvents
    } else {
      title = eventsLabel
    }
    this.setState({
      defaultDate: moment(currentDate, "DD/MM/YYYY"),
      currentDate: moment(currentDate, "DD/MM/YYYY").format("DD"),
      filteredEvents: filteredEvents,
      eventTitle: title,
      monthLabel: moment(currentDate, "DD/MM/YYYY").format('MMM')
    })
  }

  getEventList = (date) => {
    return this.state.data.filter(
      event => moment(event.start).format("DD/MM/YYYY") === date
    )
  }

  dateWithEvents = (date) => {
    let eventList = []
    let monthStart = moment(date, "DD/MM/YYYY").startOf('month').format("DD/MM/YYYY")
    let currentMonth = moment(date, "DD/MM/YYYY").month()
    while(currentMonth === moment(monthStart, "DD/MM/YYYY").month()) {
      eventList = this.getEventList(monthStart)
      if (eventList.length !== 0) {
        return [eventList, monthStart]
      }
      monthStart = moment(monthStart, "DD/MM/YYYY").add(1, 'day').format("DD/MM/YYYY")
    }
    return [[], moment(date, "DD/MM/YYYY").startOf('month').format("DD/MM/YYYY")]
  }

  clonePastReminder = (event) => {
    this.closeModal()
    this.setState({ showModal: true, event: event, selectedDate: this.initialStartDate(event.start, true), pastEvent: false })
  }

  openPopup = (event) => {
    const isPastEvent = this.isPastEvent(event.start);
    this.setState({ showModal: true, event: event, selectedDate: event.start, pastEvent: isPastEvent, tabModal: 'share' });
  }

  closeModal = () => {
    if(!this.state.event.id){
      formKey += 1;
    }
    else {
      shareKey +=1;
    }
    this.setState({ showModal: false, selectedDate: null, pastEvent: false });
  }

  addNewEvent = () => {
    const date = this.state.defaultDate._d
    this.setState({ showModal: true, event: {}, selectedDate: this.initialStartDate(date), pastEvent: false })
  }

  initialStartDate = (date, cloning = false) => {
    let currentDateTime = new Date()
    if (moment(date).isSameOrBefore(currentDateTime, 'day'))
      return moment(currentDateTime).add(1, 'hours').set('minute', 0);
    else
      return cloning ? date : moment(date).set('hour', 9).set('minute', 0)
  }

  onSelectEvent = (event) => {
    const isPastEvent = this.isPastEvent(event.start);
    this.setState({ showModal: true, event: event, selectedDate: event.start, pastEvent: isPastEvent, tabModal: 'edit' });
  };

  fetchData = async(value={}, defaultDate = false) => {
    let params = value
    if(value.length === 7){
      params = {start: value[0], end: moment(value[6]).add(1, 'days')._d}
    } else if(value.length === 1) {
      params = {start: value[0], end: moment(value[0]).add(1, 'days')._d}
    }
    if (typeof(params) === 'object' && Object.keys(params).length === 0) {
      params['end'] = moment().add(1, 'M')
    }
    let startDate = moment(params['start']).format('DD/MM/YYYY');
    let endDate = moment(params['end']).format('DD/MM/YYYY');
    let dateRange = `${startDate} - ${endDate}`;
    showDefaultDate = defaultDate;
    this.setState({
      range: params,
      dateRange: dateRange
    })
    if (this.state.defaultDate === null) {
      this.setState({defaultDate: new Date()})
    }
    if (this.state.currentMonth === '') {
      this.setState({
        currentMonth: moment().format('MMMM'),
        currentYear:  moment().format('YYYY'),
        monthLabel: moment().format('MMM')
      });
    }
    await axios.get('/reminders', { params: params }).then((res)=>{
      if(res.data.data !== undefined){
        let data = this.convertDateFormat(res.data.data)
        this.setState({data: data, loading: false})
        this.setEventList(this.state.defaultDate);
      }
    }).catch((error)=>{
      console.log(error);
    });
  };

  changeShareCount = (count) => {
    let event = this.state.event
    let events = this.state.data.map(evt => {
      if(evt.id === event.id){
        evt.shares_count = count;
      }
      return evt;
    })
    this.setState({data: events})
  }

  onRangeChange = (value) => {
    this.fetchData(value, showDefaultDate)
  }

  onShowMore = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }
  myAgendaComponentWithProps = (eventProps) => {
    return (
      <div>
        <div className="d-flex">
          <span className="mx-2 zen-cal-m-t"><i className={`fas fa-circle kt-font-${colors[eventProps.event.color]?.iconColor}`} /></span>
          <span className="w-100 text-center agenda-mobile-view">{moment(eventProps.event.start).format('hh:mm A')}</span>
          <span className="flex-fill align-self-center agenda-desktop-view">{eventProps.event.title}</span>
          {(!eventProps.event.is_shared_reminder && !eventProps.event.is_group_reminder && !this.isPastEvent(eventProps.event.start)) &&
            <div className="event_action">
              <span
                className="kt-header__topbar-icon kt-pulse"
                onClick={() => this.openPopup(eventProps.event)}
                >
                <Badge badgeContent={eventProps.event.shares_count} color="secondary">
                  <i className="fas fa-user-plus" />
                </Badge>
              </span>
            </div>
          }
          {!!reminderDisplayKey(eventProps.event.is_group_reminder, eventProps.event.is_shared_reminder) &&
            <div className="agenda-desktop-view align-self-center kt-font-md pr-1">
              <span className="font-weight-light">
                <FormattedMessage
                  id={`${reminderDisplayKey(eventProps.event.is_group_reminder, eventProps.event.is_shared_reminder)}`}
                />
              </span>
              <span className="font-weight-normal">{`: ${eventProps.event.user_full_name}`}</span>
            </div>
          }
          <span  className="event_action flex-shrink-0">
            <span
              className="kt-header__topbar-icon kt-pulse"
              onClick={() => this.onSelectEvent(eventProps.event)}
            >
              <i className="flaticon2-edit" />
            </span>
          </span>
        </div>
        <div className="w-100 text-center agenda-mobile-view">
          <span>{eventProps.event.title}</span>
        </div>
      </div>
    )
  }

  myMonthComponentWithProps = (eventProps) => {
    return (
      <div>
        <div className="d-flex align-items-center">
          <span className="mr-1"><i className={`fas fa-circle kt-font-${colors[eventProps.event.color]?.iconColor}`} /></span>
          <span className="text-truncate flex-shrink-1">{eventProps.event.title}</span>
        </div>
      </div>
    )
  }

  render() {
    const { intl } = this.props;
    moment.locale(intl.locale);
    let loader = Array.apply(null, { length: 8 }).map((e, i) => (
      <SkeletonLoader className="skeleton-loader" key={'loader'+i} width="100%" height="80px"/>
    ));
    let noData = (
      <div className="calendar-no-data text-dark">
        <div className="d-flex flex-column align-items-center w-100 p-2">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/bg/agenda-no-reminder.png")}
            className="align-self-center"
          />
          <span className="my-2 text-center"><FormattedMessage id={ "CALENDAR.NO_EVENT" }/></span>
          <button type="button" className="mx-3 my-4 btn btn-blue-shade-6 btn-pill" onClick={() => this.addNewEvent()}>
            <span className="pr-3">+</span>
            <span><FormattedMessage id="CALENDAR.NEW_EVENT" /></span>
          </button>
        </div>

      </div>
    );
    const messages = {
      date: intl.formatMessage({ id: "CALENDAR.DATE" }),
      time: intl.formatMessage({ id: "CALENDAR.TIME" }),
      event: intl.formatMessage({ id: "CALENDAR.EVENT" }),
      showMore: (total) => <span style={{ cursor: 'pointer' }} onClick={(e) => this.onShowMore(e)}>
        +{total} {intl.formatMessage({ id: "CALENDAR.MORE" })}
      </span>,
      noEventsInRange: this.state.loading ? loader : noData
    };
    const CalendarToolbar = (toolbar) => {
      const monthYear = toolbar.label.split(' ');
      let defaultDate = showDefaultDate ? this.state.defaultDate : moment(toolbar.date).startOf('month').format("YYYY-MM-DD");
      let monthLabel = moment(toolbar.date).format("MMM");
      if(this.state.currentMonth !== monthYear[0] || this.state.currentYear !== monthYear[1] || this.state.defaultDate.toString() !== defaultDate.toString()){
        this.setState({
          currentMonth: monthYear[0],
          currentYear: monthYear[1],
          defaultDate: defaultDate,
          monthLabel: monthLabel
        })
      }

      const goToBack = () => {
        this.setState({data: []});
        showDefaultDate = false;
        this.setState({loading: true});
        toolbar.onNavigate('PREV');
      };

      const goToNext = () => {
        this.setState({data: []});
        showDefaultDate = false;
        this.setState({loading: true});
        toolbar.onNavigate('NEXT');
      };

      const goToCurrent = () => {
        showDefaultDate = true;
        this.setState({loading: true, defaultDate: new Date()});
        toolbar.onNavigate('TODAY');
      };

      return (
        <CustomToolbar
          goToBack={goToBack}
          goToNext={goToNext}
          goToCurrent={goToCurrent}
          addNewEvent={this.addNewEvent}
          calView={this.props.view}
          month={this.state.currentMonth}
          year={this.state.currentYear}
          dateRange={this.state.dateRange}
        />
      );
    };
    return(
      <Portlet fluidHeight={true} className="home_cal">
        <PortletBody>
          <div className={this.props.view === 'month' ? "row" : ''}>
            <div className={this.props.view === 'month' ? 'calendar_view col-md-9 col-sm-12' : 'agenda_view'}>
              <Calendar
                selectable
                className={this.state.loading ? "home-calendar loading-month" : `home-calendar ${this.props.view}`}
                culture={this.props.lang}
                localizer={localizer}
                events={this.state.data}
                scrollToTime={new Date(2015, 1, 1)}
                onSelectEvent={this.onSelectEvent}
                onSelectSlot={this.handleSelect}
                onRangeChange={this.onRangeChange}
                messages={messages}
                view={this.props.view}
                onView={() => {}}
                components={{
                  toolbar: CalendarToolbar,
                  agenda: { event: this.myAgendaComponentWithProps },
                  month: { event: this.myMonthComponentWithProps }
                }}
              />
            </div>
            { this.props.view === 'month' &&
              <EventList
                isPastEvent={this.isPastEvent}
                selectedDate={this.state.currentDate}
                filteredEvents={this.state.filteredEvents}
                eventTitle={intl.formatMessage({ id: this.state.eventTitle })}
                editEvent={this.onSelectEvent}
                month={this.state.monthLabel}
                loading={this.state.loading}
                loader={loader}
                openPopup={this.openPopup}
              />
            }
          </div>
        </PortletBody>
        <Dialog
          open={this.state.showModal}
          onClose={this.closeModal}
          scroll={'paper'}
          maxWidth="xs"
          fullWidth
          keepMounted
          TransitionComponent={Transition}
          className="calendar-form-dailog home_cal"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <FormattedMessage
              id={
                (this.state.pastEvent || this.state.event.is_shared_reminder || this.state.event.is_group_reminder)
                  ? "CALENDAR.EVENT"
                  : this.state.event.id
                  ? "CALENDAR.UPDATE_EVENT"
                  : "CALENDAR.CREATE_EVENT"
                }
              />
              {!!reminderDisplayKey(this.state.event.is_group_reminder, this.state.event.is_shared_reminder) &&
                <span>
                  {" "}(<FormattedMessage id={`${reminderDisplayKey(this.state.event.is_group_reminder, this.state.event.is_shared_reminder)}`} />
                  {` ${this.state.event.user_full_name})`}
                </span>
              }
            <span className="event_action float-right">
              <span onClick={this.closeModal}><i className={`fas fa-times`} /></span>
            </span>
          </DialogTitle>
          <DialogContent dividers={true} className="py-0">
            <CalendarForm
              clonePastReminder={this.clonePastReminder}
              key={this.state.event.id ? "calendar-form-"+this.state.event.id : "calendar-form-"+formKey.toString()}
              onHide={this.closeModal}
              shareKey={shareKey}
              addData={this.addDatatoState}
              selectedDate={this.state.selectedDate}
              fetchData={this.fetchData}
              event={this.state.event}
              range={this.state.range}
              deleteReminder={this.deleteReminder}
              pastEvent={this.state.pastEvent}
              setEventList={this.setEventList}
              changeShareCount={this.changeShareCount}
              tabModal={this.state.tabModal === 'edit' ? 0 : 1}
            />
          </DialogContent>
        </Dialog>
      </Portlet>
    );
  }
}

const mapStateToProps = (value) => ({ lang: value?.i18n?.lang });

export default injectIntl(connect(mapStateToProps, null)(HomeCalendar));
