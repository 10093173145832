import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector, connect } from "react-redux";
import { metronic } from "../../../_metronic";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "app/partials/content/Portlet";
import { useIntl, FormattedMessage } from "react-intl";
import { Col, Row, Card } from "react-bootstrap";
import '_metronic/_assets/sass/zen_reminder/profile_form.scss';
import axios from "axios";
import cogoToast from "cogo-toast";
import GroupForm from "app/widgets/GroupForm";
import SkeletonLoader from "tiny-skeleton-loader-react";
import { Tooltip, withStyles, makeStyles } from "@material-ui/core";
import { ConfirmationPopup } from "app/widgets/ConfirmDialog";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyle = makeStyles({
  shadowEffect: {
    boxShadow: "inset 1px -10px 10px -6px #7b707040",
  }
})

export function GroupsPage(props) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const { layoutConfig } = useSelector(
    ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
    shallowEqual
  );

  const CustomTooltip = withStyles(() => ({
    tooltip: {
      textAlign: 'center',
      fontSize: 11,
    },
  }))(Tooltip);

  const changeMemberCount = () => {
    let updatedGroups = groups.map(grp => {
      if(grp.attributes.id === selectedGroup.attributes.id){
        grp.attributes.group_users_count -= 1;
      }
      return grp;
    })
    setGroups(updatedGroups)
  }

  useEffect(() => {
    layoutConfig.subheader.display = true
    dispatch(metronic.builder.actions.setLayoutConfigs(layoutConfig));
  });

  useEffect(() => {
    axios.get(`/groups`).then((res)=>{
      setGroups(res.data.data);
      setLoading(false);
    }).catch((error)=>{
      cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
        heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
    });

  }, [intl])

  const hideModal = () => {
    setShowModal(false);
    setSelectedGroup(null);
  }
  const modalShow = (group) => {
    setSelectedGroup(group);
    setShowModal(true);
  }

  const addGroupData = (data) => {
    let grps = [...groups, data];
    setGroups(grps);
  }

  const updateGroupList = (data) => {
    let idx = groups.findIndex((grp) => grp.attributes.id === data.attributes.id)
    let grps = groups;
    grps[idx] = data;
    setGroups(grps);
  }

  const deleteGroup = () => {
    var newValues = [...groups]
    let idx = groups.findIndex((grp) => grp.attributes.id === selectedGroup.attributes.id)
    newValues.splice(idx, 1);
    setGroups(newValues);
  }

  const deleteUser = (group, index) => {
    axios.delete(`/groups/${group.attributes.id}/remove_user_from_group`, { data: { group_user_email: props.user.email } }).then((res)=>{
      var newValues = [...groups]
      newValues.splice(index, 1);
      setGroups(newValues);
      cogoToast.success(intl.formatMessage({ id: "CALENDAR.SHARE.DELETE_MESSAGE" }), {
        heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
    }).catch((error)=>{
      cogoToast.error(intl.formatMessage({ id: error.response?.data?.message !== undefined ? error.response?.data?.message : "GENERAL.ERROR_MESSAGE" }), {
        heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
    });
  }

  const exitGroup = (group, index) =>{
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "PROFILE.WARNING",
      }),
      description: intl.formatMessage(
        { id: "GROUP.CONFIRM_EXIT" },
        { name: group.attributes.name}
      ),
      okLabel: intl.formatMessage({
        id: "GENERAL.EXIT",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        deleteUser(group, index);
      }
    });
  }

  const loader = Array.apply(null, { length: 9 }).map((e, i) => (
    <Col key={"loader" + i} sm={6} md={6} lg={4} className="p-3 group-card">
      <SkeletonLoader className="skeleton-loader" width="100%" height="120px"/>
    </Col>
  ));


  return (
    <>
      <div className="zen-invite-page card">
        <Portlet fluidHeight={true} className="h-100">
          <PortletHeader
            title={intl.formatMessage({ id: "MENU.GROUPS" })}
            toolbar={
              <PortletHeaderToolbar>
              <button
                type="button"
                className="btn btn-pill btn-primary btn-elevate"
                onClick={() => setShowModal(true)}
              >
                <FormattedMessage id="GROUP.CREATE" />
              </button>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody className="overflow-auto">
            {loading ? (<div className="h-100 overflow-auto row">{loader}</div>) : (groups.length === 0 ? (<div><FormattedMessage id="GROUP.NO_GROUP" />
              </div>) : (<Row noGutters={false} className="text-dark">
                {groups.map((group, index) => (
                  <Col key={group.attributes.id} sm={6} md={6} lg={4} className="p-3 group-card">
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          <div className="d-flex">
                            <h4 className="flex-fill overflow-hidden">{group.attributes.name}</h4>
                            {group.attributes.is_owner && <div className="event_action">
                              <span
                                className="kt-header__topbar-icon kt-pulse"
                                onClick={() => modalShow(group)}
                              >
                                <i className="flaticon2-edit" />
                              </span>
                            </div>}
                          {!group.attributes.is_owner && <CustomTooltip title={intl.formatMessage({id: "GROUP.EXIT"})} arrow>
                              <div className="event_action">
                                <span
                                  className="kt-header__topbar-icon kt-pulse"
                                  onClick={() => exitGroup(group, index)}
                                >
                                  <i className="flaticon-logout" />
                                </span>
                              </div>
                            </CustomTooltip>}
                          </div>
                        </Card.Title>
                        <Card.Text>
                        <span className="d-flex flex-column">
                          <span><FormattedMessage id="GROUP.OWNER" />: {group.attributes.creator_full_name}</span>
                        </span>
                        <div className="mt-3">
                          <span className="font-weight-bolder">
                            <FormattedMessage id="GROUP.MEMBERS" />:
                          </span>
                          {group.attributes.group_users.length !== 0 ? (
                            <div
                              className={
                                group.attributes.group_users.length > 4
                                  ? clsx("pl-2", classes.shadowEffect)
                                  : "pl-2"
                              }
                            >
                              <PerfectScrollbar
                                style={{
                                  height: "85px",
                                  maxHeight: "85px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  {group.attributes.group_users.map(
                                    (user) => (
                                      <div className="kt-notification__item-title">
                                        {user.email}
                                      </div>
                                    )
                                  )}
                                </div>
                              </PerfectScrollbar>
                            </div>
                            ) : (
                              <div style={{ height: "85px" }}>
                                <FormattedMessage id="GROUP.NO_USER" />
                              </div>
                            )}
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>))
            }
          </PortletBody>
        </Portlet>
        <GroupForm
          changeMemberCount={changeMemberCount}
          showModal={showModal}
          hideModal={hideModal}
          selectedGroup={selectedGroup}
          updateGroupList={updateGroupList}
          deleteGroup={deleteGroup}
          addGroupData={addGroupData}
        />
      </div>
    </>
  );
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps, null)(GroupsPage);
