import React, { useState, useEffect, useMemo } from "react";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Fade } from "react-reveal";
import { TextField } from "@material-ui/core";
import { toAbsoluteUrl } from "_metronic";
import { Image } from "react-bootstrap";
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { connect } from "react-redux";
import SkeletonLoader from "tiny-skeleton-loader-react";
import '_metronic/_assets/sass/zen_reminder/share_event.scss';
import { toInputLowercase } from "_metronic/utils/utils";

export function ShareEvent(props) {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [emailLoading, setEmailLoading] = useState(true);
  let groupProps = {
    appear: true,
    enter: true,
    exit: true,
  };

  const [sharedEmails, setSharedEmails] = useState([]);
  const intl = useIntl();
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    padding: "1.21rem 2rem"
  });
  const loader = Array.apply(null, { length: 4 }).map((e, i) => (
    <SkeletonLoader className="skeleton-loader" key={'loader'+i} width="100%" height="80px"/>
  ));

  useEffect(() => {
    axios.get(`/reminders/${props.event.id}/shared_users_list`).then((res)=>{
      setSharedEmails(res.data.shared_users);
      setEmailLoading(false);
    }).catch((error)=>{
      cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
        heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
    });
  }, [intl, props.event])
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ padding: "2.0rem" });
  };

  useMemo(() => {
    if(!emailLoading)
      props.changeShareCount(sharedEmails.length);
    },
    [props, sharedEmails.length, emailLoading],
  )
  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ padding: "1.21rem 2rem" });
  };

  const deleteEmail = (index, email) =>{
    axios.delete(`/reminders/${props.event.id}/delete_shared_user`, { data: { shared_user_email: email } }).then((res)=>{
      var newValues = [...sharedEmails]
      newValues.splice(index, 1);
      setSharedEmails(newValues);
      cogoToast.success(intl.formatMessage({ id: "CALENDAR.SHARE.DELETE_MESSAGE" }), {
        heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
    }).catch((error)=>{
      cogoToast.error(intl.formatMessage({ id: error.response?.data?.message !== undefined ? error.response?.data?.message : "GENERAL.ERROR_MESSAGE" }), {
        heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
    });
  }
  return(
    <>
      <Fade>
        <Formik
          enableReinitialize
          initialValues={{
            shared_email: ''
          }}
          validate={(values) => {
            const errors = {};
            let user_emails = [props.user.email, ...(props.user.additional_emails.map((val) => val.email))]
            if (!values.shared_email) {
              errors.shared_email = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.shared_email)) {
              errors.shared_email = intl.formatMessage(
                { id: "AUTH.VALIDATION.INVALID_EMAIL" },
              );
            } else if (user_emails.includes(values.shared_email)) {
              errors.shared_email = intl.formatMessage(
                { id: "AUTH.VALIDATION.OWN_EMAIL" },
              );
            } else if (sharedEmails.includes(values.shared_email)) {
              errors.shared_email = intl.formatMessage(
                { id: "AUTH.VALIDATION.EXISTING_EMAILS" },
                { emails: values.shared_email }
              );
            }
            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            enableLoading();
            setSubmitting(true);
            axios.post(`/reminders/${props.event.id}/add_shared_user`, {shared_emails: [values.shared_email]}).then(async(res)=>{
              setSharedEmails([...sharedEmails, ...[values.shared_email]]);
              values.shared_email='';
              setStatus(null);
              setHasError(false);
              disableLoading();
              cogoToast.success(intl.formatMessage({ id: "CALENDAR.SHARE.INVITE_MESSAGE" }), {
                heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
                bar: {size: '10px'},
                position: 'top-right'
              });
            }).catch((error)=>{
              let invalidEmails = error.response?.data?.emails
              let invalidEmailsString;
              if(invalidEmails !== undefined){
                if (!invalidEmails.includes(values.shared_email))
                  setSharedEmails([...sharedEmails, ...[values.shared_email]]);
                values.shared_email = invalidEmails;
                invalidEmailsString = invalidEmails.toString();
              }
              setStatus(
                intl.formatMessage(
                  { id: error.response?.data?.message !== undefined ? error.response?.data?.message : "GENERAL.ERROR_MESSAGE"},
                  { emails: invalidEmailsString}
                )
              );
              setHasError(true);
              disableLoading()
            });
            setSubmitting(false);
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <form
              noValidate={true}
              id="share-form"
              autoComplete="off"
              className="kt-form"
              onSubmit={handleSubmit}
            >
              <div>
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}
                <div className="d-flex">
                  <div className="form-group mb-0 flex-fill mr-3 text-field-container">
                    <TextField
                      type="text"
                      variant="outlined"
                      label={intl.formatMessage({id: "CALENDAR.EMAIL"})}
                      name="shared_email"
                      onChange={handleChange}
                      onInput={toInputLowercase}
                      value={values.shared_email}
                      helperText={touched.shared_email && errors.shared_email ? errors.shared_email : " "}
                      error={Boolean(touched.shared_email && errors.shared_email)}
                    />
                  </div>
                  <div className="kt-login__actions ">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-primary btn-pill btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      {!loading ? <FormattedMessage id="CALENDAR.SHARE.SEND" /> : " " }
                    </button>
                  </div>
                </div>
                <div className="note-text text-dark">
                  <FormattedMessage id="CALENDAR.SHARE.NOTE" />
                </div>
              </div>
            </form>
          )}
        </Formik>
        <div className="home_cal text-dark">
          <div className="my-3 kt-divider">
            <span />
          </div>
          <h5><FormattedMessage id="CALENDAR.SHARE.PEOPLE" /></h5>
          {emailLoading ? (loader) : (sharedEmails.length === 0 ? (<div><FormattedMessage id="CALENDAR.SHARE.NO_USER" /></div>) :
          (<div className={`shared-emails ${clsx(
              {
                "email-error": hasError,
              }
            )}`}
          >
            <TransitionGroup {...groupProps}>
              {sharedEmails.map((email, index) => (
                <Fade key={email} collapse bottom>
                  <div className="d-flex my-2 align-items-center" id="shared_user">
                    <Image
                      width={30}
                      height={30}
                      alt="220x20"
                      src={toAbsoluteUrl("/media/misc/default-image.png")}
                      roundedCircle
                    />
                    <div
                      className="mx-2 flex-fill"
                    >
                      {email}
                    </div>
                    <div className="event_action">
                      <span
                        className="kt-header__topbar-icon kt-pulse"
                        onClick={() => deleteEmail(index, email)}
                      >
                        <i className="fas fa-times" />
                      </span>
                    </div>
                  </div>
                </Fade>
              ))}
            </TransitionGroup>
          </div>)
          )}
        </div>
      </Fade>
    </>
  )
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps, null)(ShareEvent);
