const flatten = require('flat');
const messages = {
  TRANSLATOR: {
    SELECT: "Wähle deine Sprache"
  },
  MENU: {
    ACTIONS: "Aktionen",
    DASHBOARD: "Instrumententafel",
    MY_PROFILE: "Mein Profil",
    SIGN_OUT: "Ausloggen",
    PRIVACY_POLICY: "Datenschutz-Bestimmungen",
    CONTACT: "Kontakt",
    NOTIFICATION: "Benachrichtigung",
    NOTIFICATIONS: "Benachrichtigungen",
    PENDING_INVITES: "Ausstehende Einladungen",
    GROUPS: "Gruppen"
  },
  AUTH: {
    GENERAL: {
      SUBMIT_BUTTON: "einreichen",
      FORGOT_BUTTON: "Passwort vergessen?"
    },
    LOGIN: {
      TITLE: "Melden Sie sich bei Zen Reminder an",
    },
    FORGOT: {
      TITLE: "Passwort vergessen?",
      SUCCESS: "Wir haben Ihnen gerade eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts gesendet!"
    },
    RESET: {
      TITLE: "Passwort zurücksetzen",
      NEW_PASSWORD: "Neues Kennwort",
      CONFIRM_PASSWORD: "Kennwort bestätigen",
      ERROR: "Es gab einen Fehler.",
      SUCCESS: "Ihr Passwort wurde erfolgreich zurückgesetzt."
    },
    REGISTER: {
      TITLE: "Melden Sie sich bei Zen Reminder an",
      SUCCESS: "Ihr Konto wurde erfolgreich registriert.",
      VERIFY_EMAIL: "Bitte überprüfen Sie Ihre E-Mail-Adresse, um sich anzumelden."
    },
    VALIDATION: {
      INVALID: "{name} ist ungültig  ",
      REQUIRED: "{name} ist erforderlich",
      REGISTERED_EMAIL: "Email schon registriert. Bitte versuchen Sie es mit einem Login",
      EMAIL_IN_USE: "E-Mail wird bereits verwendet. Bitte registrieren Sie sich mit einer anderen E-Mail",
      LOGGED_IN: "Eingeloggt",
      LOGGED_OUT: "Abgemeldet",
      MIN_LENGTH: "{name}} Mindestlänge ist {{min}",
      NOT_FOUND: "Der angeforderte {name} wurde nicht gefunden",
      INVALID_LOGIN: "Das Anmeldedetail ist falsch",
      VERIFY_EMAIL: "Bitte bestätigen sie ihre E-Mail Adresse",
      OAUTH_USER: "Hinweis: Haben Sie Google/Apple verwendet, um sich bei uns zu registrieren?",
      INVITED_USER: "Bitte überprüfen Sie, ob Sie sich über den Einladungslink registriert haben",
      REQUIRED_FIELD: "Pflichtfeld",
      INVALID_FIELD: "Feld ist ungültig",
      SAME_ADDITIONAL_EMAIL: "Zusätzliche E-Mails müssen unterschiedlich sein",
      PASSWORDS_MATCH_FAILED: "Passwort und Passwort bestätigen stimmten nicht überein.",
      ACCEPT_TERMS: "Die Bedingungen akzeptieren",
      TERMS_AND_CONDITIONS_PREFIX: "Ich stimme dem zu ",
      TERMS_AND_CONDITIONS: "Terms & Bedingungen",
      TERMS_AND_CONDITIONS_SUFFIX: " ",
      TERMS_AND_CONDITIONS_NOT_FOUND: "Allgemeine Geschäftsbedingungen nicht gefunden",
      PASSWORD_REGEX: "Das Passwort muss mindestens 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Zahl enthalten",
      PASSWORD_MAX_CHAR: "Das Passwort muss aus mindestens 8 Zeichen bestehen",
      PASSWORD_SPACE: "Das Passwort sollte keine Leerzeichen enthalten",
      INVALID_EMAIL: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
      OWN_EMAIL: "Sie können keine eigene E-Mail-Adresse verwenden",
      EXISTING_EMAILS: "Bereits geteilt mit {emails}"
    },
    EMAIL_CONFIRMATION: {
      SUCESS: "Sie haben Ihre E-Mail-Adresse erfolgreich überprüft",
      FAILURE: "Ungültiger oder abgelaufener E-Mail-Bestätigungslink"
    }
  },
  CALENDAR: {
    NOTIFY_VIA: "Benachrichtigen über",
    DESCRIPTION: "Beschreibung",
    GROUP: "Gruppe",
    TRIGGER_AT: "Auslöser bei",
    REPEAT: "Wiederholen",
    REPEAT_ON: "Wiederholen am",
    START_DATE: "Anfangsdatum",
    END_DATE: "Endtermin",
    CATEGORY: "Kategorie",
    PROVIDER: "Anbieter",
    CALENDAR: "Kalender",
    CREATE_EVENT: "Erinnerung erstellen",
    NEW_EVENT: "Erinnerung hinzufügen",
    UPDATE_EVENT: "Erinnerung Aktualisieren",
    CLICK_HERE: "Klick hier",
    TELEGRAM: " und wählen Sie 'Start', um Benachrichtigungen im Telegramm zu empfangen",
    START_DATE_RESTRICTION: "Erinnerungen in der Vergangenheit können nicht erstellt werden",
    END_DATE_RESTRICTION: "Bitte wählen Sie ein gültiges Enddatum",
    REQUIRED_FIELD: "Pflichtfeld",
    MAX_CHARACTER: "Zeichenbegrenzung: 140",
    PREVIOUS: "Bisherige",
    NEXT: "Nächster",
    TODAY: "Heute",
    MONTH: "Monat",
    WEEK: "Woche",
    DAY: "Tag",
    AGENDA: "Agenda",
    DATE: "Datum",
    TIME: "Zeit",
    EVENT: "Erinnerung",
    MORE: "Mehr",
    DOES_NOT_REPEAT: "Wiederholt sich nicht",
    CUSTOM: "Benutzerdefiniert",
    DAILY: "Täglich",
    WEEKLY_ON: "Wöchentlich am {day}",
    MONTHLY_ON: "Monatlich am {date}",
    ANNUALY_ON: "Jährlich am {date} {month}",
    SURE_DELETE: "Bitte wählen Sie aus, wie Sie die Erinnerungen löschen möchten.",
    DELETE_EVENT: "Löschen Sie dieses Erinnerung",
    DELETE_ALL: "Löschen Sie alle Erinnerungen",
    DELETE_FUTURE: "Löschen Sie dieses und folgende Erinnerungen",
    CONFIRM_DELETE: "Möchten Sie dieses Erinnerung wirklich löschen?",
    CONFIRM_UPDATE: "Möchten Sie dieses Erinnerung wirklich aktualisieren?",
    SURE_UPDATE: "Bitte wählen Sie aus, wie Sie die Erinnerungen aktualisieren möchten.",
    UPDATE_THIS: "Aktualisieren Sie dieses Erinnerung",
    UPDATE_ALL: "Alle Erinnerungen aktualisieren",
    UPDATE_FUTURE: "Aktualisieren Sie dieses und die folgenden Erinnerungen",
    EVENTS: "Erinnerungen",
    TODAYS_EVENTS: "Die heutigen Erinnerungen",
    PLEASE: "Bitte",
    NO_EVENT: "Es gibt keine Erinnerungen in diesem Bereich.",
    NO_DAY_EVENT: "An diesem Tag gibt es keine Erinnerungen.",
    COLOR: "Farbe",
    NOTIFY_VIA_TOOLTIP: "Abgemeldet. Kann auf der Profilseite aktiviert werden.",
    SHARED_BY: "Geteilt von",
    CREATED_BY: "Erstellt von",
    EMAIL: "E-mail",
    FREQUENCY: "Frequenz",
    AT: "Beim",
    CREATE_MESSAGE: "Erinnerung erfolgreich erstellt.",
    UPDATE_MESSAGE: "Erinnerung erfolgreich aktualisiert.",
    DELETE_MESSAGE: "Erinnerung erfolgreich gelöscht.",
    FROM_GROUP: "Sie erhalten diese Erinnerung, da Sie derselben Gruppe wie die Erinnerung angehören",
    END_DATE_MESSAGE: "Wiederholt sich endlos",
    SHARE: {
      SEND: "Senden",
      PEOPLE: "Geteilte Menschen",
      NO_USER: "Sie haben diese Erinnerung mit niemandem geteilt",
      REJECT_DESCRIPTION: "Möchten Sie diese Erinnerung wirklich ablehnen?",
      NO_INVITES: "Es gibt keine ausstehenden Einladungen",
      ACCEPT: "Akzeptieren",
      REJECT: "Ablehnen",
      NOTE: "Hinweis: Für E-Mails, die nicht bei uns registriert sind, wird ein Einladungslink gesendet, um sich bei Zen Reminder anzumelden.",
      ACCEPT_MESSAGE: "Einladung angenommen.",
      REJECT_MESSAGE: "Einladung abgelehnt.",
      DELETE_MESSAGE: "Benutzer aus Erinnerung entfernt.",
      INVITE_MESSAGE: "Einladung erfolgreich gesendet."
    },
    SAVE_AS_NEW: {
      CLONE: "Klon",
      BUTTON: "Als neu speichern",
      TITLE: "Als neue Erinnerung speichern",
      DESCRIPTION: "Bitte wählen Sie aus, wie Sie diese Erinnerung klonen möchten",
      WHOLE_REMINDER: "Klonen Sie alle Erinnerungen",
      CURRENT_RECURRENCE: "Klonen Sie dieses Erinnerung",
      FUTURE_RECURRENCE: "Klonen Sie dieses und folgende Erinnerungen",
      NO_REPEAT_DESCRIPTION: "Möchten Sie diese Erinnerung als neue Erinnerung speichern?"
    },
    CUSTOM_RECURRENCE: {
      TITLE: "Benutzerdefinierte Wiederholung",
      REPEAT: "Wiederholen",
      REPEAT_ON: "Wiederholen am",
      REPEAT_EVERY: "Wiederhole jeden",
      MONTHLY_ON_DAY: "Monatlich am Tag {day}",
      FIRST: "Zuerst",
      SECOND: "Zweite",
      THIRD: "Dritte",
      FOURTH: "Vierte",
      FIFTH: "Fünfte",
      SUN: "Sonntag",
      MON: "Montag",
      TUE: "Dienstag",
      WED: "Mittwoch",
      THU: "Donnerstag",
      FRI: "Freitag",
      SAT: "Samstag",
      DONE: "Getan",
      INVALID_REPEAT_NUMBER: "Ungültige Nummer",
      DAY_REPEAT: "Alle {day_count} Tage",
      WEEK_REPEAT: "Jede Woche an {week_days}"
    }
  },
  GENERAL: {
    ERROR: "Error",
    ERROR_MESSAGE: "Etwas ist schief gelaufen. Bitte versuche es erneut",
    CLOSE: "Schließen",
    DELETE: "Löschen",
    UPDATE: "Aktualisieren",
    SUCCESS: "Erfolg",
    CANCEL: "Stornieren",
    RESET_PASSWORD: "Passwort Zurücksetzen",
    OK: "OK",
  },
  ERROR: {
    GO_BACK_HOME: "Wieder nach Hause gehen"
  },
  EMAIL: {
    UNSUBSCRIBE: {
      SUBTITLE: "Es tut uns leid, wenn wir Ihren Posteingang als Spam versendet haben.",
      DESCRIPTION: "Wenn Sie E-Mail-Benachrichtigungen fortsetzen möchten, können Sie sie jederzeit auf Ihrer Profilseite aktivieren."
    },
    CONFIRMATION: {
      SUCCESS: {
        SUBTITLE: "Sie haben Ihre E-Mail-Adresse erfolgreich überprüft",
        DESCRIPTION: "Ihre E-Mail ist bereit, Benachrichtigungen zu erhalten."
      },
      FAILURE: {
        SUBTITLE: "Beim Überprüfen Ihrer zusätzlichen E-Mail-Adresse ist ein Fehler",
        DESCRIPTION: "Bitte überprüfen Sie, ob der Bestätigungslink gültig ist."
      }
    },
    SHARED_REMINDER: {
      ACCEPT: {
        SUBTITLE: "Sie haben die gemeinsame Erinnerungseinladung angenommen",
        DESCRIPTION: "Wir senden Ihnen Benachrichtigungen für diese Erinnerung"
      },
      REJECT: {
        SUBTITLE: "Sie haben die gemeinsame Erinnerungseinladung abgelehnt",
        DESCRIPTION: "Wenn Sie diese Einladung versehentlich abgelehnt haben, fordern Sie bitte eine weitere Einladung an"
      },
      FAILURE: {
        SUBTITLE: "Bei der Berücksichtigung Ihrer Eingabe ist ein Fehler aufgetreten",
        DESCRIPTION: "Bitte überprüfen Sie auf der Seite Einladungen, ob Sie diese Einladung bereits angenommen / abgelehnt haben"
      }
    }
  },
  PROFILE: {
    FIRST_NAME: "Vorname",
    LAST_NAME: "Nachname",
    LANGUAGE: "Sprache",
    MAX_CHARACTER: "Zeichenbegrenzung: 50",
    WRONG_PASSWORD: "Das eingegebene aktuelle Passwort ist falsch",
    CHANGE_PASS: "Ändern Sie Ihr Passwort",
    CHANGE_PASSWORD: "Passwort Ändern",
    CURRENT_PASSWORD: "Derzeitiges Passwort",
    NEW_PASSWORD: "Neues Kennwort",
    CONFIRM_NEW_PASSWORD: "Bestätige Neues Passwort",
    CONFIRM_TITLE: "Dadurch werden alle Ihre aktiven Sitzungen abgemeldet. Sind Sie sicher, dass Sie fortfahren wollen?",
    OK_LABEL: "Ja! Fortsetzen",
    ADDITIONAL_EMAIL_EXISTS: "{email} wird bereits verwendet",
    CONFIRM_EMAIL_TOOLTIP: "Bitte überprüfen Sie diese E-Mail-Adresse, indem Sie auf den Link klicken, der an diese E-Mail-ID gesendet wurde",
    NOTIFICATION: "Benachrichtigung",
    WARNING: "Warnung",
    TIMEZONE: "Zeitzone",
    ADDITIONAL_EMAIL_1: "Zusätzliche E-Mail 1",
    ADDITIONAL_EMAIL_2: "Zusätzliche E-Mail 2",
    UPDATE_MESSAGE: "Ihr Profil wurde erfolgreich aktualisiert."
  },
  TIMEZONE_CHANGE: {
    TITLE: "Neue Zeitzone erkannt",
    DESCRIPTION: "Wir aktualisieren Ihre Zeitzone auf - {new_time_zone}",
  },
  NOTIFICATION: {
    SNOOZED: "Die Erinnerung döste eine Stunde lang.",
    CLEAR_TOOLTIP: "Benachrichtigung löschen",
    SNOOZE_TOOLTIP: "Snooze für eine Stunde",
    CLEAR_ALL: "Alles löschen"
  },
  GROUP: {
    NAME: "Name",
    CREATE: "Gruppe Erstellen",
    UPDATE: "Gruppe Aktualisieren",
    EXIT: "Verlassen Sie diese Gruppe",
    NO_GROUP: "Sie haben noch keine Gruppen",
    EMAIL_PLACEHOLDER: "Trennen Sie E-Mails mit der Eingabetaste",
    EMAIL_NOTE: "Hinweis: Trennen Sie E-Mails mit der Eingabetaste",
    USER_EMAILS: "Benutzer-E-Mails",
    OWNER: "Inhaber",
    MEMBERS: "Gruppenmitglieder",
    TOTAL_MEMBERS: "Mitglieder insgesamt",
    UPDATE_MESSAGE: "Gruppe erfolgreich aktualisiert",
    CREATE_MESSAGE: "Gruppe erfolgreich erstellt",
    DELETE_MESSAGE: "Gruppe erfolgreich gelöscht",
    SHARE_DELETE: "Benutzer wurde aus der Gruppe entfernt",
    CONFIRM_EXIT: "Sind Sie sicher, dass Sie die Gruppe \"{name}\" verlassen möchten?",
    CONFIRM_DELETE: "Durch Löschen dieser Gruppe werden auch alle damit verbundenen Erinnerungen gelöscht. Sind Sie sicher, dass Sie fortfahren möchten?",
    REMOVE_USER: "Benutzer entfernen",
    ADD_USER_MESSAGE: "Benutzer wurde der Gruppe hinzugefügt",
    ADD: "Hinzufügen",
    NO_USER: "Sie haben dieser Gruppe keinen Benutzer hinzugefügt"
  },
  REDIRECTION: {
    MESSAGE: "Sie werden in {seconds} Sekunden zum {dashboard} weitergeleitet."
  }
}

export default flatten(messages);
