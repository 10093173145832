import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { metronic } from "../../../_metronic";
import { verifyPasswordResetToken, updatePassword } from "../../crud/auth.crud";
import cogoToast from "cogo-toast";
import { Flip } from 'react-reveal';
import { passwordRegexCheck } from "_metronic";

class ResetPassword extends Component {
  state = { isRequested: false, locale: 'en', showPassword1: false, showPassword2: false };
  async componentDidMount() {
    this.props.changeBackground('/media/bg/reset-password.jpg')
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('locale') && urlParams.get('locale') !== 'en') {
      this.setState({locale: urlParams.get('locale')})
    }
    await verifyPasswordResetToken(urlParams.get('token'))
    .then(() => {
      this.setState({ token_verified: true });
    })
    .catch((e) => {
        this.setState({ token_verified: false });
      });
  }

  handleShowPassword = (value) => {
    if(value === 1) {
      this.setState({ showPassword1: !this.state.showPassword1 })
    } else {
      this.setState({ showPassword2: !this.state.showPassword2 })
    }
  }

  render() {
    const { intl, setLanguage } = this.props;
    const { isRequested, showPassword1, showPassword2 } = this.state;
    setLanguage(this.state.locale);
    if (isRequested) {
      cogoToast.success(intl.formatMessage({ id: "AUTH.RESET.SUCCESS" }), {
        heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
      this.props.history.push('/auth/login')
      return null
    }

    if (this.state.token_verified) {
      return (
        <div>
          <div className="text-dark text-center mb-3">
            <h3 className="font-weight-bold">
              <FormattedMessage id="AUTH.RESET.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              newPassword: "",
              confirmPassword: ""
              }}
            validate={values => {
              const errors = {};

              const passwordValidation = passwordRegexCheck(values.newPassword)
              if(passwordValidation !== null){
                errors.newPassword = intl.formatMessage({
                  id: passwordValidation
                });
              }

              if (!values.confirmPassword) {
                errors.confirmPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (values.newPassword !== values.confirmPassword) {
                errors.confirmPassword =
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.PASSWORDS_MATCH_FAILED",
                    defaultMessage: "Password and Confirm Password didn't match."
                  });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              var urlParams = new URLSearchParams(window.location.search)
              updatePassword(urlParams.get('token'), values.newPassword)
                .then( async() => {
                  setSubmitting(false);
                  this.setState({ isRequested: true });
                })
                .catch((error) => {
                  setSubmitting(false);
                  setStatus(
                    intl.formatMessage(
                      { id: error.response !== undefined ? error.response.data.message : "GENERAL.ERROR_MESSAGE" },
                    )
                  );
                });
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
                <Flip top>
                  <form
                    noValidate={true}
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    className="kt-form"
                  >
                    {status && (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    )}

                    <div className="form-group mb-2">
                      <TextField
                        type={showPassword1 ? "text" : "password"}
                        label={intl.formatMessage({id: "AUTH.RESET.NEW_PASSWORD"})}
                        margin="normal"
                        fullWidth={true}
                        name="newPassword"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.newPassword}
                        helperText={touched.newPassword && errors.newPassword ? errors.newPassword : " "}
                        error={Boolean(touched.newPassword && errors.newPassword)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                aria-label="Toggle password visibility"
                                onClick={ () => this.handleShowPassword(1) }
                              >
                                {showPassword1 ? (
                                  <i className="la la-eye" />
                                ) : (
                                  <i className="la la-eye-slash" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />

                      <TextField
                        type={showPassword2 ? "text" : "password"}
                        label={intl.formatMessage({id: "AUTH.RESET.CONFIRM_PASSWORD"})}
                        margin="normal"
                        fullWidth={true}
                        variant="outlined"
                        name="confirmPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmPassword}
                        helperText={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : " "}
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                aria-label="Toggle password visibility"
                                onClick={ () => this.handleShowPassword(2) }
                              >
                                {showPassword2 ? (
                                  <i className="la la-eye" />
                                ) : (
                                  <i className="la la-eye-slash" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-pill btn-elevate kt-login__btn-primary w-100"
                        disabled={isSubmitting}
                      >
                        <h4>{intl.formatMessage({id: "AUTH.GENERAL.SUBMIT_BUTTON"})}</h4>
                        </button>
                    </div>
                  </form>
                </Flip>
              )}
          </Formik>
        </div>
      );
    }
    else if (this.state.token_verified === false) {
      cogoToast.error(intl.formatMessage({ id: "AUTH.RESET.ERROR" }), {
        heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
      this.props.history.push('/auth/login')
      return null
    }
    else {
      return null;
    }


  }
}

const mapDispatchToProps = {
  ...auth.actions,
  ...metronic.i18n.actions,
};

export default injectIntl(connect(null, mapDispatchToProps)(ResetPassword));
