import React, { useState } from "react";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import clsx from "clsx";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { ConfirmationPopup } from "app/widgets/ConfirmDialog"
import { passwordRegexCheck } from "_metronic";

export default function PasswordReset(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "1.0rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "1.0rem" });
  };

  const changePassword = (values, setStatus, setSubmitting) => {
    axios.patch(`/users/${props.userId}/update_password`, {user: values}).then(async(res)=>{
      props.passwordChange();
      disableLoading()
    }).catch((error)=>{
      setStatus(
        intl.formatMessage({
          id: error.response?.data?.message !== undefined ? error.response?.data?.message : "GENERAL.ERROR_MESSAGE"
        })
      );
      disableLoading();
      setSubmitting(false);
    });
  }

  const handleShowPassword = (value) => {
    if(value === 1) {
      setShowPassword1(!showPassword1);
    } else if(value === 2) {
      setShowPassword2(!showPassword2);
    } else {
      setShowPassword3(!showPassword3);
    }
  }

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.hideModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <FormattedMessage id={"PROFILE.CHANGE_PASS"}/>
          </Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={{
            current_password: "",
            new_password: "",
            confirm_new_password: "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.current_password) {
              errors.current_password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }
            const passwordValidation = passwordRegexCheck(values.new_password)
            if(passwordValidation !== null){
              errors.new_password = intl.formatMessage({
                id: passwordValidation
              });
            }

            if (!values.confirm_new_password) {
              errors.confirm_new_password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }else if (values.new_password !== values.confirm_new_password) {
              errors.confirm_new_password =
              intl.formatMessage({
                id: "AUTH.VALIDATION.PASSWORDS_MATCH_FAILED",
                defaultMessage: "Password and Confirm Password didn't match."
              });
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            enableLoading();
            setSubmitting(true);
            ConfirmationPopup({
              title: intl.formatMessage({
                id: "PROFILE.WARNING",
              }),
              description: intl.formatMessage({
                id: "PROFILE.CONFIRM_TITLE",
              }),
              okLabel: intl.formatMessage({
                id: "PROFILE.OK_LABEL",
              }),
              cancelLabel: intl.formatMessage({
                id: "GENERAL.CANCEL",
              }),
              okAction: () => {
                changePassword(values, setStatus, setSubmitting)
              },
              closeAction: () => {
                disableLoading();
                setSubmitting(false);
              }
            });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              className="kt-form"
              onSubmit={handleSubmit}
            >
              <div className="modal-body">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group mb-0 mt-4">
                  <TextField
                    type={showPassword1 ? "text" : "password"}
                    label={intl.formatMessage({id: "PROFILE.CURRENT_PASSWORD"})}
                    margin="normal"
                    className="kt-width-full mt-0"
                    name="current_password"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.current_password}
                    helperText={touched.current_password && errors.current_password ? errors.current_password : " "}
                    error={Boolean(touched.current_password && errors.current_password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={ () => handleShowPassword(1) }
                          >
                            {showPassword1 ? (
                              <i className="la la-eye" />
                            ) : (
                              <i className="la la-eye-slash" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    type={showPassword2 ? "text" : "password"}
                    margin="normal"
                    label={intl.formatMessage({id: "PROFILE.NEW_PASSWORD"})}
                    className="kt-width-full"
                    name="new_password"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.new_password}
                    helperText={touched.new_password && errors.new_password ? errors.new_password : " "}
                    error={Boolean(touched.new_password && errors.new_password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={ () => handleShowPassword(2) }
                          >
                            {showPassword2 ? (
                              <i className="la la-eye" />
                            ) : (
                              <i className="la la-eye-slash" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    type={showPassword3 ? "text" : "password"}
                    margin="normal"
                    label={intl.formatMessage({id: "PROFILE.CONFIRM_NEW_PASSWORD"})}
                    className="kt-width-full"
                    name="confirm_new_password"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirm_new_password}
                    helperText={touched.confirm_new_password && errors.confirm_new_password ? errors.confirm_new_password : " "}
                    error={Boolean(touched.confirm_new_password && errors.confirm_new_password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={ () => handleShowPassword(3) }
                          >
                            {showPassword3 ? (
                              <i className="la la-eye" />
                            ) : (
                              <i className="la la-eye-slash" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <div className="kt-login__actions">
                  <button type="button" disabled={isSubmitting} className="btn btn-pill btn-outline-dark btn-elevate kt-login__btn-danger" onClick={props.hideModal}><FormattedMessage id="GENERAL.CLOSE" /></button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`ml-3 btn btn-pill btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    <FormattedMessage id={"PROFILE.CHANGE_PASSWORD"}/>
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
