import React from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/error/error-404.scss";
import { FormattedMessage } from "react-intl";

export default function ErrorPage404() {
  return (
    <>
      <div className="error-404 flex-column">
        <div className="body-container flex-column">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/error/error-404.png")}
            className="align-self-center"
          />
          <a href="/dashboard" className="btn btn-primary align-self-center">
            <FormattedMessage id="ERROR.GO_BACK_HOME" />
          </a>
        </div>

      </div>
    </>
  );
}
