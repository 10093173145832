import React, { useState, useEffect, useCallback } from "react";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { TextField, MenuItem, Checkbox, FormLabel, FormGroup, FormControlLabel, Radio, makeStyles, RadioGroup, Tooltip, withStyles, Tab, Tabs } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import clsx from "clsx";
import axios from "axios";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import cogoToast from 'cogo-toast';
import { connect } from "react-redux";
import * as auth from "../store/ducks/auth.duck";
import moment from 'moment';
import { ConfirmationPopup } from "app/widgets/ConfirmDialog"
import '_metronic/_assets/sass/zen_reminder/calendar_form.scss';
import { colors } from "_metronic/utils/utils"
import SkeletonLoader from "tiny-skeleton-loader-react";
import { TabContainer, Button, Dropdown, ButtonGroup } from "react-bootstrap";
import ShareEvent from "./ShareEvent";
import CustomRecurrence from "./CustomRecurrence";
require("moment/min/locales.min");

const useStyles = (color) => {
  const styleChange = makeStyles({
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: color,
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: color,
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: color,
      },
    }
  })
  return styleChange();
}

function StyledRadio(props) {
  const classes = useStyles(props.clr);

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}


export function CalendarForm(props) {

  const intl = useIntl();
  moment.locale(intl.locale);
  const [formLoading, setFormLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [defaultCategory, setDefaultCategory] = useState("")
  const [defaultGroup, setDefaultGroup] = useState("")
  const [defaultProvider, setDefaultProvider] = useState("")
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [providers, setProviders] = useState([]);
  const [categoryProviders, setCategoryProviders] = useState([]);
  const [telegramLink, setTelegramLink] = useState("")
  const [repeatOptions, setRepeatOptions] = useState([]);
  const [cloning, setCloning] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const changeTabValue = (_, newValue) => setTabValue(newValue);

  const REPEAT_TYPES = {
    NO_REPEAT: "no_repeat",
    DAILY: "daily",
    WEEKLY: "weekly",
    MONTHLY: "monthly",
    ANUALLY: "anually",
    CUSTOM: "custom"
  };

  const repeatOptionsValue = useCallback((date) => {
    if (date === null) {
      return;
    }
    let dateString = moment(date).format('MMMM Do dddd').split(" ");
    setRepeatOptions([{
      value: "no_repeat",
      text: intl.formatMessage({id: "CALENDAR.DOES_NOT_REPEAT"})
    },
    {
      value: "daily",
      text: intl.formatMessage({id: "CALENDAR.DAILY"}),
    },
    {
      value: "weekly",
      text: intl.formatMessage({id: "CALENDAR.WEEKLY_ON"}, {day: dateString[2]}),
    },
    {
      value: "monthly",
      text: intl.formatMessage({id: "CALENDAR.MONTHLY_ON"}, {date: dateString[1]})
    },
    {
      value: "anually",
      text: intl.formatMessage({id: "CALENDAR.ANNUALY_ON"}, {month: dateString[0], date: dateString[1]})
    },
    {
      value: "custom",
      text: intl.formatMessage({id: "CALENDAR.CUSTOM"}),
    }]);
  }, [intl])

  useEffect(() => {
    const callOnMount = async () => {
      await axios.get('/categories').then((res)=>{
        if(res.data.data.length !== 0){
          let categoriesList = res.data.data.map(category => {
            return {
              id: category.attributes.id,
              description: category.attributes.description,
              icon_file_path: category.attributes.icon_file_path,
              providers : category.attributes.providers
            }
          });
          setCategories(categoriesList);
          setDefaultCategory(categoriesList[0].id)
          var providers = []
          if(props.event.category_id) {
            providers = categoriesList.find(category => category.id === props.event.category_id).providers
            setProviders(providers)
          }
          else {
            providers = categoriesList[0].providers
            if (providers.length !== 0) {
              setProviders(providers)
              setDefaultProvider(providers[0].id)
            }
          }
        }
      }).catch((_)=>{
        cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
          heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
          bar: {size: '10px'},
          position: 'top-right'
        });
      });
      await axios.get(`/groups`).then((res)=>{
        if(res.data.data.length !== 0){
          let groupsList = res.data.data.map(group => {
            return {
              id: group.attributes.id,
              name: group.attributes.name
            }
          });
          setGroups(groupsList);
          setDefaultGroup(groupsList[0].id)
        }
      }).catch((_)=>{
        cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
          heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
          bar: {size: '10px'},
          position: 'top-right'
        });
      });
      await axios.get(`category_providers`).then((res)=>{
        if(res.data.data.length !== 0){
          let categoryProvdersList = res.data.data.map(categoryProvider => {
            return {
              id: categoryProvider.attributes.id,
              category_id: categoryProvider.attributes.category_id,
              provider_id: categoryProvider.attributes.provider_id,
              description: categoryProvider.attributes.description
            }
          });
          setCategoryProviders(categoryProvdersList);
        }
      }).catch((_)=>{
        cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
          heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
          bar: {size: '10px'},
          position: 'top-right'
        });
      });
      if(!props.user.telegram_chat_id) {
        axios.get("users/create_telegram_subscription_url").then((res) => {
          setTelegramLink(res.data.telegram_subscription_url)
        }).catch((_)=>{
          cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
            heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
            bar: {size: '10px'},
            position: 'top-right'
          });
        });
      }
      repeatOptionsValue(props.selectedDate)
      setFormLoading(false);
    }
    callOnMount();
  }, [intl, props, props.selectedDate, repeatOptionsValue]);

  useEffect(() => {
    setTabValue(props.tabModal);
  }, [props.tabModal])

  const isOwnReminder = () => {
    return !props.event.is_shared_reminder && !props.event.is_group_reminder
  }

  const handleCategoryChange = (value) => {
    var providers = categories.find(category => category.id === value).providers
    if (providers.length !== 0) {
      setProviders(providers)
    } else {
      setProviders([])
    }
  }

  const deleteSuccess = async() => {
    await props.fetchData(props.range, true)
    props.onHide();
    cogoToast.success(intl.formatMessage({ id: "CALENDAR.DELETE_MESSAGE" }), {
      position: 'top-right',
      heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
      bar: {size: '10px'}
    });
    setFormLoading(false);
    setDeleting(false);
  }

  const deleteError = () => {
    cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
      heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
      bar: {size: '10px'},
      position: 'top-right'
    });
    setFormLoading(false);
    setDeleting(false);
  }

  const deleteAll = () => {
    setDeleting(true);
    setFormLoading(true);
    axios.delete(`/reminders/${props.event.id}`).then((res)=>{
      deleteSuccess();
    }).catch((error)=>{
      deleteError();
    });
  }

  const deleteReminder = () => {
    if(props.event.repeat_type === REPEAT_TYPES.NO_REPEAT) {
      ConfirmationPopup({
        title: intl.formatMessage({
          id: "PROFILE.WARNING",
        }),
        description: intl.formatMessage({
          id: "CALENDAR.CONFIRM_DELETE",
        }),
        okLabel: intl.formatMessage({
          id: "GENERAL.DELETE",
        }),
        cancelLabel: intl.formatMessage({
          id: "GENERAL.CANCEL",
        }),
        okAction: () => {
          deleteAll()
        },
        closeAction: () => {
          setFormLoading(false);
          setDeleting(false);
        }
      });
    }
    else {
      ConfirmationPopup({
        title: intl.formatMessage({
          id: "PROFILE.WARNING",
        }),
        description: intl.formatMessage({
          id: "CALENDAR.SURE_DELETE",
        }),
        okLabel: intl.formatMessage({
          id: "CALENDAR.DELETE_EVENT",
        }),
        cancelLabel: intl.formatMessage({
          id: "GENERAL.CANCEL",
        }),
        thirdLabel: intl.formatMessage({
          id: "CALENDAR.DELETE_FUTURE",
        }),
        fourthLabel: intl.formatMessage({
          id: "CALENDAR.DELETE_ALL",
        }),
        maxWidth: "md",
        fourthAction: () => {
          deleteAll()
        },
        closeAction: () => {
          setFormLoading(false);
          setDeleting(false);
        },
        thirdAction: () => {
          setFormLoading(true);
          setDeleting(true);
          axios.patch(`/reminders/${props.event.id}/delete_future_recurrences`, {end_date: props.event.start}).then((res)=>{
            deleteSuccess();
          }).catch((error)=>{
            deleteError();
          });
        },
        okAction: () => {
          setFormLoading(true);
          setDeleting(true);
          axios.patch(`/reminders/${props.event.id}/delete_current_recurrence`, {start_date: props.event.start}).then((res)=>{
            deleteSuccess();
          }).catch((error)=>{
            deleteError();
          });
        }
      });
    }
  }

  const updateAll = (values, setSubmitting, setStatus) => {
    axios.patch(`/reminders/${props.event.id}`, {reminder: values}).then(async(res)=>{
      successCall(res, values, "CALENDAR.UPDATE_MESSAGE")
    }).catch((error)=>{
      errorCall(setSubmitting, setStatus)
    });
  }

  const updateNotificationChannel = (values, setSubmitting, setStatus) => {
    axios.patch(`/reminders/${props.event.id}/update_reminder_notification_channel`, {reminder: values}).then(async(res)=>{
      successCall(res, values, "CALENDAR.UPDATE_MESSAGE")
    }).catch((error)=>{
      errorCall(setSubmitting, setStatus)
    });
  }

  const rejectSharedReminder = () => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "PROFILE.WARNING",
      }),
      description: intl.formatMessage({
        id: "CALENDAR.SHARE.REJECT_DESCRIPTION",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      maxWidth: "sm",
      closeAction: () => {
        setFormLoading(false);
        setDeleting(false);
      },
      okAction: () => {
        axios.patch(`/reminders/${props.event.id}/update_shared_reminder`, {status: 'rejected'}).then((res)=>{
          deleteSuccess();
        }).catch((error)=>{
          deleteError();
        });
      }
    });
  }

  const correctReminderStartDate = (start_date) => {
    let new_start_date = new Date(props.event.start_date);
    return new Date(new_start_date.setHours(start_date.getHours(), start_date.getMinutes()))
  }

  const createReminder = (values, setSubmitting, setStatus) => {
    setFormLoading(true);
    axios.post('/reminders', {reminder: values}).then(async(res)=>{
      successCall(res, values, "CALENDAR.CREATE_MESSAGE")
    }).catch(() => {
      errorCall(setSubmitting, setStatus)
    });
  }

  const updateThisAndFuture = (values, setSubmitting, setStatus) => {
    values.prev_start_date = props.event.start
    axios.patch(`/reminders/${props.event.id}/update_future_recurrences`, {reminder: values}).then((res)=>{
      successCall(res, values, "CALENDAR.UPDATE_MESSAGE");
    }).catch((error)=>{
      errorCall(setSubmitting, setStatus);
    });
  }

  const updateThis = (values, setSubmitting, setStatus) => {
    values.prev_start_date = props.event.start
    axios.patch(`/reminders/${props.event.id}/update_current_recurrence`, {reminder: values}).then((res)=>{
      successCall(res, values, "CALENDAR.UPDATE_MESSAGE");
    }).catch((error)=>{
      errorCall(setSubmitting, setStatus);
    });
  }

  const successCall = async(res, values, message) => {
    await props.fetchData(props.range);
    if(values.notify_via_telegram && !props.user.telegram_chat_id) {
      props.requestUser()
    }
    props.onHide();
    cogoToast.success(intl.formatMessage({ id: message }), {
      position: 'top-right',
      heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
      bar: {size: '10px'}
    });
  }

  const errorCall = (setSubmitting, setStatus) => {
    setFormLoading(false);
    setSubmitting(false);
    setStatus(
      intl.formatMessage({
        id: "GENERAL.ERROR_MESSAGE"
      })
    );
  }

  const filterOptions = (options, { inputValue }) => {
    return options.filter(option => !!inputValue && option.toLowerCase().includes(inputValue.toLowerCase()))
  };

  const getCategoryProvider = (value) => {
    return categoryProviders.find(categoryProvider => categoryProvider.description === value)
  }

  const handleDescriptionChange = (setFieldValue, value) => {
    var categoryProvider = getCategoryProvider(value)
    if (!!categoryProvider) {
      handleCategoryChange(categoryProvider.category_id);
      setFieldValue("description", value);
      setFieldValue("category_id", categoryProvider.category_id);
      setFieldValue("provider_id", categoryProvider.provider_id);
    }
  }

  const endDateValidation = (values) => {
    if(values.end_date && values.repeat_type !== REPEAT_TYPES.NO_REPEAT) {
      const start = moment(values.start_date)
      const end = moment(values.end_date)
      if((values.repeat_type === REPEAT_TYPES.DAILY || values.custom_recurrence?.repeat_type === 'day') && (end.diff(start, 'days') < 1)) {
        return true;
      } else if((values.repeat_type === REPEAT_TYPES.WEEKLY || values.custom_recurrence?.repeat_type === 'week') && (end.diff(start, 'weeks') < 1)) {
        return true;
      } else if((values.repeat_type === REPEAT_TYPES.MONTHLY || values.custom_recurrence?.repeat_type === 'month') && (end.diff(start, 'days') < 30)) {
        return true;
      } else if(values.repeat_type === REPEAT_TYPES.ANUALLY && (end.diff(start, 'years') < 1)) {
        return true;
      }
    }
    return false;
  }

  const startDateChangeCheck = (start_date) => {
    if(moment(start_date).format("DD/MM/YYYY") === moment(props.event.start).format("DD/MM/YYYY")) {
      start_date = correctReminderStartDate(start_date)
    }
    return start_date
  }

  const cloneReminder = (handleSubmit) => {
    if (props.pastEvent || !isOwnReminder()) {
      let event = JSON.parse(JSON.stringify(props.event))
      if (event.is_shared_reminder && !event.is_group_reminder)
        event.group_id = ''
      event.id = null
      event.is_group_reminder = false
      event.is_shared_reminder = false
      props.clonePastReminder(event);
    }
    else {
      setCloning(true)
      handleSubmit()
    }
  }

  const loader = Array.apply(null, { length: 9 }).map((e, i) => (
    <SkeletonLoader className="skeleton-loader" key={'loader'+i} width="100%" height="55px"/>
  ));

  const CustomTooltip = withStyles(() => ({
    tooltip: {
      fontSize: 11,
    },
  }))(Tooltip);

  const formCalendar = (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Formik
          enableReinitialize
          initialValues={{
            description: props.event.title || "",
            group_id: props.event.group_id || defaultGroup || "",
            category_id: props.event.category_id || defaultCategory || "",
            provider_id: props.event.provider_id || defaultProvider || "",
            start_date: props.selectedDate,
            end_date: props.event.end_date || null,
            user_id: props.user.id,
            color: props.event.color || 0,
            repeat_type: props.event.repeat_type || REPEAT_TYPES.NO_REPEAT,
            notify_via_email: props.event.id ? props.event.notify_via_email : props.user.notify_via_email || false,
            notify_via_telegram: props.event.id ? props.event.notify_via_telegram : props.user.notify_via_telegram || false,
            custom_recurrence: props.event.id && props.event.repeat_type === REPEAT_TYPES.CUSTOM ? props.event.custom_recurrence : {}
          }}
          validate={values => {
            if (!props.pastEvent) {
              const errors = {};
              values.description = values.description.trim()
              if (!values.description) {
                errors.description = intl.formatMessage({
                  id: "CALENDAR.REQUIRED_FIELD"
                });
              }

              if(values.description && values.description.length > 140) {
                errors.description = intl.formatMessage({
                  id: "CALENDAR.MAX_CHARACTER"
                });
              }

              if (!values.category_id) {
                errors.category_id = intl.formatMessage({
                  id: "CALENDAR.REQUIRED_FIELD"
                });
              }

              if (providers.length !==0 && !values.provider_id) {
                errors.provider_id = intl.formatMessage({
                  id: "CALENDAR.REQUIRED_FIELD"
                });
              }

              if (!values.group_id) {
                errors.group_id = intl.formatMessage({
                  id: "CALENDAR.REQUIRED_FIELD"
                });
              }

              if (!values.start_date) {
                errors.start_date = intl.formatMessage({
                  id: "CALENDAR.REQUIRED_FIELD"
                });
              }else if(values.start_date <= new Date()){
                errors.start_date = intl.formatMessage({
                  id: "CALENDAR.START_DATE_RESTRICTION"
                });
              }

              if (!values.repeat_type) {
                errors.repeat_type = intl.formatMessage({
                  id: "CALENDAR.REQUIRED_FIELD"
                });
              }

              if(endDateValidation(values)) {
                errors.end_date = intl.formatMessage({
                  id: "CALENDAR.END_DATE_RESTRICTION"
                });
              }

              return errors;
            }
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            if(values.repeat_type === REPEAT_TYPES.NO_REPEAT) {
              let new_end_date = new Date(values.start_date);
              values.end_date = new Date(new_end_date.setHours(23, 59, 59, 999));
            }
            if (cloning) {
              if(props.event.repeat_type === REPEAT_TYPES.NO_REPEAT) {
                ConfirmationPopup({
                  title: intl.formatMessage({
                    id: "CALENDAR.SAVE_AS_NEW.TITLE",
                  }),
                  description: intl.formatMessage({
                    id: "CALENDAR.SAVE_AS_NEW.NO_REPEAT_DESCRIPTION",
                  }),
                  cancelLabel: intl.formatMessage({
                    id: "GENERAL.CANCEL",
                  }),
                  okLabel: intl.formatMessage({
                    id: "CALENDAR.SAVE_AS_NEW.BUTTON",
                  }),
                  okAction: () => {
                    createReminder(values, setSubmitting, setStatus)
                  },
                  closeAction: () => {
                    setFormLoading(false);
                    setSubmitting(false);
                  }
                });
              }
              else {
                ConfirmationPopup({
                  title: intl.formatMessage({
                    id: "CALENDAR.SAVE_AS_NEW.TITLE",
                  }),
                  description: intl.formatMessage({
                    id: "CALENDAR.SAVE_AS_NEW.DESCRIPTION",
                  }),
                  cancelLabel: intl.formatMessage({
                    id: "GENERAL.CANCEL",
                  }),
                  okLabel: intl.formatMessage({
                    id: "CALENDAR.SAVE_AS_NEW.CURRENT_RECURRENCE",
                  }),
                  thirdLabel: intl.formatMessage({
                    id: "CALENDAR.SAVE_AS_NEW.FUTURE_RECURRENCE",
                  }),
                  fourthLabel: intl.formatMessage({
                    id: "CALENDAR.SAVE_AS_NEW.WHOLE_REMINDER",
                  }),
                  maxWidth: "md",
                  closeAction: () => {
                    setFormLoading(false);
                    setSubmitting(false);
                  },
                  okAction: () => {
                    values.repeat_type = 'no_repeat'
                    values.end_date = values.start_date
                    createReminder(values, setSubmitting, setStatus)
                  },
                  thirdAction: () => {
                    createReminder(values, setSubmitting, setStatus)
                  },
                  fourthAction: () => {
                    values.start_date = startDateChangeCheck(values.start_date)
                    createReminder(values, setSubmitting, setStatus)
                  },
                });
              }
              setCloning(false)
            }
            else if(!isOwnReminder() && !props.pastEvent) {
              updateNotificationChannel(values, setSubmitting, setStatus);
            }
            else if(props.event.id) {
              let editedFields = Object.keys(values).filter((key) => {
                switch(key) {
                  case 'start_date':
                    return moment(values[key])?.valueOf() !== moment(props.event['start'])?.valueOf()
                  case 'end_date':
                    return moment(values[key])?.valueOf() !== moment(props.event[key])?.valueOf()
                  case 'description':
                    return values[key] !== props.event['title']
                  case 'provider_id':
                    if (props.event[key] === null)
                      return values[key] !== ''
                    else
                      return values[key] !== props.event[key]
                  case 'user_id':
                    return false
                  default:
                    return values[key] !== props.event[key]
                }
              })
              const onlyEndDateEdited = editedFields.length === 1 && editedFields[0] === 'end_date'
              if(props.event.repeat_type === REPEAT_TYPES.NO_REPEAT || onlyEndDateEdited) {
                setFormLoading(true);
                if (onlyEndDateEdited) {
                  values.start_date = correctReminderStartDate(values.start_date)
                }
                updateAll(values, setSubmitting, setStatus);
              } else {
                ConfirmationPopup({
                  title: intl.formatMessage({
                    id: "CALENDAR.UPDATE_EVENT",
                  }),
                  description: intl.formatMessage({
                    id: "CALENDAR.SURE_UPDATE",
                  }),
                  okLabel: intl.formatMessage({
                    id: "CALENDAR.UPDATE_THIS",
                  }),
                  cancelLabel: intl.formatMessage({
                    id: "GENERAL.CANCEL",
                  }),
                  thirdLabel: intl.formatMessage({
                    id: "CALENDAR.UPDATE_FUTURE",
                  }),
                  fourthLabel: intl.formatMessage({
                    id: "CALENDAR.UPDATE_ALL",
                  }),
                  maxWidth: "md",
                  fourthAction: () => {
                    setFormLoading(true);
                    values.start_date = startDateChangeCheck(values.start_date)
                    updateAll(values, setSubmitting, setStatus);
                  },
                  closeAction: () => {
                    setDeleting(false);
                    setFormLoading(false);
                    setSubmitting(false);
                  },
                  thirdAction: () => {
                    setFormLoading(true);
                    if(props.event.start_date.toDateString() === values.start_date.toDateString()) {
                      values.start_date = correctReminderStartDate(values.start_date)
                      updateAll(values, setSubmitting, setStatus);
                    } else {
                      updateThisAndFuture(values, setSubmitting, setStatus);
                    }
                  },
                  okAction: () => {
                    setFormLoading(true);
                    values.repeat_type = 'no_repeat'
                    values.end_date = values.start_date
                    updateThis(values, setSubmitting, setStatus);
                  }
                });
              }
            } else{
              createReminder(values, setSubmitting, setStatus)
            }
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <form
              noValidate={true}
              id="calendar-form"
              autoComplete="off"
              className={`kt-form py-4 calendar-form-height
                ${clsx ({"create-height-custom-recurrence": (values.repeat_type === "custom" && !props.event.id )})}
                ${clsx ({"update-height-custom-recurrence": (values.repeat_type === "custom" && props.event.id )})}
                ${clsx ({"update-height-end-date": (values.repeat_type !== "no_repeat" && props.event.id )})}
                ${clsx({"create-height-end-date" : ((values.repeat_type !== "no_repeat" && !props.event.id ))})}
                ${clsx ({"shared-height": (values.repeat_type === "no_repeat" && props.event.is_shared_reminder && !props.event.is_group_reminder )})}
                ${clsx ({"shared-height-end-date": (values.repeat_type !== "no_repeat" && props.event.is_shared_reminder && !props.event.is_group_reminder )})} `}
              onSubmit={handleSubmit}
            >
              {
                status &&
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              }

              <div className="form-group mb-0">
                <Autocomplete
                  freeSolo
                  disableClearable
                  id="description-autoComplete"
                  filterOptions={filterOptions}
                  options={categoryProviders.map((option) => option.description)}
                  inputValue={values.description}
                  onInputChange={ (e, value) => {
                    handleDescriptionChange(setFieldValue, value)
                  }}
                  disabled={props.pastEvent || !isOwnReminder()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="text"
                      margin="normal"
                      variant="outlined"
                      id="description-textField"
                      label={intl.formatMessage({id: "CALENDAR.DESCRIPTION"})}
                      className="kt-width-full my-0"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.description && errors.description ? errors.description : " "}
                      error={Boolean(touched.description && errors.description)}
                    />
                  )}
                />
              </div>
              <div className="form-group mb-0">
                <FormGroup row>
                  <DateTimePicker
                    margin="normal"
                    label={intl.formatMessage({id: "CALENDAR.TRIGGER_AT"})}
                    value={values.start_date}
                    name='start_date'
                    className="calendar-form-row pad-r"
                    inputVariant="outlined"
                    format='EEE MMM dd yyyy hh:mm a'
                    onChange={ value => {
                      setFieldValue("start_date", value);
                      repeatOptionsValue(value);
                    }}
                    minDate={new Date()}
                    helperText={touched.start_date && errors.start_date ? errors.start_date : " "}
                    error={Boolean(touched.start_date && errors.start_date)}
                    disabled={props.pastEvent || !isOwnReminder()}
                  />
                  <TextField
                    select
                    margin="normal"
                    label={intl.formatMessage({id: "CALENDAR.REPEAT"})}
                    className="calendar-form-row pad-l"
                    variant="outlined"
                    name="repeat_type"
                    onBlur={handleBlur}
                    onChange={e => {
                      setFieldValue('repeat_type', e.target.value);
                      if(!props.event.id && e.target.value === REPEAT_TYPES.CUSTOM) {
                        setShowModal(true);
                      }
                    }}
                    value={repeatOptions.length !== 0 ? values.repeat_type : ""}
                    helperText={touched.repeat_type && errors.repeat_type ? errors.repeat_type : " "}
                    error={Boolean(touched.repeat_type && errors.repeat_type)}
                    disabled={props.pastEvent || !isOwnReminder() || repeatOptions.length === 0}
                  >
                    {repeatOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.text}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormGroup>
              </div>
              {values.repeat_type === REPEAT_TYPES.CUSTOM && (
                <div className="form-group mb-0">
                  <TextField
                    margin="normal"
                    label={intl.formatMessage({id: "CALENDAR.CUSTOM_RECURRENCE.REPEAT_ON"})}
                    variant="outlined"
                    name="custom_option"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={
                      values.custom_recurrence.repeat_type
                        ? values.custom_recurrence.repeat_type === "day"
                          ? intl.formatMessage(
                              { id: "CALENDAR.CUSTOM_RECURRENCE.DAY_REPEAT" },
                              {
                                day_count: values.custom_recurrence.repeat_number,
                              }
                            )
                          : values.custom_recurrence.repeat_type === "week"
                          ? intl.formatMessage(
                              {
                                id: "CALENDAR.CUSTOM_RECURRENCE.WEEK_REPEAT",
                              },
                              {
                                week_days: values.custom_recurrence.repeat_days
                                  .split(",")
                                  .map((c) =>
                                    intl.formatMessage({
                                      id: `CALENDAR.CUSTOM_RECURRENCE.${c}`,
                                    })
                                  )
                                  .join(", "),
                              }
                            )
                          : values.custom_recurrence.monthly_option
                        : " "
                    }
                    helperText={" "}
                    error={""}
                    disabled={props.pastEvent}
                    contentEditable={false}
                    onClick={() => {
                      if(!props.pastEvent)
                        setShowModal(true)
                    }}
                  />
                </div>
              )}
              {values.repeat_type === 'custom' && (
                <div className="form-group my-0 px-3">
                  <CustomRecurrence
                    reminderID={props.event.id}
                    formKey={new Date()}
                    showModal={showModal}
                    custom_recurrence={values.custom_recurrence}
                    pastEvent={props.pastEvent}
                    selected_date={values.start_date}
                    hideModal={() => {
                      setShowModal(false);
                      setFieldValue("repeat_type", REPEAT_TYPES.NO_REPEAT)
                    }}
                    onSubmitHandler={(custom_values) => {
                      setShowModal(false);
                      setFieldValue('custom_recurrence', custom_values);
                    }}
                  />
                </div>
              )}
              {categories.length !== 0 &&
                <div className="form-group-row mb-0">
                  <FormGroup row>
                    <TextField
                      select
                      margin="normal"
                      label={intl.formatMessage({id: "CALENDAR.CATEGORY"})}
                      className="calendar-form-row pad-r"
                      name="category_id"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={ e => {
                        handleCategoryChange(e.target.value);
                        setFieldValue("category_id", e.target.value);
                        setFieldValue("provider_id", "");
                      }}
                      value={values.category_id}
                      helperText={touched.category_id && errors.category_id ? errors.category_id : " "}
                      error={Boolean(touched.category_id && errors.category_id)}
                      disabled={props.pastEvent || !isOwnReminder()}
                    >

                    {categories.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.description}
                          {option.icon_file_path && (
                            <img
                              className="float-right"
                              height={15}
                              src={process.env.REACT_APP_BASE_URL + option.icon_file_path}
                              alt=""
                            />
                          )}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      select
                      margin="normal"
                      label={intl.formatMessage({id: "CALENDAR.PROVIDER"})}
                      className="calendar-form-row pad-l"
                      name="provider_id"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.provider_id}
                      helperText={touched.provider_id && errors.provider_id ? errors.provider_id : " "}
                      error={Boolean(touched.provider_id && errors.provider_id)}
                      disabled={props.pastEvent || !isOwnReminder() || providers.length === 0}
                    >
                        {providers.map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            <div className="d-flex justify-content-between">
                              <div className="text-truncate">{option.name}</div>
                              <img
                                className="float-right"
                                height={15}
                                src={process.env.REACT_APP_BASE_URL + option.icon_file_path}
                                alt=""
                              />
                            </div>
                          </MenuItem>
                        ))}
                    </TextField>
                  </FormGroup>
                </div>
              }
              { (!props.event.is_shared_reminder || props.event.is_group_reminder) &&
                <div className="form-group mb-0">
                  <TextField
                    select
                    margin="normal"
                    label={intl.formatMessage({id: "CALENDAR.GROUP"})}
                    variant="outlined"
                    name="group_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.group_id}
                    helperText={touched.group_id && errors.group_id ? errors.group_id : " "}
                    error={Boolean(touched.group_id && errors.group_id)}
                    disabled={props.pastEvent || !isOwnReminder()}
                  >

                  {groups.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              }

              { values.repeat_type !== "no_repeat"  &&
                <div className="form-group mb-0">
                <DatePicker
                  margin="normal"
                  inputVariant="outlined"
                  label={intl.formatMessage({id: "CALENDAR.END_DATE"})}
                  value={values.end_date}
                  name='end_date'
                  className="kt-width-full"
                  minDate={values.start_date}
                  format='EEE MMM dd yyyy'
                  clearable
                  onChange={ value => {
                    setFieldValue("end_date", value !== null ? new Date(value.setHours(23, 59, 59, 999)) : value);
                  }}
                  disabled={props.pastEvent || !isOwnReminder()}
                  helperText={
                    !values.end_date
                      ? intl.formatMessage({ id: "CALENDAR.END_DATE_MESSAGE" })
                      : touched.end_date && errors.end_date
                      ? errors.end_date
                      : " "
                  }
                  error={Boolean(touched.end_date && errors.end_date)}
                />
              </div>}
              <div className="form-group mb-1 px-3 mt-1">
                <FormGroup row className="align-items-center">
                  <FormLabel  className="w-25" component="legend" disabled={props.pastEvent || props.event.is_shared_reminder}>
                    <FormattedMessage id="CALENDAR.COLOR" />
                  </FormLabel>
                  <RadioGroup
                    row aria-label="color"
                    name="color"
                    value={values.color}
                    onChange={(e, value) => {
                      setFieldValue("color", parseInt(value))
                    }}
                    >
                    {colors.map((clr, index) => (
                      <FormControlLabel
                        key={clr.code}
                        value={index}
                        control={
                          <StyledRadio
                            clr={clr.code}
                          />
                        }
                        disabled={props.pastEvent || !isOwnReminder()}
                      />)
                    )}
                  </RadioGroup>
                </FormGroup>
              </div>

              <div className="form-group mb-2 px-3 mt-1">
                <FormGroup row className="align-items-center">
                  <FormLabel component="legend" className="w-25">
                    <FormattedMessage id="CALENDAR.NOTIFY_VIA" />
                  </FormLabel>
                  <FormGroup row>
                    <CustomTooltip
                      title={props.pastEvent || props.user.notify_via_email ? "" : intl.formatMessage({id: "CALENDAR.NOTIFY_VIA_TOOLTIP"})}
                      arrow
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="notify_via_email"
                            checked={values.notify_via_email}
                            color="primary"
                            onChange={handleChange}
                          />
                        }
                        label="Email"
                        disabled={props.pastEvent || !props.user.notify_via_email}
                      />
                    </CustomTooltip>
                    <CustomTooltip
                      title={props.pastEvent || props.user.notify_via_telegram ?
                        "" : intl.formatMessage({id: "CALENDAR.NOTIFY_VIA_TOOLTIP"})}
                      arrow
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="notify_via_telegram"
                            checked={values.notify_via_telegram}
                            color="primary"
                            onChange={handleChange}
                          />
                        }
                        label="Telegram"
                        disabled={props.pastEvent || !props.user.notify_via_telegram}
                      />
                    </CustomTooltip>
                  </FormGroup>
                </FormGroup>
                {!props.user.telegram_chat_id && values.notify_via_telegram ? (
                  <div className="form-group">
                    <span className="font-weight-normal">
                      Please <a
                        className="font-weight-bold"
                        href={telegramLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedMessage id="CALENDAR.CLICK_HERE" />
                      </a><FormattedMessage id="CALENDAR.TELEGRAM" />
                    </span>
                  </div>
                ) : null}
              </div>

              <div className="float-right kt-login__actions">
                <button
                  type="button"
                  disabled={isSubmitting}
                  className="btn btn-pill btn-outline-dark btn-elevate kt-login__btn-danger"
                  onClick={props.onHide}
                >
                  <FormattedMessage id="GENERAL.CLOSE" />
                </button>
                {(props.event.id) &&
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className={`btn btn-pill btn-info btn-elevate kt-login__btn-info ml-3
                      ${!props.pastEvent && isOwnReminder() ? 'hide-button' : ''}`}
                    onClick={ () => {
                      cloneReminder(handleSubmit)
                    }}
                  >
                    <FormattedMessage id={props.pastEvent || !isOwnReminder() ? 'CALENDAR.SAVE_AS_NEW.CLONE': 'CALENDAR.SAVE_AS_NEW.BUTTON'} />
                  </button>
                }
                {(props.event.is_shared_reminder && !props.event.is_group_reminder) &&
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className="btn btn-pill btn-danger btn-elevate kt-login__btn-danger ml-3"
                    onClick={rejectSharedReminder}
                  >
                    <FormattedMessage id="GENERAL.REJECT" />
                  </button>
                }
                {(props.event.id && isOwnReminder())  &&
                  <button
                    type="button"
                    disabled={isSubmitting || deleting}
                    className={`btn btn-pill btn-danger btn-elevate kt-login__btn-danger ml-3
                      ${!props.pastEvent ? 'hide-button' : ''}`}
                    onClick={deleteReminder}
                  >
                    <FormattedMessage id="GENERAL.DELETE" />
                  </button>
                }
                {(!props.pastEvent && isOwnReminder() && !props.event.id) && (
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting || props.event.is_shared_reminder}
                    className={`my-1 btn btn-pill btn-primary btn-elevate kt-login__btn-primary ml-3`}
                  >
                    <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                  </button>
                )}
                {(!props.pastEvent && isOwnReminder() && props.event.id) && (
                  <Dropdown as={ButtonGroup} className="dropdown-button">
                    <Button
                      className={`btn btn-pill btn-primary btn-elevate kt-login__btn-primary ml-3`}
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="GENERAL.UPDATE" />
                    </Button>

                    <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" className="btn btn-pill btn-primary btn-elevate"/>

                    <Dropdown.Menu className="form-dropdown-button">
                      <Dropdown.Item
                        className="text-dark"
                        onClick={ () => {
                          cloneReminder(handleSubmit)
                        }}
                        disabled={isSubmitting}
                      >
                        <FormattedMessage id="CALENDAR.SAVE_AS_NEW.BUTTON" />
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-dark"
                        disabled={isSubmitting || deleting}
                        onClick={deleteReminder}
                      >
                        <FormattedMessage id="GENERAL.DELETE" />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {(!props.pastEvent && props.event.id) && (
                  <Button
                    className={`btn btn-pill btn-primary btn-elevate kt-login__btn-primary ml-3
                      ${!props.pastEvent && isOwnReminder() ? 'hide-button' : ''}`}
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="GENERAL.UPDATE" />
                  </Button>
                )}
              </div>

            </form>
          )}
        </Formik>
      </MuiPickersUtilsProvider>
    );

  return (
    <>
    {formLoading ? (
      <div className={`calendar-form-height py-4 ${clsx ({"calendar-update-height": props.event.id})}`}>
        { loader }
      </div>
    ) : (props.event.id && isOwnReminder() && !props.pastEvent ? (
      <>
        <Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={changeTabValue} >
          <Tab className="w-50" label={intl.formatMessage({ id: "CALENDAR.UPDATE_EVENT" })} />
          <Tab className="w-50" label={intl.formatMessage({ id: "CALENDAR.SHARE.PEOPLE" })}/>
        </Tabs>
        {tabValue === 0 && <TabContainer>{formCalendar}</TabContainer>}
        {tabValue === 1 &&
          <TabContainer>
            <div className={`calendar-form-height ${clsx({"calendar-update-height": props.event.repeat_type !== "no_repeat"})} py-4`}>
              <ShareEvent key={"share-event-"+props.shareKey.toString()} event={props.event} changeShareCount={props.changeShareCount}/>
            </div>
          </TabContainer>
        }
      </>
    ) : formCalendar)}
    </>
  );
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps, auth.actions)(CalendarForm);
