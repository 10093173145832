import React, { useState, useEffect } from "react";
import { Link} from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "_metronic/_assets/sass/zen_reminder/unsubscribe_email.scss";
import { FormattedMessage } from "react-intl";

export default function DisplayMessage(props) {
  let subtitle, description;
  let urlSearchParams = (new URLSearchParams(window.location.search))
  let email_confirmed_param = urlSearchParams.get("email_confirmed")
  let email_unsubscribed_param = urlSearchParams.get("email_unsubscribed")
  let shared_reminder_param = urlSearchParams.get("share_reminder_action")

  const [seconds, setSeconds] = useState(7);

  useEffect(() => {
    setTimeout(() => {
      if (seconds < 1) {
        props.history.push("/dashboard");
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
  })

  if(!!email_confirmed_param) {
    if (email_confirmed_param === 'true') {
      subtitle = "EMAIL.CONFIRMATION.SUCCESS.SUBTITLE"
      description = "EMAIL.CONFIRMATION.SUCCESS.DESCRIPTION"
    } else {
      subtitle = "EMAIL.CONFIRMATION.FAILURE.SUBTITLE"
      description = "EMAIL.CONFIRMATION.FAILURE.DESCRIPTION"
    }
  }
  else if(!!email_unsubscribed_param) {
    subtitle = "EMAIL.UNSUBSCRIBE.SUBTITLE"
    description = "EMAIL.UNSUBSCRIBE.DESCRIPTION"
  }
  else if(!!shared_reminder_param) {
    if (shared_reminder_param === 'success') {
      if(urlSearchParams.get("status") === 'accepted') {
        subtitle = "EMAIL.SHARED_REMINDER.ACCEPT.SUBTITLE"
        description = "EMAIL.SHARED_REMINDER.ACCEPT.DESCRIPTION"
      }
      else {
        subtitle = "EMAIL.SHARED_REMINDER.REJECT.SUBTITLE"
        description = "EMAIL.SHARED_REMINDER.REJECT.DESCRIPTION"
      }
    }
    else {
      subtitle = "EMAIL.SHARED_REMINDER.FAILURE.SUBTITLE"
      description = "EMAIL.SHARED_REMINDER.FAILURE.DESCRIPTION"
    }
  }

  return (
    <>
      <div
        className="unsubscribe-email"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/demos/demo4/header.jpg")})`
        }}
      >
        <div className="body-container">
          <div className="image-container">
            <Link to="/" className="">
              <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/logo-mini-md.png")}
              />
            </Link>
          </div>
          <div className="sub-title kt-font-light">
            <h3><FormattedMessage id={subtitle} /></h3>
          </div>

          <p className="description kt-font-light">
            <FormattedMessage id={description} />
          </p>
          <br/><br/>
          <p className="kt-font-light">
            <FormattedMessage
              id="REDIRECTION.MESSAGE"
              values={{
                seconds,
                dashboard: (
                  <Link
                    to="/dashboard"
                    className="kt-font-light font-weight-bold text-lowercase"
                  >
                    <FormattedMessage id="MENU.DASHBOARD" />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
      </div>
    </>
  );
}
