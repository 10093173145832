import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { metronic, toAbsoluteUrl } from "../../../_metronic";
import {
  Portlet,
  PortletBody,
  PortletHeader
} from "app/partials/content/Portlet";
import { useIntl, FormattedMessage } from "react-intl";
import '_metronic/_assets/sass/zen_reminder/invite_page.scss';
import { Table } from "react-bootstrap";
import cogoToast from "cogo-toast";
import axios from "axios";
import moment from 'moment'
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/de';
import SkeletonLoader from "tiny-skeleton-loader-react";
import { Redirect } from "react-router-dom";

export default function InvitePage(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [redirect, setRedirect] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const { layoutConfig } = useSelector(
    ({ builder }) => ({ layoutConfig: builder.layoutConfig }),
    shallowEqual
  );
  const loader = Array.apply(null, { length: 6 }).map((e, i) => (
    <SkeletonLoader className="skeleton-loader" key={'loader'+i} width="100%" height="80px"/>
  ));
  moment.locale(intl.locale);

  useEffect(() => {
    layoutConfig.subheader.display = true
    dispatch(metronic.builder.actions.setLayoutConfigs(layoutConfig));
  });

  useEffect(() => {
    var urlParams = new URLSearchParams(window.location.search)
    if(urlParams.get('initialPage') === 'true'){
      setRedirect(true);
    }
    axios.get("reminders/shared_reminders_list").then((res) => {
      setReminders(res.data.data);
      setLoading(false);
    }).catch((_)=>{
      cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
        heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
      setLoading(false);
    });
  }, [intl]);

  const getFrequency = (date, repeat_type) => {
    let dateString = moment(date).format('MMMM Do dddd').split(" ");
    switch (repeat_type) {
      case "daily":
        return intl.formatMessage({id: "CALENDAR.DAILY"});
      case "weekly":
        return intl.formatMessage({id: "CALENDAR.WEEKLY_ON"}, {day: dateString[2]});
      case "monthly":
        return intl.formatMessage({id: "CALENDAR.MONTHLY_ON"}, {date: dateString[1]});
      case "anually":
        return intl.formatMessage({id: "CALENDAR.ANNUALY_ON"}, {month: dateString[0], date: dateString[1]})
      default:
        return intl.formatMessage({id: "CALENDAR.DOES_NOT_REPEAT"});
    }
  }

  const removeReminder = (index) => {
    var newValues = [...reminders]
    newValues.splice(index, 1);
    setReminders(newValues);
  }

  const reminderClickAction = (event, index, action) =>{
    setSubmitting(true);
    axios.patch(`reminders/${event.attributes.id}/update_shared_reminder`, {status: action}).then((res) => {
      removeReminder(index);
      cogoToast.success(intl.formatMessage({ id: action === 'accepted' ? "CALENDAR.SHARE.ACCEPT_MESSAGE" : "CALENDAR.SHARE.REJECT_MESSAGE" }), {
        heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
      setSubmitting(false);
    }).catch((_)=>{
      cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
        heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
      setSubmitting(false);
    });
  }

  return (
    <>
      <div className="zen-invite-page card">
        <Portlet fluidHeight={true} className="h-100">
          <PortletHeader
            title={intl.formatMessage({ id: "MENU.PENDING_INVITES" })}
          />
          <PortletBody>
            { loading ? loader : (
              reminders.length === 0 ?
                (redirect ?
                  <Redirect to="/dashboard" /> :
                  (
                    <div className="calendar-no-data text-dark">
                      <div className="d-flex flex-column align-items-center w-100 p-2">
                        <img
                          alt="Logo"
                          src={toAbsoluteUrl("/media/bg/no-invites.png")}
                          className="align-self-center"
                        />
                        <span className="my-2 text-center">
                          <FormattedMessage id={"CALENDAR.SHARE.NO_INVITES"} />
                        </span>
                      </div>
                    </div>
                  )
                ) : (
                <Table responsive striped hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th><FormattedMessage id="CALENDAR.SHARED_BY" /></th>
                      <th><FormattedMessage id="CALENDAR.DESCRIPTION" /></th>
                      <th><FormattedMessage id="CALENDAR.AT" /></th>
                      <th><FormattedMessage id="CALENDAR.FREQUENCY" /></th>
                      <th><FormattedMessage id="MENU.ACTIONS" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {reminders.map((reminder, index) => (
                      <tr key={reminder.attributes.id}>
                        <td>{index + 1}</td>
                        <td>
                          {reminder.attributes.user_full_name}
                        </td>
                        <td>
                          {reminder.attributes.description}
                        </td>
                        <td>
                          {moment(reminder.attributes.start_date).format("ddd MMM DD YYYY hh:mm A")}
                        </td>
                        <td className="text-capitalize">
                          {getFrequency(reminder.attributes.start_date, reminder.attributes.repeat_type)}
                        </td>
                        <td>
                          <div className="d-flex flex-wrap">
                            <button type="button" className="btn mr-3 mb-2 btn-pill btn-primary btn-elevate kt-login__btn-danger" disabled={isSubmitting} onClick={() => reminderClickAction(reminder, index, 'accepted')}><FormattedMessage id="CALENDAR.SHARE.ACCEPT" /></button>
                            <button type="button" className="btn mb-2 btn-pill btn-outline-danger btn-elevate kt-login__btn-danger" disabled={isSubmitting}  onClick={() => reminderClickAction(reminder, index, 'rejected')}><FormattedMessage id="CALENDAR.SHARE.REJECT" /></button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )
            )}
          </PortletBody>
        </Portlet>
      </div>
    </>
  );
}

