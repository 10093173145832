import axios from "axios";

export const LOGIN_URL = "sign_in";
export const LOGOUT_URL = "sign_out";
export const REGISTER_URL = "users";
export const PASSWORD_RESET_URL = "password_reset";
export const VERIFY_TOKEN = "password_reset/verify_token";
export const UPDATE_LOCALE_URL = "users/update_locale";

export const ME_URL = "users/my_profile";

export function login(email, password) {
  return axios.post(LOGIN_URL, { session: { email, password } });
}

export function logout(delete_all_sessions = false) {
  return axios.delete(LOGOUT_URL, { data: { delete_all_sessions } });
}

export function register(email, first_name, last_name, password, time_zone, locale, registered_via) {
  return axios.post(REGISTER_URL, {
    user: { email, first_name, last_name, password, time_zone, locale, registered_via},
  });
}

export function requestPassword(email) {
  return axios.post(PASSWORD_RESET_URL, { email });
}

export function getCurrentUser() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function verifyPasswordResetToken(token, user_id) {
  return axios.post(VERIFY_TOKEN, { token, user_id });
}

export function updatePassword(token, password) {
  return axios.patch(PASSWORD_RESET_URL, { token, password });
}

export function updateLocale(locale) {
  return axios.patch(UPDATE_LOCALE_URL, { locale });
}
