import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { Checkbox, FormControlLabel, TextField, MenuItem, InputAdornment, IconButton } from "@material-ui/core";
import { Modal } from 'react-bootstrap';
import * as auth from "../../store/ducks/auth.duck";
import { register } from "../../crud/auth.crud";
import '_metronic/_assets/sass/zen_reminder/registration.scss';
import cogoToast from "cogo-toast";
import axios from "axios";
import { metronic, passwordRegexCheck } from "../../../_metronic";
import { Flip } from 'react-reveal';
import { toInputLowercase } from "_metronic/utils/utils";

export const languages = [
  {
    lang: "en",
    name: "English"
  },
  {
    lang: "es",
    name: "Español"
  },
  {
    lang: "de",
    name: "Deutsche"
  },
  {
    lang: "fr",
    name: "Français"
  }
];

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      terms_content: '',
      showPassword1: false,
      showPassword2: false
    }

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleShowPassword = this.handleShowPassword.bind(this)
  }

  componentDidMount() {
    axios.get('/contents/terms_and_conditions').then((res)=>{
      this.setState({ terms_content: res.data.content})
    }).catch((error)=>{
      cogoToast.error(this.props.intl.formatMessage({ id: error.response !== undefined ? error.response.data.message : "GENERAL.ERROR_MESSAGE" }), {
        heading: this.props.intl.formatMessage({ id: "GENERAL.ERROR" }),
        bar: {size: '10px'},
        position: 'top-right'
      });
    });
    this.props.changeBackground('/media/bg/sign-up.jpg')
  }

  handleShow() {
    this.setState({show: true})
  }

  handleClose() {
    this.setState({show: false})
  }

  handleShowPassword(value) {
    if(value === 1) {
      this.setState({ showPassword1: !this.state.showPassword1 })
    } else {
      this.setState({ showPassword2: !this.state.showPassword2 })
    }
  }

  initialEmailValue() {
    return (new URLSearchParams(window.location.search)).get("email")
  }

  render() {
    const { intl, setLanguage } = this.props;
    const { showPassword1, showPassword2 } = this.state;

    return (
      <div>
        <div className="text-dark text-center mb-3">
          <h3 className="font-weight-bold">
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
        </div>

        <Formik
          initialValues={{
            email: this.initialEmailValue() || "",
            first_name: "",
            last_name: "",
            password: "",
            acceptTerms: false,
            confirmPassword: "",
            locale: "en",
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
          }}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_FIELD"
              });
            }

            if (!values.first_name || !values.first_name.trim()) {
              errors.first_name = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if(values.first_name && values.first_name.length > 50) {
              errors.first_name = intl.formatMessage({
                id: "PROFILE.MAX_CHARACTER"
              });
            }

            if (!values.last_name || !values.last_name.trim()) {
              errors.last_name = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if(values.last_name && values.last_name.length > 50) {
              errors.last_name = intl.formatMessage({
                id: "PROFILE.MAX_CHARACTER"
              });
            }
            const passwordValidation = passwordRegexCheck(values.password)
            if(passwordValidation !== null){
              errors.password = intl.formatMessage({
                id: passwordValidation
              });
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
              intl.formatMessage({
                id: "AUTH.VALIDATION.PASSWORDS_MATCH_FAILED",
                defaultMessage: "Password and Confirm Password didn't match."
              });
            }

            if (!values.acceptTerms) {
              errors.acceptTerms = intl.formatMessage({
                id: "AUTH.VALIDATION.ACCEPT_TERMS",
                defaultMessage: "Accept Terms"
              });;
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            values.registered_via = "email"
            register(
              values.email,
              values.first_name,
              values.last_name,
              values.password,
              values.time_zone,
              values.locale,
              values.registered_via
            )
              .then((response) => {
                setLanguage(values.locale);
                cogoToast.success(this.props.intl.formatMessage({ id: "AUTH.REGISTER.SUCCESS" }), {
                  heading: this.props.intl.formatMessage({ id: "GENERAL.SUCCESS" }),
                  bar: {size: '10px'},
                  position: 'top-right'
                });
                cogoToast.info(this.props.intl.formatMessage({ id: "AUTH.REGISTER.VERIFY_EMAIL" }), {
                  heading: this.props.intl.formatMessage({ id: "GENERAL.SUCCESS" }),
                  bar: {size: '10px'},
                  position: 'top-right'
                });
                this.props.history.push("/auth/login")
              })
              .catch((error) => {
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: error.response !== undefined ? error.response?.data?.message : "GENERAL.ERROR_MESSAGE"
                  })
                );
              });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Flip top>
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group mb-0">
                  <TextField
                    margin="normal"
                    label="First Name"
                    className="kt-width-full"
                    name="first_name"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                    helperText={touched.first_name && errors.first_name ? errors.first_name : " "}
                    error={Boolean(touched.first_name && errors.first_name)}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    margin="normal"
                    label="Last Name"
                    className="kt-width-full"
                    variant="outlined"
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.last_name}
                    helperText={touched.last_name && errors.last_name ? errors.last_name : " "}
                    error={Boolean(touched.last_name && errors.last_name)}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    label="Email"
                    margin="normal"
                    className="kt-width-full"
                    variant="outlined"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    onInput={toInputLowercase}
                    helperText={touched.email && errors.email ? errors.email : " "}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    type={showPassword1 ? "text" : "password"}
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    variant="outlined"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password ? errors.password : " "}
                    error={Boolean(touched.password && errors.password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            tabindex="-1"
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={ () => this.handleShowPassword(1) }
                          >
                            {showPassword1 ? (
                              <i className="la la-eye" />
                            ) : (
                              <i className="la la-eye-slash" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                  type={showPassword2 ? "text" : "password"}
                    margin="normal"
                    label="Confirm Password"
                    className="kt-width-full"
                    variant="outlined"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    helperText={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : " "}
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            tabindex="-1"
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={ () => this.handleShowPassword(2) }
                          >
                            {showPassword2 ? (
                              <i className="la la-eye" />
                            ) : (
                              <i className="la la-eye-slash" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    select
                    margin="normal"
                    label="Language"
                    className="kt-width-full"
                    variant="outlined"
                    name="locale"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.locale}
                    helperText={touched.locale && errors.locale ? errors.locale : " "}
                    error={Boolean(
                      touched.locale && errors.locale
                    )}
                  >
                    {languages.map((language, index) => (
                      <MenuItem key={index} value={language.lang}>{language.name}</MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className="form-group mb-1 px-3">
                  <FormControlLabel
                    label={
                      <>
                        <FormattedMessage id="AUTH.VALIDATION.TERMS_AND_CONDITIONS_PREFIX" defaultMessage="I agree to the " />
                        <span className="text-primary" onClick={this.handleShow}>
                          <FormattedMessage id="AUTH.VALIDATION.TERMS_AND_CONDITIONS" defaultMessage="Terms & Conditions" />
                        </span>
                        <FormattedMessage id="AUTH.VALIDATION.TERMS_AND_CONDITIONS_SUFFIX" defaultMessage=" " />
                      </>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name="acceptTerms"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        checked={values.acceptTerms}
                      />
                    }
                  />
                </div>

                <Modal show={this.state.show} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Terms &amp; Conditions</Modal.Title>
                  </Modal.Header>
                  <Modal.Body
                    className='terms_body'
                    dangerouslySetInnerHTML={{ __html: this.state.terms_content }} >
                  </Modal.Body>
                </Modal>

                <div className="d-flex zen-custom-button">
                  <Link to="/auth" className="flex-fill mr-3">
                    <button type="button" className="btn btn-pill btn-outline-dark btn-elevate w-100">
                      Cancel
                    </button>
                  </Link>

                  <button
                    disabled={isSubmitting || !values.acceptTerms}
                    className="btn btn-pill btn-primary btn-elevate flex-fill ml-3"
                    type="submit"
                  >
                    Register
                  </button>
                </div>
              </form>
            </Flip>
          )}
        </Formik>
        <div className="my-3 kt-divider">
          <span />
        </div>
        <div className="text-center text-dark">
          <span >
            Already have an account?
          </span>
          &nbsp;&nbsp;
          <Link to="/auth/login" className="kt-link kt-link--primary text-primary ">
            Sign In!
          </Link>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...auth.actions,
  ...metronic.i18n.actions,
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(Registration)
);
