import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import Calendar from "./Calendar";
import { LayoutSplashScreen } from "../../../_metronic";
import ProfilePage from "app/pages/home/ProfilePage";
import CalendarNotification from "./CalendarNotification";
import InvitePage from "./InvitePage";
import GroupsPage from "./GroupsPage";

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/invite_page?initialPage=true" />
        }
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/calendar/:reminder_id/:event_date" render={(props) => (
          <CalendarNotification key={props.match.params.reminder_id + new Date().toString()} {...props} />)
        } />
        <Route path="/calendar" component={Calendar} />
        <Route path="/my_profile" component={ProfilePage} />
        <Route path="/invite_page" component={InvitePage} />
        <Route path="/groups" component={GroupsPage} />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
