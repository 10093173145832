const flatten = require('flat');
const messages = {
  TRANSLATOR: {
    SELECT: "choisissez votre langue"
  },
  MENU: {
    ACTIONS: "Actes",
    DASHBOARD: "Tableau de Bord",
    MY_PROFILE: "Mon Profil",
    SIGN_OUT: "Déconnexion",
    PRIVACY_POLICY: "Politique de confidentialité",
    CONTACT: "Contact",
    NOTIFICATION: "Notification",
    NOTIFICATIONS: "Notifications",
    PENDING_INVITES: "Invitations En attente",
    GROUPS: "Groupes"
  },
  AUTH: {
    GENERAL: {
      SUBMIT_BUTTON: "Soumettre",
      FORGOT_BUTTON: "Mot de passe oublié?"
    },
    LOGIN: {
      TITLE: "Connectez-vous à Zen Reminder",
    },
    FORGOT: {
      TITLE: "Mot de passe oublié?",
      SUCCESS: "Nous venons de vous envoyer un e-mail avec un lien pour réinitialiser votre mot de passe!"
    },
    RESET: {
      TITLE: "Réinitialiser le mot de passe",
      NEW_PASSWORD: "Nouveau mot de passe",
      CONFIRM_PASSWORD: "Confirmez le mot de passe",
      ERROR: "Il y avait une erreur",
      SUCCESS: "Votre mot de passe a été réinitialisé avec succès."
    },
    REGISTER: {
      TITLE: "Inscrivez-vous à Zen Reminder",
      SUCCESS: "Votre compte a été enregistré avec succès.",
      VERIFY_EMAIL: "Veuillez vérifier votre e-mail pour vous connecter."
    },
    VALIDATION: {
      INVALID: "{name} n'est pas valide",
      REQUIRED: "{name} est requis",
      REGISTERED_EMAIL: "ECourriel déjà enregistré. Veuillez essayer de vous connecter",
      EMAIL_IN_USE: "Cet email est déjà utilisé. Veuillez vous inscrire en utilisant un autre e-mail",
      LOGGED_IN: "Connecté",
      LOGGED_OUT: "Déconnecté",
      MIN_LENGTH: "{name}} la longueur minimale est {{min}",
      NOT_FOUND: "Le {name} demandé est introuvable",
      INVALID_LOGIN: "Les informations de connexion sont incorrectes",
      VERIFY_EMAIL: "Veuillez vérifier votre adresse e-mail",
      OAUTH_USER: "Remarque: avez-vous utilisé google/apple pour vous inscrire avec nous?",
      INVITED_USER: "Veuillez vérifier si vous vous êtes inscrit en utilisant le lien d'invitation",
      REQUIRED_FIELD: "champs requis",
      INVALID_FIELD: "Le champ n'est pas valide",
      SAME_ADDITIONAL_EMAIL: "Les e-mails supplémentaires doivent être différents",
      PASSWORDS_MATCH_FAILED: "Le mot de passe et la confirmation du mot de passe ne correspondent pas.",
      ACCEPT_TERMS: "Accepter les termes",
      TERMS_AND_CONDITIONS_PREFIX: "Je suis d'accord avec le ",
      TERMS_AND_CONDITIONS: "Termes et conditions",
      TERMS_AND_CONDITIONS_SUFFIX: " ",
      TERMS_AND_CONDITIONS_NOT_FOUND: "Conditions générales non trouvées",
      PASSWORD_REGEX: "Le mot de passe doit contenir au moins 1 majuscule, 1 minuscule et 1 chiffre",
      PASSWORD_MAX_CHAR: "Mot de passe doit être d'au moins 8 caractères",
      PASSWORD_SPACE: "Le mot de passe ne doit contenir aucun espace blanc",
      INVALID_EMAIL: "S'il vous plaît, mettez une adresse email valide",
      OWN_EMAIL: "Vous ne pouvez pas utiliser votre propre e-mail",
      EXISTING_EMAILS: "Déjà partagé avec {emails}"
    },
    EMAIL_CONFIRMATION: {
      SUCESS: "Vous avez correctement vérifié votre adresse e-mail",
      FAILURE: "Lien de confirmation par e-mail invalide ou expiré"
    }
  },
  CALENDAR: {
    NOTIFY_VIA: "Avertissez via",
    DESCRIPTION: "La description",
    GROUP: "Groupe",
    TRIGGER_AT: "Déclencher à",
    REPEAT: "Répéter",
    REPEAT_ON: "Répéter le",
    START_DATE: "Date de début",
    END_DATE: "Date de fin",
    CATEGORY: "Catégorie",
    PROVIDER: "Fournisseur",
    CALENDAR: "Calendrier",
    CREATE_EVENT: "Créer un rappel",
    NEW_EVENT: "Ajouter un  rappel",
    UPDATE_EVENT: "Mettre à jour l'rappel",
    CLICK_HERE: "Cliquez ici",
    TELEGRAM: " et sélectionnez 'Démarrer' pour commencer à recevoir des notifications dans Telegram",
    START_DATE_RESTRICTION: "Impossible de créer des rappels dans le passé",
    END_DATE_RESTRICTION: "Veuillez choisir une date de fin valide",
    REQUIRED_FIELD: "champs requis",
    MAX_CHARACTER: "Limite de caractères: 140",
    PREVIOUS: "Précédent",
    NEXT: "Prochain",
    TODAY: "Aujourd'hui",
    MONTH: "Mois",
    WEEK: "La Semaine",
    DAY: "Journée",
    AGENDA: "Ordre Du Jour",
    DATE: "Date",
    TIME: "Temps",
    EVENT: "Un Rappel",
    MORE: "Plus",
    DOES_NOT_REPEAT: "Ne se répète pas",
    CUSTOM: "Personnalisé",
    DAILY: "Quotidien",
    WEEKLY_ON: "Hebdomadaire le {day}",
    MONTHLY_ON: "Mensuel le {date}",
    ANNUALY_ON: "Annuellement le {month} {date}",
    SURE_DELETE: "Veuillez choisir la façon dont vous souhaitez supprimer les rappels.",
    DELETE_EVENT: "Supprimer cet rappel",
    DELETE_ALL: "Supprimer tous les rappels",
    DELETE_FUTURE: "Supprimer cet rappel et les rappels suivants",
    CONFIRM_DELETE: "Voulez-vous vraiment supprimer cet rappel?",
    CONFIRM_UPDATE: "Voulez-vous vraiment mettre à jour cet rappel?",
    SURE_UPDATE: "Veuillez choisir la façon dont vous souhaitez mettre à jour les rappels.",
    UPDATE_THIS: "Mettre à jour cet rappel",
    UPDATE_ALL: "Mettre à jour tous les rappels",
    UPDATE_FUTURE: "Mettre à jour cet rappel et les rappels suivants",
    EVENTS: "Rappels",
    TODAYS_EVENTS: "Rappels du jour",
    PLEASE: "S'il vous plaît",
    NO_EVENT: "Il n'y a aucun rappel dans cette gamme.",
    NO_DAY_EVENT: "Il n'y a pas d'rappels ce jour la.",
    COLOR: "Couleur",
    NOTIFY_VIA_TOOLTIP: "Désabonné. Peut être activé dans la page de profil.",
    SHARED_BY: "Partagé par",
    CREATED_BY: "Créé par",
    EMAIL: "E-mail",
    FREQUENCY: "La fréquence",
    AT: "À",
    CREATE_MESSAGE: "Rappel créé avec succès.",
    UPDATE_MESSAGE: "Rappel mis à jour avec succès.",
    DELETE_MESSAGE: "L'rappel a bien été supprimé.",
    FROM_GROUP: "Vous recevez ce rappel car vous appartenez au même groupe que le rappel",
    END_DATE_MESSAGE: "Se répète à l'infini",
    SHARE: {
      SEND: "Envoyer",
      PEOPLE: "Personnes partagées",
      NO_USER: "Vous n'avez partagé ce rappel avec personne",
      REJECT_DESCRIPTION: "Voulez-vous vraiment refuser ce rappel?",
      NO_INVITES: "Il n'y a aucune invitation en attente",
      ACCEPT: "J'accepte",
      REJECT: "Rejeter",
      NOTE: "Remarque: pour les e-mails qui ne sont pas enregistrés chez nous, un lien d'invitation sera envoyé pour vous inscrire à Zen Reminder.",
      ACCEPT_MESSAGE: "Invitation acceptée.",
      REJECT_MESSAGE: "Invitation rejetée.",
      DELETE_MESSAGE: "Utilisateur supprimé de l'rappel.",
      INVITE_MESSAGE: "Invitation envoyée avec succès."
    },
    SAVE_AS_NEW: {
      CLONE: "Cloner",
      BUTTON: "Enregistrer comme nouveau",
      TITLE: "Enregistrer comme nouveau rappel",
      DESCRIPTION: "Veuillez choisir comment vous souhaitez cloner ce rappel",
      WHOLE_REMINDER: "Cloner tous les rappels",
      CURRENT_RECURRENCE: "Cloner cet rappel",
      FUTURE_RECURRENCE: "Cloner cet rappel et les suivants",
      NO_REPEAT_DESCRIPTION: "Voulez-vous enregistrer ce rappel en tant que nouveau rappel?"
    },
    CUSTOM_RECURRENCE: {
      TITLE: "Récurrence personnalisée",
      REPEAT: "Répéter",
      REPEAT_ON: "Répéter le",
      REPEAT_EVERY: "Répétez chaque",
      MONTHLY_ON_DAY: "Mensuellement au jour {day}",
      FIRST: "Première",
      SECOND: "Seconde",
      THIRD: "Troisième",
      FOURTH: "Quatrième",
      FIFTH: "Cinquième",
      SU: "dimanche",
      MON: "Lundi",
      TUE: "Mardi",
      WED: "Mercredi",
      THU: "Jeudi",
      FRI: "Vendredi",
      SAT: "samedi",
      DONE: "Terminé",
      INVALID_REPEAT_NUMBER: "Numéro invalide",
      DAY_REPEAT: "Tous les {day_count} jours",
      WEEK_REPEAT: "Chaque semaine, les {week_days}"
    }
  },
  GENERAL: {
    ERROR: "Erreur",
    ERROR_MESSAGE: "Quelque chose a mal tourné. Veuillez réessayer",
    CLOSE: "Proche",
    DELETE: "Supprimer",
    UPDATE: "Mise à jour",
    SUCCESS: "Succès",
    CANCEL: "Annuler",
    RESET_PASSWORD: "Réinitialiser Le Mot De Passe",
    OK: "D'accord",
  },
  ERROR: {
    GO_BACK_HOME: "Rentrer à la maison"
  },
  EMAIL: {
    UNSUBSCRIBE: {
      SUBTITLE: "Nous sommes désolés si nous avons spammé votre boîte de réception.",
      DESCRIPTION: "Si vous souhaitez reprendre les notifications par e-mail, vous pouvez l'activer à tout moment sur votre page de profil."
    },
    CONFIRMATION: {
      SUCCESS: {
        SUBTITLE: "Vous avez correctement vérifié votre adresse e-mail",
        DESCRIPTION: "Votre e-mail est prêt à recevoir des notifications."
      },
      FAILURE: {
        SUBTITLE: "Une erreur s'est produite lors de la vérification de votre adresse e-mail",
        DESCRIPTION: "Veuillez vérifier si le lien de vérification est valide."
      }
    },
    SHARED_REMINDER: {
      ACCEPT: {
        SUBTITLE: "Nous vous enverrons des notifications pour ce rappel.",
        DESCRIPTION: "Nous vous enverrons des notifications pour ce rappel, vous pouvez consulter la page Invitations si vous souhaitez les désactiver plus tard"
      },
      REJECT: {
        SUBTITLE: "Vous avez refusé l'invitation de rappel partagée",
        DESCRIPTION: "Si vous avez rejeté cette invitation par erreur, veuillez demander une autre invitation"
      },
      FAILURE: {
        SUBTITLE: "Une erreur s'est produite lors de l'examen de votre entrée",
        DESCRIPTION: "Vérifiez si vous avez déjà accepté / rejeté cette invitation sur la page Invitations"
      }
    }
  },
  PROFILE: {
    FIRST_NAME: "Prénom",
    LAST_NAME: "Nom De Famille",
    LANGUAGE: "Langue",
    MAX_CHARACTER: "Limite de caractères: 50",
    WRONG_PASSWORD: "Le mot de passe actuel entré est incorrect",
    CHANGE_PASS: "Changez Votre Mot De Passe",
    CHANGE_PASSWORD: "Changer Le Mot De passe",
    CURRENT_PASSWORD: "Mot De Passe Actuel",
    NEW_PASSWORD: "Nouveau Mot De Passe",
    CONFIRM_NEW_PASSWORD: "Confirmer Le Nouveau Mot De Passe",
    CONFIRM_TITLE: "Cela déconnectera toutes vos sessions actives. Es-tu sur de vouloir continuer?",
    OK_LABEL: "Oui! Continuer",
    ADDITIONAL_EMAIL_EXISTS: "{email} déja en cours d'utilisation",
    CONFIRM_EMAIL_TOOLTIP: "Veuillez vérifier cette adresse e-mail en cliquant sur le lien envoyé à cet identifiant e-mail",
    NOTIFICATION: "Notification",
    WARNING: "Attention",
    TIMEZONE: "Fuseau Horaire",
    ADDITIONAL_EMAIL_1: "E-mail supplémentaire 1",
    ADDITIONAL_EMAIL_2: "E-mail supplémentaire 2",
    UPDATE_MESSAGE: "Votre profil a bien été mis à jour."
  },
  TIMEZONE_CHANGE: {
    TITLE: "Nouveau fuseau horaire détecté",
    DESCRIPTION: "Nous mettons à jour votre fuseau horaire pour - {new_time_zone}",
  },
  NOTIFICATION: {
    SNOOZED: "Rappel mis en attente pendant une heure.",
    CLEAR_TOOLTIP: "Effacer la notification",
    SNOOZE_TOOLTIP: "Snooze pendant une heure",
    CLEAR_ALL: "Tout effacer"
  },
  GROUP: {
    NAME: "Nom",
    CREATE: "Créer un groupe",
    UPDATE: "Groupe de mise à jour",
    EXIT: "Quitter ce groupe",
    NO_GROUP: "Vous n'avez pas encore de groupe",
    EMAIL_PLACEHOLDER: "E-mails séparés avec la touche Entrée",
    EMAIL_NOTE: "Remarque: séparez les e-mails avec la touche Entrée",
    USER_EMAILS: "E-mails des utilisateurs",
    OWNER: "Propriétaire",
    MEMBERS: "Les membres du groupe",
    TOTAL_MEMBERS: "Nombre total de membres",
    UPDATE_MESSAGE: "Groupe mis à jour avec succès",
    CREATE_MESSAGE: "Groupe créé avec succès",
    DELETE_MESSAGE: "Groupe supprimé avec succès",
    SHARE_DELETE: "L'utilisateur a été supprimé du groupe",
    CONFIRM_EXIT: "Êtes-vous sûr de vouloir quitter le groupe \"{name}\"?",
    CONFIRM_DELETE: "La suppression de ce groupe supprimera également tous les rappels qui lui sont associés. Es-tu sur de vouloir continuer?",
    REMOVE_USER: "Supprimer l'utilisateur",
    ADD_USER_MESSAGE: "L'utilisateur a été ajouté au groupe",
    ADD: "Ajouter",
    NO_USER: "Vous n'avez ajouté aucun utilisateur à ce groupe"
  },
  REDIRECTION: {
    MESSAGE: "Vous serez redirigé vers le {dashboard} dans {seconds} secondes."
  }
}

export default flatten(messages);
