const flatten = require('flat');
const messages = {
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  MENU: {
    ACTIONS: "Actions",
    DASHBOARD: "Dashboard",
    MY_PROFILE: "My Profile",
    SIGN_OUT: "Sign Out",
    PRIVACY_POLICY: "Privacy Policy",
    CONTACT: "Contact",
    NOTIFICATION: "Notification",
    NOTIFICATIONS: "Notifications",
    PENDING_INVITES: "Pending Invites",
    GROUPS: "Groups"
  },
  AUTH: {
    GENERAL: {
      SUBMIT_BUTTON: "Submit",
      FORGOT_BUTTON: "Forgot Password?"
    },
    LOGIN: {
      TITLE: "Sign In to Zen Reminder",
    },
    FORGOT: {
      TITLE: "Forgotten Password?",
      SUCCESS: "We just sent an email to you with a link to reset your password!."
    },
    RESET: {
      TITLE: "Reset Password",
      NEW_PASSWORD: "New Password",
      CONFIRM_PASSWORD: "Confirm Password",
      ERROR: "The password reset link has been expired.",
      SUCCESS: "Your password has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up to Zen Reminder",
      SUCCESS: "Your account has been successfuly registered.",
      VERIFY_EMAIL: "Please verify your email to login."
    },
    VALIDATION: {
      INVALID: "{name} is not valid",
      REQUIRED: "{name} is required",
      REGISTERED_EMAIL: "Email already registered. Please try login",
      EMAIL_IN_USE: "Email is already in use. Please register using a different email",
      LOGGED_IN: "Logged In",
      LOGGED_OUT: "Logged Out",
      MIN_LENGTH: "{name}} minimum length is {{min}",
      NOT_FOUND: "The requested {name} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      USER_NOT_FOUND: "User not found with the given email address",
      INCORRECT_PASSWORD_1: "You have entered an invalid password. 4 retries left",
      INCORRECT_PASSWORD_2: "You have entered an invalid password. 3 retries left",
      INCORRECT_PASSWORD_3: "You have entered an invalid password. 2 retries left",
      INCORRECT_PASSWORD_4: "You have entered an invalid password. 1 retry left",
      INCORRECT_PASSWORD_5: "Sorry! Your account is locked. Please try after an hour.",
      LIMIT_EXCEEDED: "Sorry! Your account is locked. Please try after an hour.",
      OAUTH_USER: "Note: Have you used google/apple to register with us?",
      INVITED_USER: "Please verify if you have registered using the invitation link",
      REQUIRED_FIELD: "Required field",
      INVALID_FIELD: "Field is not valid",
      SAME_ADDITIONAL_EMAIL: "Additional emails must be different",
      PASSWORDS_MATCH_FAILED: "Password and Confirm Password didn't match.",
      ACCEPT_TERMS: "Accept Terms",
      TERMS_AND_CONDITIONS_PREFIX: "I agree to the ",
      TERMS_AND_CONDITIONS: "Terms & Conditions",
      TERMS_AND_CONDITIONS_SUFFIX: " ",
      TERMS_AND_CONDITIONS_NOT_FOUND: "Allgemeine Geschäftsbedingungen nicht gefunden",
      PASSWORD_REGEX: "Password must contain atleast 1 uppercase, 1 lowercase and 1 number",
      PASSWORD_MAX_CHAR: "Password must be atleast 8 characters",
      PASSWORD_SPACE: "Password should not contain any white space",
      INVALID_EMAIL: "Please enter a valid email address",
      OWN_EMAIL: "You cannot use your own email",
      EXISTING_EMAILS: "Already shared with {emails}"
    },
    EMAIL_CONFIRMATION: {
      SUCESS: "You have successfully verified your email address",
      FAILURE: "Invalid or expired Email confirmation link"
    }
  },
  CALENDAR: {
    NOTIFY_VIA: "Notify via",
    DESCRIPTION: "Description",
    GROUP: "Group",
    TRIGGER_AT: "Trigger At",
    REPEAT: "Repeat",
    REPEAT_ON: "Repeat on",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    CATEGORY: "Category",
    PROVIDER: "Provider",
    CALENDAR: "Calendar",
    CREATE_EVENT: "Create Reminder",
    NEW_EVENT: "Add Reminder",
    UPDATE_EVENT: "Update Reminder",
    CLICK_HERE: "Click here",
    TELEGRAM: " and select 'Start' to start receiving notifications in Telegram",
    START_DATE_RESTRICTION: "Can't create reminders in the past",
    END_DATE_RESTRICTION: "Please pick a valid end date",
    REQUIRED_FIELD: "Required field",
    MAX_CHARACTER: "Character limit: 140",
    PREVIOUS: "Previous",
    NEXT: "Next",
    TODAY: "Today",
    MONTH: "Month",
    WEEK: "Week",
    DAY: "Day",
    AGENDA: "Agenda",
    DATE: "Date",
    TIME: "Time",
    EVENT: "Reminder",
    MORE: "More",
    DOES_NOT_REPEAT: "Doesn't repeat",
    CUSTOM: "Custom",
    DAILY: "Daily",
    WEEKLY_ON: "Weekly on {day}",
    MONTHLY_ON: "Monthly on {date}",
    ANNUALY_ON: "Anually on {month} {date}",
    SURE_DELETE: "Please choose how you want to delete the reminders.",
    DELETE_EVENT: "Delete This Reminder",
    DELETE_ALL: "Delete All Reminders",
    DELETE_FUTURE: "Delete This and Following Reminders",
    CONFIRM_DELETE: "Are you sure do you want to delete this reminder?",
    CONFIRM_UPDATE: "Are you sure do you want to update this reminder?",
    SURE_UPDATE: "Please choose how you want to update the reminders.",
    UPDATE_THIS: "Update This Reminder",
    UPDATE_ALL: "Update All Reminders",
    UPDATE_FUTURE: "Update this and following Reminders",
    EVENTS: "Reminders",
    TODAYS_EVENTS: "Today's Reminders",
    PLEASE: "Please",
    NO_EVENT: "There are no reminders in this range.",
    NO_DAY_EVENT: "There are no reminders on this day.",
    COLOR: "Colour",
    NOTIFY_VIA_TOOLTIP: "Unsubscribed. Can be enabled in profile page.",
    SHARED_BY: "Shared by",
    CREATED_BY: "Created by",
    EMAIL: "Email",
    FREQUENCY: "Frequency",
    AT: "At",
    CREATE_MESSAGE: "Reminder created successfully.",
    UPDATE_MESSAGE: "Reminder updated successfully.",
    DELETE_MESSAGE: "Reminder deleted successfully.",
    FROM_GROUP: "You are receiving this Reminder as you to belong to the same group as the Reminder",
    END_DATE_MESSAGE: "Repeats endlessly",
    SHARE: {
      SEND: "Send",
      PEOPLE: "Shared People",
      EVENT: "Share Your Event",
      NO_USER: "You haven't shared this reminder with anyone",
      REJECT_DESCRIPTION: "Are you sure you want to reject this reminder?",
      NO_INVITES: "There are no pending invites",
      ACCEPT: "Accept",
      REJECT: "Reject",
      NOTE: "Note: For emails that are not registered with us, an invite link will be sent to sign up to Zen Reminder.",
      ACCEPT_MESSAGE: "Invitation accepted.",
      REJECT_MESSAGE: "Invitation rejected.",
      DELETE_MESSAGE: "Removed user from reminder.",
      INVITE_MESSAGE: "Invitation sent successfully."
    },
    SAVE_AS_NEW: {
      CLONE: "Clone",
      BUTTON: "Save as new",
      TITLE: "Save as new Reminder",
      DESCRIPTION: "Please choose how you want to clone this Reminder.",
      WHOLE_REMINDER: "Clone all reminders",
      CURRENT_RECURRENCE: "Clone this reminder",
      FUTURE_RECURRENCE: "Clone this and following reminders",
      NO_REPEAT_DESCRIPTION: "Do you want to save this reminder as a new reminder?"
    },
    CUSTOM_RECURRENCE: {
      TITLE: "Custom recurrence",
      REPEAT: "Repeat",
      REPEAT_ON: "Repeat on",
      REPEAT_EVERY: "Repeat every",
      MONTHLY_ON_DAY: "Monthly on day {day}",
      FIRST: "First",
      SECOND: "Second",
      THIRD: "Third",
      FOURTH: "Fourth",
      FIFTH: "Fifth",
      SUN: "Sunday",
      MON: "Monday",
      TUE: "Tuesday",
      WED: "Wednesday",
      THU: "Thursday",
      FRI: "Friday",
      SAT: "Saturday",
      DONE: "Done",
      INVALID_REPEAT_NUMBER: "Invalid number",
      DAY_REPEAT: "Every {day_count} days",
      WEEK_REPEAT: "Every week on {week_days}"
    }
  },
  GENERAL: {
    ERROR: "Error",
    ERROR_MESSAGE: "Something went wrong. Please try again",
    CLOSE: "Close",
    DELETE: "Delete",
    UPDATE: "Update",
    SUCCESS: "Success",
    CANCEL: "Cancel",
    RESET_PASSWORD: "Reset Password",
    OK: "OK",
    REJECT: "Reject",
    EXIT: "EXIT"
  },
  ERROR: {
    GO_BACK_HOME: "GO BACK HOME"
  },
  EMAIL: {
    UNSUBSCRIBE: {
      SUBTITLE: "We're sorry if we have spammed your inbox.",
      DESCRIPTION: "If you want to resume email notifications, You can enable it anytime on your profile page."
    },
    CONFIRMATION: {
      SUCCESS: {
        SUBTITLE: "You have successfully verified your email address",
        DESCRIPTION: "Your email is ready to receive notifications."
      },
      FAILURE: {
        SUBTITLE: "There was a error while verifying your email address",
        DESCRIPTION: "Please check if the verification link is valid"
      }
    },
    SHARED_REMINDER: {
      ACCEPT: {
        SUBTITLE: "You have accepted the shared reminder invitation",
        DESCRIPTION: "We will be sending you notifications for this reminder."
      },
      REJECT: {
        SUBTITLE: "You have rejected the shared reminder invitation",
        DESCRIPTION: "If you had rejected this invitation by mistake, please request for another invitation"
      },
      FAILURE: {
        SUBTITLE: "There was a error while considering your input",
        DESCRIPTION: "Please check if you have already accepted/rejected this invitation in the Invites page"
      }
    }
  },
  PROFILE: {
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    LANGUAGE: "Language",
    MAX_CHARACTER: "Character limit: 50",
    WRONG_PASSWORD: "The entered current password is wrong",
    CHANGE_PASS: "Change Your Password",
    CHANGE_PASSWORD: "Change Password",
    CURRENT_PASSWORD: "Current Password",
    NEW_PASSWORD: "New Password",
    CONFIRM_NEW_PASSWORD: "Confirm New Password",
    CONFIRM_TITLE: "This will logout all your active sessions. Are you sure you want to continue?",
    OK_LABEL: "Yes! Continue",
    ADDITIONAL_EMAIL_EXISTS: "{email} is already in use",
    CONFIRM_EMAIL_TOOLTIP: "Please verify this email address by clicking on the link sent to this Email id",
    NOTIFICATION: "Notification",
    WARNING: "Warning",
    TIMEZONE: "Time zone",
    ADDITIONAL_EMAIL_1: "Additional Email 1",
    ADDITIONAL_EMAIL_2: "Additional Email 2",
    UPDATE_MESSAGE: "Your profile was updated successfully."
  },
  TIMEZONE_CHANGE: {
    TITLE: "New Timezone detected",
    DESCRIPTION: "We are updating your Timezone to - {new_time_zone}",
  },
  NOTIFICATION: {
    SNOOZED: "Reminder snoozed for an hour.",
    CLEAR_TOOLTIP: "Clear Notification",
    SNOOZE_TOOLTIP: "Snooze for an hour",
    CLEAR_ALL: "Clear all"
  },
  GROUP: {
    NAME: "Name",
    CREATE: "Create Group",
    UPDATE: "Update Group",
    EXIT: "Exit from this group",
    NO_GROUP: "You dont have any groups yet",
    EMAIL_PLACEHOLDER: "Seperate e-mails with enter key",
    EMAIL_NOTE: "Note: Seperate e-mails with enter key",
    USER_EMAILS: "User Emails",
    OWNER: "Owner",
    MEMBERS: "Group Members",
    TOTAL_MEMBERS: "Total Members",
    UPDATE_MESSAGE: "Group updated successfully",
    CREATE_MESSAGE: "Group created successfully",
    DELETE_MESSAGE: "Group deleted successfully",
    SHARE_DELETE: "User has been removed from the group",
    CONFIRM_EXIT: "Are you sure, you want to exit from the group \"{name}\"?",
    CONFIRM_DELETE: "Deleting this group will also delete all the reminders associated with it. Are you sure you want to continue?",
    REMOVE_USER: "Remove User",
    ADD_USER_MESSAGE: "User has been added to the group",
    ADD: "Add",
    NO_USER: "You haven't added any user to this group"
  },
  REDIRECTION: {
    MESSAGE: "You will be redirected to the {dashboard} in {seconds} seconds."
  }
}

export default flatten(messages);
