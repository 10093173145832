import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Zoom,
  TextField,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Avatar,
  Checkbox,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik } from "formik";
import moment from "moment";

const useCheckBoxStyles = makeStyles((theme) => ({
  checked: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    fontSize: "14px",
    color: "#ffffff",
    backgroundColor: "#6146d8",
  },
  unchecked: {
    width: theme.spacing(2.7),
    height: theme.spacing(2.7),
    fontSize: "14px",
    color: "#6146d8",
    backgroundColor: "rgba(206,217,224,.5)",
  },
}));

const CustomRecurrence = (props) => {
  const classes = useCheckBoxStyles();
  const intl = useIntl();

  const REPEAT_TYPES = [
    {
      value: "week",
      text: intl.formatMessage({id: "CALENDAR.WEEK"}),
    },
    {
      value: "month",
      text: intl.formatMessage({id: "CALENDAR.MONTH"}),
    }
  ];

  const weekAndDay = (date) => {
    var days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    prefixes = ['FIRST', 'SECOND', 'THIRD', 'FOURTH', 'FIFTH'];

    return [prefixes[Math.floor(date.getDate() / 7)], days[date.getDay()]];
  }

  const weekdays = () => {
    const selectedDays = !!props.custom_recurrence.repeat_days ? props.custom_recurrence.repeat_days.split(",") : [moment(props.selected_date).format("ddd").toUpperCase()];
    return [
      { text: "S", value: "SUN", checked: selectedDays.some(i => i === "SUN" ) },
      { text: "M", value: "MON", checked: selectedDays.some(i => i === "MON" ) },
      { text: "T", value: "TUE", checked: selectedDays.some(i => i === "TUE" ) },
      { text: "W", value: "WED", checked: selectedDays.some(i => i === "WED" ) },
      { text: "T", value: "THU", checked: selectedDays.some(i => i === "THU" ) },
      { text: "F", value: "FRI", checked: selectedDays.some(i => i === "FRI" ) },
      { text: "S", value: "SAT", checked: selectedDays.some(i => i === "SAT" ) },
    ]
  }

  const monthlyOnOptions = [
    {text: intl.formatMessage({id: "CALENDAR.CUSTOM_RECURRENCE.MONTHLY_ON_DAY"}, {day: moment(props.selected_date).format("D")}), value: "date"},
    {text: intl.formatMessage({id: "CALENDAR.MONTHLY_ON"}, {date: intl.formatMessage({id: `CALENDAR.CUSTOM_RECURRENCE.${weekAndDay(new Date(props.selected_date))[0]}`}) + ' ' + intl.formatMessage({id: `CALENDAR.CUSTOM_RECURRENCE.${weekAndDay(new Date(props.selected_date))[1]}`})}), value: "day"},
  ];

  const Transition = React.forwardRef(function Transition5(props, ref) {
    return <Zoom ref={ref} {...props} />;
  });

  return (
    <Dialog
      open={props.showModal}
      onClose={props.hideModal}
      key={props.formKey}
      scroll={"paper"}
      maxWidth="xs"
      fullWidth
      TransitionComponent={Transition}
      className="calendar-form-dailog home_cal"
      aria-labelledby="form-dialog-custom"
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-custom">
        <FormattedMessage id="CALENDAR.CUSTOM_RECURRENCE.TITLE" />
        {!props.reminderID && (
          <span className="event_action float-right">
            <span onClick={props.hideModal}>
              <i className={`fas fa-times`} />
            </span>
          </span>
        )}
      </DialogTitle>
      <DialogContent dividers={true} className="py-0">
        <Formik
          enableReinitialize
          initialValues={{
            repeat_number: props.custom_recurrence.repeat_number || 1,
            repeat_type: props.custom_recurrence.repeat_type || REPEAT_TYPES[1].value,
            repeat_days: weekdays() || [],
            monthly_on: props.custom_recurrence.monthly_on || monthlyOnOptions[0].value
          }}
          validate={(values) => {
            const errors = {};

            if(values.repeat_type === "day" && !values.repeat_number) {
              errors.repeat_number = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }

            if(values.repeat_type === "day" && (values.repeat_number < 1 || values.repeat_number > 1000)) {
              errors.repeat_number = intl.formatMessage({
                id: "CALENDAR.CUSTOM_RECURRENCE.INVALID_REPEAT_NUMBER",
              });
            }

            return errors;
          }}
          onSubmit={(values) => {
            let selected_days = values.repeat_days.filter(i => i.checked).flatMap(i => i.value)
            values.repeat_days = selected_days.join()
            if(values.repeat_type === "month")
              values.monthly_option = monthlyOnOptions.find(m => m.value === values.monthly_on).text

            props.onSubmitHandler(values);
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form
              noValidate
              autoComplete="off"
              className="kt-form"
              onSubmit={handleSubmit}
            >
              <div className="modal-body">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group mb-0">
                  <FormGroup row>
                    <TextField
                      select
                      margin="normal"
                      label={intl.formatMessage({id: "CALENDAR.CUSTOM_RECURRENCE.REPEAT_EVERY"})}
                      className="kt-width-full pad-l"
                      variant="outlined"
                      name="repeat_type"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={
                        REPEAT_TYPES.length !== 0
                          ? values.repeat_type
                          : ""
                      }
                      helperText={
                        touched.repeat_type && errors.repeat_number
                          ? errors.repeat_type
                          : " "
                      }
                      error={Boolean(
                        touched.repeat_type && errors.repeat_type
                      )}
                      disabled={props.pastEvent || REPEAT_TYPES.length === 0}
                    >
                      {REPEAT_TYPES.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormGroup>
                </div>

                {/* {values.repeat_type === REPEAT_TYPES[0].value && (
                  <div className="form-group my-0 px-3">
                    <FormGroup row className="align-items-center">
                      <TextField
                        type="number"
                        margin="normal"
                        label={intl.formatMessage({id: "CALENDAR.CUSTOM_RECURRENCE.REPEAT_ON"})}
                        name="repeat_on_day"
                        className="calendar-form-row pad-r"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={(e) => setFieldValue('repeat_number', e.target.value)}
                        value={values.repeat_number}
                        helperText={
                          touched.repeat_number && errors.repeat_number
                            ? errors.repeat_number
                            : " "
                        }
                        error={Boolean(
                          touched.repeat_number && errors.repeat_number
                        )}
                        disabled={props.pastEvent || REPEAT_TYPES.length === 0}
                      />
                    </FormGroup>
                  </div>
                )} */}

                {values.repeat_type === REPEAT_TYPES[0].value && (
                  <div className="form-group my-0 px-3">
                    <FormGroup row className="align-items-center">
                      <FormLabel
                        component="legend"
                        className="w-25"
                        disabled={props.pastEvent}
                      >
                        <FormattedMessage id="CALENDAR.REPEAT_ON" />
                      </FormLabel>
                      <FormGroup row>
                        {values.repeat_days.map((option, i) => (
                          <FormControlLabel
                            key={i}
                            className="mx-0"
                            control={
                              <Checkbox
                                className="px-2"
                                icon={
                                  <Avatar className={classes.unchecked}>
                                    {option.text}
                                  </Avatar>
                                }
                                checkedIcon={
                                  <Avatar className={classes.checked}>
                                    {option.text}
                                  </Avatar>
                                }
                                name="repeat_days"
                                checked={option.checked}
                                color="primary"
                                onChange={(e) => {
                                  values.repeat_days[i].checked = !option.checked;
                                  setFieldValue(
                                    "repeat_days",
                                    values.repeat_days
                                  );
                                }}
                                disabled={
                                  props.pastEvent ||
                                  (option.checked &&
                                    values.repeat_days.filter((i) => i.checked)
                                      .length === 1)
                                }
                              />
                            }
                          />
                        ))}
                      </FormGroup>
                    </FormGroup>
                  </div>
                )}

                {values.repeat_type === REPEAT_TYPES[1].value && (
                  <div className="form-group mb-0">
                    <TextField
                      select
                      margin="normal"
                      label={intl.formatMessage({id: "CALENDAR.CUSTOM_RECURRENCE.REPEAT_ON"})}
                      className="kt-width-full"
                      variant="outlined"
                      name="repeat_on_month"
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue('monthly_on', e.target.value)}
                      value={values.monthly_on}
                      helperText={touched.monthly_on && errors.monthly_on ? errors.monthly_on : " "}
                      error={Boolean(
                        touched.monthly_on && errors.monthly_on
                      )}
                      disabled={props.pastEvent || REPEAT_TYPES.length === 0}
                    >
                      {monthlyOnOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>{option.text}</MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}
              </div>

              <div className="modal-footer">
                <div className="kt-login__actions">
                  {!props.reminderID && (
                    <button
                      type="button"
                      disabled={isSubmitting}
                      className="btn btn-pill btn-outline-dark btn-elevate kt-login__btn-danger"
                      onClick={props.hideModal}
                    >
                      <FormattedMessage id="GENERAL.CLOSE" />
                    </button>
                  )}
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="ml-3 btn btn-pill btn-primary btn-elevate kt-login__btn-primary"
                  >
                    <FormattedMessage id="CALENDAR.CUSTOM_RECURRENCE.DONE" />
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CustomRecurrence;
