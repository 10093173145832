const flatten = require('flat');
const messages = {
  TRANSLATOR: {
    SELECT: "Elige tu idioma"
  },
  MENU: {
    ACTIONS: "Comportamiento",
    DASHBOARD: "Tablero",
    MY_PROFILE: "Mi Perfil",
    SIGN_OUT: "Desconectar",
    PRIVACY_POLICY: "Política de privacidad",
    CONTACT: "Contacto",
    NOTIFICATION: "Notificación",
    NOTIFICATIONS: "Notificaciones",
    PENDING_INVITES: "Invitaciones pendientes",
    GROUPS: "Grupos"
  },
  AUTH: {
    GENERAL: {
      SUBMIT_BUTTON: "Enviar",
      FORGOT_BUTTON: "Se te olvidó tu contraseña?"
    },
    LOGIN: {
      TITLE: "Iniciar sesión en Zen Reminder",
    },
    FORGOT: {
      TITLE: "Contraseña olvidada?",
      SUCCESS: "Acabamos de enviarle un correo electrónico con un enlace para restablecer su contraseña!"
    },
    RESET: {
      TITLE: "Restablecer la contraseña",
      NEW_PASSWORD: "Nueva contraseña",
      CONFIRM_PASSWORD: "Confirmar contraseña",
      ERROR: "Hubo un error.",
      SUCCESS: "Su contraseña ha sido restablecida con éxito."
    },
    REGISTER: {
      TITLE: "Regístrese en Zen Reminder",
      SUCCESS: "Su cuenta ha sido registrada exitosamente.",
      VERIFY_EMAIL: "Verifique su correo electrónico para iniciar sesión."
    },
    VALIDATION: {
      INVALID: "{name} No es válido",
      REQUIRED: "{name} es requerido",
      REGISTERED_EMAIL: "Correo electrónico ya registrado. Por favor intente iniciar sesión",
      EMAIL_IN_USE: "Correo electrónico ya está en uso. Por favor regístrese usando un correo electrónico diferente",
      LOGGED_IN: "Conectado",
      LOGGED_OUT: "Desconectado",
      MIN_LENGTH: "{name}} longitud mínima es {{min}",
      NOT_FOUND: "El {name} solicitado no se encuentra",
      INVALID_LOGIN: "El detalle de inicio de sesión es incorrecto",
      VERIFY_EMAIL: "Por favor verifique su dirección de correo electrónico",
      OAUTH_USER: "Nota: ¿Has utilizado google/apple para registrarte con nosotros?",
      INVITED_USER: "Verifique si se ha registrado mediante el enlace de invitación",
      REQUIRED_FIELD: "Campo requerido",
      INVALID_FIELD: "El campo no es valido",
      SAME_ADDITIONAL_EMAIL: "Los correos electrónicos adicionales deben ser diferentes",
      PASSWORDS_MATCH_FAILED: "Contraseña y Confirmar contraseña no coinciden.",
      ACCEPT_TERMS: "Aceptar los terminos",
      TERMS_AND_CONDITIONS_PREFIX: "Estoy de acuerdo con la ",
      TERMS_AND_CONDITIONS: "Términos y condiciones",
      TERMS_AND_CONDITIONS_SUFFIX: " ",
      TERMS_AND_CONDITIONS_NOT_FOUND: "Términos y condiciones no encontrados",
      PASSWORD_REGEX: "La contraseña debe contener al menos 1 mayúscula, 1 minúscula y 1 número",
      PASSWORD_MAX_CHAR: "La contraseña debe tener al menos 8 caracteres",
      PASSWORD_SPACE: "La contraseña no debe contener ningún espacio en blanco",
      INVALID_EMAIL: "Por favor, introduce una dirección de correo electrónico válida",
      OWN_EMAIL: "No puedes usar tu propio correo electrónico",
      EXISTING_EMAILS: "Ya compartido con {emails}"
    },
    EMAIL_CONFIRMATION: {
      SUCESS: "Ha verificado correctamente su dirección de correo electrónico",
      FAILURE: "Enlace de confirmación de correo electrónico no válido o caducado"
    }
  },
  CALENDAR: {
    NOTIFY_VIA: "Notificar a través de",
    DESCRIPTION: "Descripción",
    GROUP: "Grupo",
    TRIGGER_AT: "Activar en",
    REPEAT: "Repetir",
    REPEAT_ON: "Repetir en",
    START_DATE: "Fecha de inicio",
    END_DATE: "Fecha final",
    CATEGORY: "Categoría",
    PROVIDER: "Proveedor",
    CALENDAR: "Calendario",
    CREATE_EVENT: "Crear recordatorio",
    NEW_EVENT: "Añadir recordatorio",
    UPDATE_EVENT: "Actualizar Recordatorio",
    CLICK_HERE: "Haga clic aquí",
    TELEGRAM: " y seleccione 'Iniciar' para comenzar a recibir notificaciones en Telegram",
    START_DATE_RESTRICTION: "No se pueden crear recordatorios en el pasado",
    END_DATE_RESTRICTION: "Por favor, elija una fecha de finalización válida",
    REQUIRED_FIELD: "Campo requerido",
    MAX_CHARACTER: "Límite de caracteres: 140",
    PREVIOUS: "Previo",
    NEXT: "Próximo",
    TODAY: "Hoy",
    MONTH: "Mes",
    WEEK: "Semana",
    DAY: "Día",
    AGENDA: "Agenda",
    DATE: "Fecha",
    TIME: "Hora",
    EVENT: "Recordatorio",
    MORE: "Más",
    DOES_NOT_REPEAT: "No se repite",
    CUSTOM: "Personalizada",
    DAILY: "Diario",
    WEEKLY_ON: "Semanalmente el {day}",
    MONTHLY_ON: "Mensual el {date}",
    ANNUALY_ON: "Anualmente el {month} de {date}",
    SURE_DELETE: "Elija cómo desea eliminar los recordatorios.",
    DELETE_EVENT: "Eliminar este recordatorio",
    DELETE_ALL: "Eliminar todos los recordatorios",
    DELETE_FUTURE: "Eliminar esto y los siguientes recordatorios",
    CONFIRM_DELETE: "¿Estás seguro de que deseas eliminar este recordatorio?",
    CONFIRM_UPDATE: "¿Estás seguro de que deseas actualizar este recordatorio?",
    SURE_UPDATE: "Elija cómo desea actualizar los recordatorios.",
    UPDATE_THIS: "Actualiza este recordatorio",
    UPDATE_ALL: "Actualizar todos los recordatorios",
    UPDATE_FUTURE: "Actualizar este y los siguientes recordatorios",
    EVENTS: "Recordatorios",
    TODAYS_EVENTS: "Recordatorios de hoy",
    PLEASE: "Por favor",
    NO_EVENT: "No hay recordatorios en este rango.",
    NO_DAY_EVENT: "No hay recordatorios en este día.",
    COLOR: "Color",
    NOTIFY_VIA_TOOLTIP: "No suscrito Se puede habilitar en la página de perfil.",
    SHARED_BY: "Compartido por",
    CREATED_BY: "Creado por",
    EMAIL: "Correos electrónico",
    FREQUENCY: "Frecuencia",
    AT: "A",
    CREATE_MESSAGE: "Recordatorio creado con éxito.",
    UPDATE_MESSAGE: "Recordatorio actualizado correctamente.",
    DELETE_MESSAGE: "Recordatorio eliminado correctamente.",
    FROM_GROUP: "Estás recibiendo este recordatorio porque perteneces al mismo grupo que el recordatorio",
    END_DATE_MESSAGE: "Se repite sin cesar",
    SHARE: {
      SEND: "Enviar",
      PEOPLE: "Personas compartidas",
      NO_USER: "No has compartido este recordatorio con nadie",
      REJECT_DESCRIPTION: "¿Estás seguro de que quieres rechazar este recordatorio?",
      NO_INVITES: "No hay invitaciones pendientes",
      ACCEPT: "Aceptar",
      REJECT: "Rechazar",
      NOTE: "Nota: Para los correos electrónicos que no están registrados con nosotros, se enviará un enlace de invitación para registrarse en Zen Reminder.",
      ACCEPT_MESSAGE: "Invitación aceptada.",
      REJECT_MESSAGE: "Invitación rechazada.",
      DELETE_MESSAGE: "Usuario eliminado del recordatorio.",
      INVITE_MESSAGE: "Invitación enviada con éxito."
    },
    SAVE_AS_NEW: {
      CLONE: "Clon",
      BUTTON: "Guardar como nuevo",
      TITLE: "Guardar como nuevo recordatorio",
      DESCRIPTION: "Elija cómo desea clonar este recordatorio",
      WHOLE_REMINDER: "Clonar todos los recordatorios",
      CURRENT_RECURRENCE: "Clonar este recordatorio",
      FUTURE_RECURRENCE: "Clonar este y los siguientes recordatorios",
      NO_REPEAT_DESCRIPTION: "Desea guardar este recordatorio como un recordatorio nuevo?"
    },
    CUSTOM_RECURRENCE: {
      TITLE: "Recurrencia personalizada",
      REPEAT: "Repetir",
      REPEAT_ON: "Repetir en",
      REPEAT_EVERY: "Repite cada",
      MONTHLY_ON_DAY: "Mensualmente el día {day}",
      FIRST: "Primera",
      SECOND: "Segunda",
      THIRD: "Tercera",
      FOURTH: "Cuarta",
      FIFTH: "Quinta",
      SUN: "domingo",
      MON: "lunes",
      TUE: "martes",
      WED: "miércoles",
      THU: "jueves",
      FRI: "viernes",
      SAT: "sábado",
      DONE: "Hecho",
      INVALID_REPEAT_NUMBER: "Número invalido",
      DAY_REPEAT: "Cada {day_count} dias",
      WEEK_REPEAT: "Todas las semanas los {week_days}"
    }
  },
  GENERAL: {
    ERROR: "Error",
    ERROR_MESSAGE: "Algo salió mal. Inténtalo de nuevo",
    CLOSE: "Cerca",
    DELETE: "Eliminar",
    UPDATE: "Actualizar",
    SUCCESS: "Éxito",
    CANCEL: "Cancelar",
    RESET_PASSWORD: "Restablecer La Contraseña",
    OK: "Okay",
  },
  ERROR: {
    GO_BACK_HOME: "Regresar a casa"
  },
  EMAIL: {
    UNSUBSCRIBE: {
      SUBTITLE: "Lo sentimos si hemos enviado spam a su bandeja de entrada.",
      DESCRIPTION: "Si desea reanudar las notificaciones por correo electrónico, puede habilitarlo en cualquier momento en su página de perfil."
    },
    CONFIRMATION: {
      SUCCESS: {
        SUBTITLE: "Ha verificado correctamente su dirección de correo electrónico",
        DESCRIPTION: "Su correo electrónico está listo para recibir notificaciones."
      },
      FAILURE: {
        SUBTITLE: "Se produjo un error al verificar su dirección de correo electrónico",
        DESCRIPTION: "Verifique si el enlace de verificación es válido."
      }
    },
    SHARED_REMINDER: {
      ACCEPT: {
        SUBTITLE: "Ha aceptado la invitación de recordatorio compartida",
        DESCRIPTION: "Le enviaremos notificaciones para este recordatorio."
      },
      REJECT: {
        SUBTITLE: "Rechazaste la invitación de recordatorio compartida",
        DESCRIPTION: "Si rechazó esta invitación por error, solicite otra invitación"
      },
      FAILURE: {
        SUBTITLE: "Hubo un error al considerar su entrada",
        DESCRIPTION: "Verifique si ya ha aceptado / rechazado esta invitación en la página Invitaciones"
      }
    }
  },
  PROFILE: {
    FIRST_NAME: "Nombre De Pila",
    LAST_NAME: "Apellido",
    LANGUAGE: "Idioma",
    MAX_CHARACTER: "Límite de caracteres: 50",
    WRONG_PASSWORD: "La contraseña actual ingresada es incorrecta",
    CHANGE_PASS: "Cambia tu contraseña",
    CHANGE_PASSWORD: "Cambia la contraseña",
    CURRENT_PASSWORD: "Contraseña Actual",
    NEW_PASSWORD: "Nueva Contraseña",
    CONFIRM_NEW_PASSWORD: "Confirmar Nueva Contraseña",
    CONFIRM_TITLE: "Esto cerrará sesión en todas sus sesiones activas. Estás seguro de que quieres continuar?",
    OK_LABEL: "¡Si! Seguir",
    ADDITIONAL_EMAIL_EXISTS: "{email} Esta en uso",
    CONFIRM_EMAIL_TOOLTIP: "Verifique esta dirección de correo electrónico haciendo clic en el enlace enviado a este ID de correo electrónico",
    NOTIFICATION: "Notificación",
    WARNING: "Advertencia",
    TIMEZONE: "Zona Horaria",
    ADDITIONAL_EMAIL_1: "Email adicional 1",
    ADDITIONAL_EMAIL_2: "Email adicional 2",
    UPDATE_MESSAGE: "Tu perfil se actualizó correctamente."
  },
  TIMEZONE_CHANGE: {
    TITLE: "Nueva zona horaria detectada",
    DESCRIPTION: "Estamos actualizando su zona horaria a - {new_time_zone}",
  },
  NOTIFICATION: {
    SNOOZED: "Recordatorio pospuesto durante una hora.",
    CLEAR_TOOLTIP: "Notificación clara",
    SNOOZE_TOOLTIP: "Posponer una hora",
    CLEAR_ALL: "Limpiar todo"
  },
  GROUP: {
    NAME: "Nombre",
    CREATE: "Gruppe erstellen",
    UPDATE: "Grupo de actualización",
    EXIT: "Salir de este grupo",
    NO_GROUP: "Aún no tienes ningún grupo",
    EMAIL_PLACEHOLDER: "Separe los correos electrónicos con la tecla Intro",
    EMAIL_NOTE: "Nota: correos electrónicos separados con la tecla Intro",
    USER_EMAILS: "Correos electrónicos de usuario",
    OWNER: "Propietario",
    MEMBERS: "Miembros del grupo",
    TOTAL_MEMBERS: "Miembros totales",
    UPDATE_MESSAGE: "Grupo actualizado correctamente",
    CREATE_MESSAGE: "Grupo creado correctamente",
    DELETE_MESSAGE: "Grupo eliminado correctamente",
    SHARE_DELETE: "El usuario ha sido eliminado del grupo",
    CONFIRM_EXIT: "¿Estás seguro, quieres salir del grupo \"{name}\"?",
    CONFIRM_DELETE: "Eliminar este grupo también eliminará todos los recordatorios asociados a él. Estás seguro de que quieres continuar?",
    REMOVE_USER: "Eliminar usuario",
    ADD_USER_MESSAGE: "El usuario ha sido agregado al grupo",
    ADD: "Añadir",
    NO_USER: "No has añadido ninguna usuario a este grupo."
  },
  REDIRECTION: {
    MESSAGE: "Serás redirigido al {dashboard} en {seconds} segundos.",
  }
}

export default flatten(messages);
