import React from "react";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";
import UserNotifications from "app/partials/layout/UserNotifications";

export default class Topbar extends React.Component {
  render() {
    return (
      <div className="kt-header__topbar kt-grid__item">
        <UserNotifications />
        <LanguageSelector />

        <UserProfile showHi={true} showBadge={false} />
      </div>
    );
  }
}
