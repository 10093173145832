import React, { useState } from "react";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { TextField, withStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Fade } from "react-reveal";
import TransitionGroup from 'react-transition-group/TransitionGroup';
import SkeletonLoader from "tiny-skeleton-loader-react";
import cogoToast from "cogo-toast";
import { toAbsoluteUrl } from "_metronic";
import { Image } from "react-bootstrap";
import '_metronic/_assets/sass/zen_reminder/group_form.scss';
import { useEffect } from "react";
import { connect } from "react-redux";
import { ConfirmationPopup } from "./ConfirmDialog";
import { toInputLowercase } from "_metronic/utils/utils";

function GroupForm(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [deleting, setdeleting] = useState(false);
  const [groupUsers, setGroupUsers] = useState([]);
  const [emailLoading, setEmailLoading] = useState(false);
  let groupProps = {
    appear: true,
    enter: true,
    exit: true,
  };

  const CustomTooltip = withStyles(() => ({
    tooltip: {
      textAlign: 'center',
      fontSize: 11,
    },
  }))(Tooltip);

  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "1.0rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "1.0rem" });
  };

  const updateGroup = (values, setStatus, setSubmitting) => {
    axios.patch(`/groups/${props.selectedGroup.attributes.id}`, {group: { name: values.name, group_emails: groupUsers }}).then(async(res)=>{
      props.updateGroupList(res.data.data);
      props.hideModal();
      cogoToast.success(intl.formatMessage({ id: "GROUP.UPDATE_MESSAGE" }), {
        position: 'top-right',
        heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
        bar: {size: '10px'}
      });
      disableLoading();
      setSubmitting(false);
      setGroupUsers([]);
    }).catch((error)=>{
      setStatus(
        intl.formatMessage({
          id: error.response?.data?.message !== undefined ? error.response?.data?.message : "GENERAL.ERROR_MESSAGE"
        })
      );
      disableLoading();
      setSubmitting(false);
    });
  }

  const createGroup = (values, setStatus, setSubmitting) => {
    values.user_id = props.user.id

    axios.post(`/groups`, {group: { name: values.name, group_emails: groupUsers, user_id: values.user_id }}).then(async(res)=>{
      await props.addGroupData(res.data.data);
      props.hideModal();
      cogoToast.success(intl.formatMessage({ id: "GROUP.CREATE_MESSAGE" }), {
        position: 'top-right',
        heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
        bar: {size: '10px'}
      });
      disableLoading();
      setSubmitting(false);
      setGroupUsers([]);
    }).catch((error)=>{
      setStatus(
        intl.formatMessage({
          id: error.response?.data?.message !== undefined ? error.response?.data?.message : "GENERAL.ERROR_MESSAGE"
        })
      );
      disableLoading();
      setSubmitting(false);
    });
  }

  const deletePopup = (setStatus) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "PROFILE.WARNING",
      }),
      description: intl.formatMessage(
        { id: "GROUP.CONFIRM_DELETE" }
      ),
      okLabel: intl.formatMessage({
        id: "GENERAL.DELETE",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      maxWidth: "sm",
      okAction: () => {
        deleteGroup(setStatus);
      }
    });
  }

  const deleteGroup = (setStatus) => {
    setdeleting(true);
    axios.delete(`/groups/${props.selectedGroup.attributes.id}`).then(async(res)=>{
      await props.deleteGroup();
      cogoToast.success(intl.formatMessage({ id: "GROUP.DELETE_MESSAGE" }), {
        position: 'top-right',
        heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
        bar: {size: '10px'}
      });
      disableLoading();
      setdeleting(false);
      props.hideModal();
    }).catch((error)=>{
      setStatus(
        intl.formatMessage({
          id: error.response?.data?.message !== undefined ? error.response?.data?.message : "GENERAL.ERROR_MESSAGE"
        })
      );
      disableLoading();
      setdeleting(false);
    });
  }

  const deleteEmail = (index, email) =>{
    if(props.selectedGroup) {
      axios.delete(`/groups/${props.selectedGroup.attributes.id}/remove_user_from_group`, { data: { group_user_email: email } }).then((res)=>{
        var newValues = [...groupUsers]
        newValues.splice(index, 1);
        setGroupUsers(newValues);
        props.changeMemberCount()
        cogoToast.success(intl.formatMessage({ id: "GROUP.SHARE_DELETE" }), {
          heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
          bar: {size: '10px'},
          position: 'top-right'
        });
      }).catch((error)=>{
        cogoToast.error(intl.formatMessage({ id: error.response?.data?.message !== undefined ? error.response?.data?.message : "GENERAL.ERROR_MESSAGE" }), {
          heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
          bar: {size: '10px'},
          position: 'top-right'
        });
      });
    } else {
      var newValues = [...groupUsers]
      newValues.splice(index, 1);
      setGroupUsers(newValues);
    }
  }

  const addEmail = (email) => {
    if(props.selectedGroup) {
      axios.post(`/groups/${props.selectedGroup.attributes.id}/add_user_to_group`, {group_user_email: [email]}).then(res => {
        cogoToast.success(intl.formatMessage({ id: "GROUP.ADD_USER_MESSAGE" }), {
          heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
          bar: {size: '10px'},
          position: 'top-right'
        });
        setGroupUsers([...groupUsers, email])
      }).catch(error => {
        cogoToast.error(intl.formatMessage({ id: error.response?.data?.message !== undefined ? error.response?.data?.message : "GENERAL.ERROR_MESSAGE" }), {
          heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
          bar: {size: '10px'},
          position: 'top-right'
        });
      })
    } else {
      setGroupUsers([...groupUsers, email])
    }
  }

  useEffect(() => {
    if(props.selectedGroup?.attributes?.id){
      setEmailLoading(true);
      axios.get(`/groups/${props.selectedGroup.attributes.id}/group_users_list`).then((res)=>{
        setGroupUsers(res.data.group_users);
        setEmailLoading(false);
      }).catch((error)=>{
        cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
          heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
          bar: {size: '10px'},
          position: 'top-right'
        });
      });
    }
    else {
      setGroupUsers([])
    }
  }, [intl, props.selectedGroup])

  const loader = Array.apply(null, { length: 4 }).map((e, i) => (
    <SkeletonLoader className="skeleton-loader" key={'loader'+i} width="100%" height="40px"/>
  ));

  const EmailForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          group_email: ""
        }}
        validate={(values) => {
          const errors = {};

          let user_emails = [props.user.email, ...(props.user.additional_emails.map((val) => val.email))]

          if (!values.group_email) {
            errors.group_email = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            });
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.group_email)) {
            errors.group_email = intl.formatMessage(
              { id: "AUTH.VALIDATION.INVALID_EMAIL" },
            );
          } else if (user_emails.includes(values.group_email)) {
            errors.group_email = intl.formatMessage(
              { id: "AUTH.VALIDATION.OWN_EMAIL" },
            );
          } else if (groupUsers.includes(values.group_email)) {
            errors.group_email = intl.formatMessage(
              { id: "AUTH.VALIDATION.EXISTING_EMAILS" },
              { emails: values.group_email }
            );
          }
          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          enableLoading();
          setSubmitting(true);

          addEmail(values.group_email)

          setSubmitting(false);
          disableLoading();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <div
            noValidate={true}
            autoComplete="off"
            className="kt-form group-form"
            onSubmit={handleSubmit}
          >
            <div className="d-flex">
              <div className="form-group mb-0 flex-fill mr-3 text-field-container">
                <TextField
                  type="text"
                  variant="outlined"
                  label={intl.formatMessage({id: "CALENDAR.EMAIL"})}
                  name="group_email"
                  onChange={handleChange}
                  onInput={toInputLowercase}
                  value={values.group_email}
                  helperText={touched.group_email && errors.group_email ? errors.group_email : " "}
                  error={Boolean(touched.group_email && errors.group_email)}
                />
              </div>
              <div className="kt-login__actions ">
                <button
                  type="button"
                  disabled={isSubmitting}
                  className={`btn btn-primary btn-pill btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                    }
                  )}`}
                  style={{padding: "1.21rem 2rem"}}
                  onClick={handleSubmit}
                >
                  {!loading ? <FormattedMessage id="GROUP.ADD" /> : " " }
                </button>
              </div>
            </div>
          </div>
        )}
      </Formik>
    )
  }

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.hideModal}
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <FormattedMessage id={props.selectedGroup ?  "GROUP.UPDATE" : "GROUP.CREATE"}/>
          </Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={{
            name: props.selectedGroup?.attributes?.name || "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              });
            }

            if (values.name && values.name.length > 50) {
              errors.name = intl.formatMessage({
                id: "PROFILE.MAX_CHARACTER",
              });
            }
            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            enableLoading();
            setSubmitting(true);
            if(props.selectedGroup?.attributes?.id){
              updateGroup(values, setStatus, setSubmitting);
            }else{
              createGroup(values, setStatus, setSubmitting);
            }
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setStatus
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              className="kt-form group-form"
              onSubmit={handleSubmit}
            >
              <div className="modal-body">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group mb-0 mt-4">
                  <TextField
                    type="text"
                    label={intl.formatMessage({id: "GROUP.NAME"})}
                    margin="normal"
                    className="kt-width-full mt-0"
                    name="name"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    helperText={touched.name && errors.name ? errors.name : " "}
                    error={Boolean(touched.name && errors.name)}
                  />
                </div>
                <div className="form-group mb-3">
                  <EmailForm />

                  <div className="note-text text-dark">
                    <FormattedMessage id="CALENDAR.SHARE.NOTE" />
                  </div>
                </div>
                <div className="home_cal text-dark">
                  <h5><FormattedMessage id="GROUP.MEMBERS" /></h5>
                  <div className="group-email-list">
                    {emailLoading ? (loader) : (groupUsers.length === 0 ? (<div><FormattedMessage id="GROUP.NO_USER" /></div>) :
                    (<div>
                      <TransitionGroup {...groupProps}>
                        {groupUsers.map((email, index) => (
                          <Fade key={email} collapse bottom>
                            <div className="d-flex my-2 align-items-center" id="group_user">
                              <Image
                                width={30}
                                height={30}
                                alt="220x20"
                                src={toAbsoluteUrl("/media/misc/default-image.png")}
                                roundedCircle
                              />
                              <div
                                className="mx-2 flex-fill"
                              >
                                {email}
                              </div>
                              <CustomTooltip title={intl.formatMessage({id: "GROUP.REMOVE_USER"})} arrow>
                                <div className="event_action">
                                  <span
                                    className="kt-header__topbar-icon kt-pulse"
                                    onClick={() => deleteEmail(index, email)}
                                  >
                                    <i className="fas fa-times" />
                                  </span>
                                </div>
                              </CustomTooltip>
                            </div>
                          </Fade>
                        ))}
                      </TransitionGroup>
                    </div>)
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="kt-login__actions">
                  <button type="button" disabled={isSubmitting || deleting} className="btn btn-pill btn-outline-dark btn-elevate kt-login__btn-danger" onClick={props.hideModal}><FormattedMessage id="GENERAL.CLOSE" /></button>
                  {props.selectedGroup  &&
                    <button
                      type="button"
                      disabled={isSubmitting || deleting}
                      className="btn btn-pill btn-danger btn-elevate kt-login__btn-danger ml-3"
                      onClick={() => deletePopup(setStatus)}
                    >
                      <FormattedMessage id="GENERAL.DELETE" />
                    </button>
                  }
                  <button
                    type="submit"
                    disabled={isSubmitting|| deleting}
                    className={`ml-3 btn btn-pill btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    <FormattedMessage id={props.selectedGroup ? "GENERAL.UPDATE" : "AUTH.GENERAL.SUBMIT_BUTTON" }/>
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps, null)(GroupForm);
