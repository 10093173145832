import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";
import clsx from "clsx";
import * as auth from "app/store/ducks/auth.duck";
import cogoToast from "cogo-toast";
import { connect } from "react-redux";
import { Image } from "react-bootstrap";
import { metronic } from "_metronic";
import { TextField, MenuItem, Checkbox, FormLabel, FormGroup, FormControlLabel, InputAdornment, Tooltip, withStyles } from "@material-ui/core";
import PasswordReset from "app/widgets/PasswordReset"
import { logout, requestPassword } from "app/crud/auth.crud";
import '_metronic/_assets/sass/zen_reminder/profile_form.scss';
import { languages } from "app/pages/auth/Registration"
import { toAbsoluteUrl, emailValidator } from "_metronic/utils/utils";
import { ConfirmationPopup } from "./ConfirmDialog";
import SkeletonLoader from "tiny-skeleton-loader-react";
import Fade from "react-reveal";
import { toInputLowercase } from "_metronic/utils/utils";

export function ProfileForm(props) {
  const { requestUser } = props;
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();
  const [telegramLink, setTelegramLink] = useState("")
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "1.0rem"
  });
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "1.0rem" });
  };

  const hideModal = () => setShowModal(false)
  const modalShow = () => {
    if(props.user.registered_via !== 'email') {
      ConfirmationPopup({
        title: intl.formatMessage({
          id: "PROFILE.WARNING",
        }),
        description: intl.formatMessage({
          id: "PROFILE.CONFIRM_TITLE",
        }),
        okLabel: intl.formatMessage({
          id: "PROFILE.OK_LABEL",
        }),
        cancelLabel: intl.formatMessage({
          id: "GENERAL.CANCEL",
        }),
        okAction: () => {
          requestPassword(props.user.email)
          .then((res) => {
            passwordChange()
            cogoToast.success(intl.formatMessage({ id: "AUTH.FORGOT.SUCCESS" }), {
              heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
              bar: {size: '10px'},
              position: 'top-right'
            });
          })
          .catch((error) => {
            cogoToast.success(intl.formatMessage({ id: error.response.data.message }), {
              heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
              bar: {size: '10px'},
              position: 'top-right'
            });
          });
        },
        closeAction: () => {
          disableLoading();
        }
      });
    } else {
      setShowModal(true)
    }
  }

  const formatAdditionalEmailValues = (formatted_values) => {
    if (props_additional_email_1) {
      formatted_values.additional_emails_attributes.email_1['id'] = props_additional_email_1.id
      if (formatted_values.additional_emails_attributes.email_1.email === "")
        formatted_values.additional_emails_attributes.email_1['_destroy'] = true

    } else {
      if (formatted_values.additional_emails_attributes.email_1.email === "")
        delete formatted_values.additional_emails_attributes.email_1
    }


    if (props_additional_email_2) {
      formatted_values.additional_emails_attributes.email_2['id'] = props_additional_email_2.id
      if (formatted_values.additional_emails_attributes.email_2.email === "")
        formatted_values.additional_emails_attributes.email_2['_destroy'] = true
    } else {
      if (formatted_values.additional_emails_attributes.email_2.email === "")
        delete formatted_values.additional_emails_attributes.email_2
    }
    formatted_values.additional_emails_attributes = Object.values(formatted_values.additional_emails_attributes)
  }

  const passwordChange = async() => {
    await logout(true);
    props.logout();
    cogoToast.success(intl.formatMessage({ id: "AUTH.RESET.SUCCESS" }), {
      heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
      bar: {size: '10px'},
      position: 'top-right'
    });
    hideModal()
  }

  const CustomTooltip = withStyles(() => ({
    tooltip: {
      textAlign: 'center',
      fontSize: 11,
    },
  }))(Tooltip);

  const props_additional_email_1 = Object.values(props.user.additional_emails)[0]
  const props_additional_email_2 = Object.values(props.user.additional_emails)[1]

  useEffect(() => {
    var browser_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (props.user.time_zone !== browser_timezone) {
      axios.patch(`/users/${props.user.id}`, { user: { time_zone: browser_timezone }}).then((res)=>{
        requestUser();
        ConfirmationPopup({
          title: intl.formatMessage({ id: "TIMEZONE_CHANGE.TITLE" }),
          description: intl.formatMessage({ id: "TIMEZONE_CHANGE.DESCRIPTION" }, { new_time_zone: browser_timezone }),
          okLabel: intl.formatMessage({ id: "GENERAL.OK" }),
        });
      }).catch((_)=>{
        cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
          heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
          bar: {size: '10px'},
          position: 'top-right'
        });
      });

    }
    if(!props.user.telegram_chat_id) {
      axios.get("users/create_telegram_subscription_url").then((res) => {
        setTelegramLink(res.data.telegram_subscription_url)
      }).catch((_)=>{
        cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
          heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
          bar: {size: '10px'},
          position: 'top-right'
        });
      });
    }
    setDataLoading(false)
  },[intl, requestUser, props.user.time_zone, props.user.id, props.user.telegram_chat_id])

  const loader = (
    <>
      <SkeletonLoader circle height="120px" width="30%" style={{alignSelf: "center"}}/>
      <SkeletonLoader width="100%" height="30px"/>
      <SkeletonLoader width="100%" height="30px"/>
      <SkeletonLoader width="100%" height="50px"/>
      <SkeletonLoader width="100%" height="50px"/>
      <SkeletonLoader width="100%" height="50px"/>
      <SkeletonLoader width="100%" height="50px"/>
      <SkeletonLoader width="100%" height="50px"/>
    </>
  )

  return dataLoading ? loader : (
    <>
      <Fade right cascade>
        <div className="form-group text-center img-margin">
          <Image
            width={120}
            height={120}
            alt="120x120"
            src={
              props.user.avatar
                ? props.user.avatar
                : toAbsoluteUrl("/media/misc/default-image.png")
            }
            roundedCircle
          />
          <h5 className="text-body mt-2">{props.user.full_name}</h5>
          <p className="text-muted mt-3 mb-0">({props.user.email})</p>
        </div>
      </Fade>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: props.user.first_name || "",
          last_name: props.user.last_name || "",
          locale: props.user.locale || "",
          additional_emails_attributes : {
            email_1: {
              email: props_additional_email_1?.email || "",
              notify_via_email: props_additional_email_1?.notify_via_email || false
            },
            email_2: {
              email: props_additional_email_2?.email || "",
              notify_via_email: props_additional_email_2?.notify_via_email || false
            }
          },
          time_zone: props.user.time_zone || "",
          notification_config_attributes: {
            notify_via_email: props.user.notify_via_email || false,
            notify_via_telegram: props.user.notify_via_telegram || false
          }
        }}
        validate={(values) => {
          const errors = {};
          values.additional_emails_attributes.email_1.email = values.additional_emails_attributes.email_1.email.trim()
          values.additional_emails_attributes.email_2.email = values.additional_emails_attributes.email_2.email.trim()

          if (!values.first_name || !values.first_name.trim()) {
            errors.first_name = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            });
          }

          if(values.first_name && values.first_name.length > 50) {
            errors.first_name = intl.formatMessage({
              id: "PROFILE.MAX_CHARACTER"
            });
          }

          if (!values.last_name || !values.last_name.trim()) {
            errors.last_name = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            });
          }

          if(values.last_name && values.last_name.length > 50) {
            errors.last_name = intl.formatMessage({
              id: "PROFILE.MAX_CHARACTER"
            });
          }

          if (!emailValidator(values.additional_emails_attributes.email_1.email)) {
            errors.email_1 = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          }
          else if (
            !!values.additional_emails_attributes.email_1.email &&
            !!values.additional_emails_attributes.email_2.email
          )
          {
            if(values.additional_emails_attributes.email_1.email === values.additional_emails_attributes.email_2.email)
            {
              errors.email_1 = intl.formatMessage({
                id: "AUTH.VALIDATION.SAME_ADDITIONAL_EMAIL"
              });
              errors.email_2 = intl.formatMessage({
                id: "AUTH.VALIDATION.SAME_ADDITIONAL_EMAIL"
              });
            }
          }

          if (!emailValidator(values.additional_emails_attributes.email_2.email)) {
            errors.email_2 = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          }

          if (!values.locale) {
            errors.locale = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            });
          }

          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          enableLoading();
          setSubmitting(true);
          let formatted_values = JSON.parse(JSON.stringify(values))
          formatAdditionalEmailValues(formatted_values)
          axios.patch(`/users/${props.user.id}`, {user: formatted_values}).then(async(res)=>{
            props.requestUser();
            props.setLanguage(values.locale);
            cogoToast.success(intl.formatMessage({ id: "PROFILE.UPDATE_MESSAGE" }), {
              heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
              bar: {size: '10px'},
              position: 'top-right'
            });
            disableLoading()
          }).catch((error)=>{
            setStatus(
              intl.formatMessage(
                { id: error.response?.data?.message !== undefined ? error.response?.data?.message : "GENERAL.ERROR_MESSAGE"},
                { email: error.response?.data?.email }
              )
            );
            disableLoading()
          });
          setSubmitting(false);
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form
            noValidate={true}
            autoComplete="off"
            className="kt-form"
            onSubmit={handleSubmit}
          >
            <Fade right cascade>
              <div>
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}
                <div className="form-group mb-0">
                  <TextField
                    type="text"
                    label={intl.formatMessage({id: "PROFILE.FIRST_NAME"})}
                    margin="normal"
                    variant="outlined"
                    className="kt-width-full mt-0"
                    name="first_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                    helperText={touched.first_name && errors.first_name ? errors.first_name : " "}
                    error={Boolean(touched.first_name && errors.first_name)}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label={intl.formatMessage({id: "PROFILE.LAST_NAME"})}
                    className="kt-width-full"
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.last_name}
                    helperText={touched.last_name && errors.last_name ? errors.last_name : " "}
                    error={Boolean(touched.last_name && errors.last_name)}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    select
                    margin="normal"
                    label={intl.formatMessage({id: "PROFILE.LANGUAGE"})}
                    className="kt-width-full"
                    name="locale"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.locale}
                    helperText={touched.locale && errors.locale ? errors.locale : " "}
                    error={Boolean(touched.locale && errors.locale)}
                  >

                  {languages.map((option, index) => (
                      <MenuItem key={index} value={option.lang}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="form-group mb-0">
                  <TextField
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label={intl.formatMessage({id: "PROFILE.ADDITIONAL_EMAIL_1"})}
                    className="kt-width-full"
                    name="additional_emails_attributes.email_1.email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.additional_emails_attributes.email_1.email}
                    onInput={toInputLowercase}
                    helperText={errors.email_1 ? errors.email_1 : " "}
                    error={Boolean(errors.email_1)}
                    InputProps={{
                      endAdornment:
                      <InputAdornment
                      hidden={!props_additional_email_1 || !!props_additional_email_1?.email_confirmed}>
                        <CustomTooltip
                          title={intl.formatMessage({id: "PROFILE.CONFIRM_EMAIL_TOOLTIP"})}
                          arrow
                        >
                          <i className="flaticon-warning email-warning-icon kt-font-warning" />
                        </CustomTooltip>
                      </InputAdornment>,
                    }}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label={intl.formatMessage({id: "PROFILE.ADDITIONAL_EMAIL_2"})}
                    className="kt-width-full"
                    name="additional_emails_attributes.email_2.email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.additional_emails_attributes.email_2.email}
                    onInput={toInputLowercase}
                    helperText={errors.email_2 ? errors.email_2 : " "}
                    error={Boolean(errors.email_2)}
                    InputProps={{
                      endAdornment:
                      <InputAdornment
                        hidden={!props_additional_email_2 || !!props_additional_email_2?.email_confirmed }>
                        <CustomTooltip
                          title={intl.formatMessage({id: "PROFILE.CONFIRM_EMAIL_TOOLTIP"})}
                          arrow
                        >
                          <i className="flaticon-warning email-warning-icon kt-font-warning" />
                        </CustomTooltip>
                      </InputAdornment>,
                    }}
                  />
                </div>
                <div className="form-group mb-0">
                  <TextField
                    margin="normal"
                    label={intl.formatMessage({id: "PROFILE.TIMEZONE"})}
                    className="kt-width-full"
                    name="time_zone"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.time_zone}
                    helperText={touched.time_zone && errors.time_zone ? errors.time_zone : " "}
                    error={Boolean(touched.time_zone && errors.time_zone)}
                    disabled={true}
                  />
                </div>
                <div className="form-group mb-0 px-3 mt-2">
                  <FormLabel component="legend">
                    <FormattedMessage id="CALENDAR.NOTIFY_VIA" />
                  </FormLabel>
                  <FormGroup className={!!props_additional_email_1 ? "" : "MuiFormGroup-row"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="notification_config_attributes.notify_via_email"
                          checked={values.notification_config_attributes.notify_via_email}
                          color="primary"
                          onChange={handleChange}
                        />
                      }
                      label="Email"
                      disabled={props.pastEvent}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="notification_config_attributes.notify_via_telegram"
                          checked={values.notification_config_attributes.notify_via_telegram}
                          color="primary"
                          onChange={handleChange}
                        />
                      }
                      label="Telegram"
                      disabled={props.pastEvent}
                    />
                  </FormGroup>
                  {(!!props_additional_email_1 || !!props_additional_email_2) &&
                    <FormGroup className="additional-emails-chk-box">
                      {!!props_additional_email_1 &&
                        <FormControlLabel
                          className="w-100"
                          control={
                            <Checkbox
                              name="additional_emails_attributes.email_1.notify_via_email"
                              checked={values.additional_emails_attributes.email_1.notify_via_email}
                              color="primary"
                              onChange={handleChange}
                            />
                          }
                          label={props_additional_email_1.email}
                        />
                      }
                      {!!props_additional_email_2 &&
                        <FormControlLabel
                          className="w-100"
                          control={
                            <Checkbox
                              name="additional_emails_attributes.email_2.notify_via_email"
                              checked={values.additional_emails_attributes.email_2.notify_via_email}
                              color="primary"
                              onChange={handleChange}
                            />
                          }
                          label={props_additional_email_2.email}
                        />
                      }
                    </FormGroup>
                  }
                  {!props.user.telegram_chat_id && values.notification_config_attributes.notify_via_telegram ? (
                    <div className="form-group">
                      <span className="font-weight-normal">
                        <FormattedMessage id="CALENDAR.PLEASE" />{' '}
                        <a
                          className="font-weight-bold"
                          href={telegramLink}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <FormattedMessage id="CALENDAR.CLICK_HERE" />
                        </a><FormattedMessage id="CALENDAR.TELEGRAM" />
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <div className="float-right kt-login__actions d-flex">
                  <button type="button" disabled={isSubmitting} className="btn btn-pill btn-outline-danger btn-elevate kt-login__btn-danger" onClick={modalShow}><FormattedMessage id="GENERAL.RESET_PASSWORD" /></button>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`ml-3 btn btn-primary btn-pill btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                  </button>
                </div>
              </div>
            </Fade>
          </form>
        )}
      </Formik>
      <PasswordReset
        showModal={showModal}
        hideModal={hideModal}
        userId={props.user.id}
        passwordChange={passwordChange}
      />
    </>
  );
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

const mapDispatchToProps = {
  ...auth.actions, // will be wrapped into a dispatch call
  ...metronic.i18n.actions, // will be wrapped into a dispatch call
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
