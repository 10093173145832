import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import moment from 'moment';
import { colors } from "_metronic/utils/utils"
import { Badge, Tooltip, withStyles } from "@material-ui/core";
import { ReactComponent as CalendarBell } from "_metronic/layout/assets/layout-svg-icons/CalendarBell.svg";
import { reminderDisplayKey } from "_metronic/utils/utils";
import '_metronic/_assets/sass/zen_reminder/event_list.scss';

const CustomTooltip = withStyles(() => ({
  tooltip: {
    textAlign: 'center',
    fontSize: 11,
  },
}))(Tooltip);

class EventList extends React.Component {
  render() {
    let reminders;
    if (this.props.loading) {
      reminders = this.props.loader
    } else
    if (this.props.filteredEvents.length > 0) {
      reminders = this.props.filteredEvents.map((reminder) => (
        <div className="event_item" key={reminder.id} style={{borderLeftColor: colors[reminder.color]?.code}}>
          <div className="d-flex">

            <div className={`d-flex flex-shrink-0 flex-column align-items-center mt-2 kt-font-${colors[reminder.color]?.iconColor}`}>
              <CalendarBell className={`kt-svg-icon kt-svg-icon--${colors[reminder.color]?.iconColor} kt-opacity-5`} />
              <div className="my-2 font-weight-bold kt-font-sm">{moment(reminder.start).format('hh:mm A')}</div>
            </div>
            <div className="d-flex flex-column flex-fill">
              <div className="d-flex">
                <div className="flex-grow-1 desc_row">
                  <div className="event_desc wrap-overflow">{reminder.title}</div>
                  <div className="sub_desc wrap-overflow">{reminder.group_name}</div>
                </div>
                {(!reminder.is_shared_reminder && !reminder.is_group_reminder && !this.props.isPastEvent(reminder.start)) &&
                  <div className="event_action">
                    <span
                      className="kt-header__topbar-icon kt-pulse"
                      onClick={() => this.props.openPopup(reminder)}
                    >
                      <Badge badgeContent={reminder.shares_count} color="secondary">
                        <i className="fas fa-user-plus" />
                      </Badge>
                    </span>
                  </div>
                }
                {(reminder.is_group_reminder) &&
                  <div className="event_action group-icon">
                    <CustomTooltip
                      title={<FormattedMessage id="CALENDAR.FROM_GROUP" />}
                      arrow
                    >
                      <span
                        className="kt-header__topbar-icon kt-pulse"
                        onClick={() => null }
                      >
                        <Badge color="secondary">
                          <i className="fas fa-users"/>
                        </Badge>
                      </span>
                    </CustomTooltip>
                  </div>
                }
                <div className="event_action">
                  <span
                    className="kt-header__topbar-icon kt-pulse"
                    onClick={() => this.props.editEvent(reminder)}
                  >
                    <i className="flaticon2-edit" />
                  </span>
                </div>
              </div>
              {!!reminderDisplayKey(reminder.is_group_reminder, reminder.is_shared_reminder) && <div className="d-flex flex-column flex-fill desc_row">
                <div className="my-2 kt-divider">
                  <span />
                </div>
                <div className="kt-font-sm">
                  <FormattedMessage
                    id={`${reminderDisplayKey(reminder.is_group_reminder, reminder.is_shared_reminder)}`}
                  />
                  <span className="kt-font-dark font-weight-normal">{`: ${reminder.user_full_name}`}</span>
                </div>
              </div>}
            </div>
          </div>
        </div>
      ))
    } else {
      reminders = <span className='text-dark'><FormattedMessage id={ "CALENDAR.NO_DAY_EVENT" }/></span>
    }

    return(
      <div className="event_container col-md-3">
        <div className="event_list">
          <div className="event_head_bg">
            <div className='event_header'>
              <span className="event_date">
                <span className="event_month">{this.props.month}</span>
                <span className="event_dt">{this.props.selectedDate}</span>
              </span>
              <span className="event_heading">{this.props.eventTitle}</span>
            </div>
          </div>
          <div className="event_body">
            { reminders }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (value) => ({ lang: value?.i18n?.lang });

export default injectIntl(connect(mapStateToProps, null)(EventList));
