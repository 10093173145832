/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import firebase_db from "firebase_db";
import { Badge, withStyles, Tooltip, Card } from "@material-ui/core";
import { colors } from "_metronic/utils/utils";
import "_metronic/_assets/sass/global/layout/user_notification.scss";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import axios from "axios";
import cogoToast from "cogo-toast";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export const UserNotifications = (props) => {
  const intl = useIntl();
  const { user } = props;
  const [pulse, setPulse] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const CustomTooltip = withStyles(() => ({
    tooltip: {
      fontSize: 11,
    },
  }))(Tooltip);

  const getIconClassName = (reminder_type) => {
    switch (reminder_type) {
      case "Bills":
        return `flaticon-price-tag kt-font-light`;
      case "Todo":
        return `flaticon2-check-mark kt-font-light`;
      case "Events":
        return `flaticon-event-calendar-symbol kt-font-light`;
      default:
        return `flaticon-calendar-with-a-clock-time-tools kt-font-light`;
    }
  };
  useEffect(() => {
    firebase_db
      .collection("reminder")
      .doc(user.id)
      .onSnapshot((doc) => {
        setPulse(false);
        if (doc.data() && !!doc.data().reminders) {
          setNotifications(doc.data().reminders);
          setPulse(doc.data().reminders.some((r) => r.status));
        }
      });
  }, [user.id, notifications.length]);

  const notificationRead = () => {
    if (pulse) {
      axios
        .post(`/users/${user.id}/update_firestore`, {
          read_notification: true,
        })
        .then((res) => {
          // setPulse(false);
        })
        .catch(() => {
          cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
            heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
            bar: { size: "10px" },
            position: "top-right",
          });
        });
    }
  };

  const clearOne = async (reminder) => {
    const notificationIndex = notifications.findIndex((r) => r === reminder);
    if (notificationIndex !== -1) {
      notifications.splice(notificationIndex, 1);
      await axios.post(`/users/${user.id}/update_firestore`, {
        clear_one: true,
        index: notificationIndex,
      });
    }
  };

  const clearAll = async () => {
    await axios.post(`/users/${user.id}/update_firestore`, {
      clear_all: true,
      reminders: [],
    });
  };

  const snoozeReminder = async (reminder) => {
    await axios.get(`/reminders/${reminder.id}/snooze_reminder`);
    clearOne(reminder);
    cogoToast.success(intl.formatMessage({ id: "NOTIFICATION.SNOOZED" }), {
      heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
      bar: { size: "10px" },
      position: "top-right",
    });
  };

  const reminderClickAction = (notification, action) => {
    axios
      .patch(`reminders/${notification.id}/update_shared_reminder`, {
        status: action,
      })
      .then((res) => {
        clearOne(notification);
        cogoToast.success(
          intl.formatMessage({
            id:
              action === "accepted"
                ? "CALENDAR.SHARE.ACCEPT_MESSAGE"
                : "CALENDAR.SHARE.REJECT_MESSAGE",
          }),
          {
            heading: intl.formatMessage({ id: "GENERAL.SUCCESS" }),
            bar: { size: "10px" },
            position: "top-right",
          }
        );
      })
      .catch((_) => {
        cogoToast.error(intl.formatMessage({ id: "GENERAL.ERROR_MESSAGE" }), {
          heading: intl.formatMessage({ id: "GENERAL.ERROR" }),
          bar: { size: "10px" },
          position: "top-right",
        });
      });
  };

  return (
    <Dropdown
      className="kt-header__topbar-item"
      drop="down"
      alignRight
      hidden={!notifications.length && !!props.displayWithText}
    >
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-notifications"
      >
        <span
          className="kt-header__topbar-icon kt-pulse"
          onClick={notificationRead}
        >
          {pulse ? (
            <div className="user-notification-mob">
              <div className="notification-icon-mob">
                <Badge
                  badgeContent={notifications.filter((n) => n.status).length}
                  color="error"
                >
                  <i className="flaticon2-notification header-mobile-icon" />
                </Badge>
              </div>
              {props.displayWithText && (
                <span className="notification-text-mob">
                  {notifications.length > 1
                    ? intl.formatMessage({ id: "MENU.NOTIFICATIONS" })
                    : intl.formatMessage({ id: "MENU.NOTIFICATION" })}
                </span>
              )}
            </div>
          ) : (
            <div className="user-notification-mob">
              <i className="flaticon2-notification notification-icon-mob" />
              {props.displayWithText && (
                <span className="notification-text-mob">
                  {notifications.length > 1
                    ? intl.formatMessage({ id: "MENU.NOTIFICATIONS" })
                    : intl.formatMessage({ id: "MENU.NOTIFICATION" })}
                </span>
              )}
            </div>
          )}

          <span
            className="kt-pulse__ring"
            hidden={!pulse || props.displayWithText}
          />
          <span className="kt-hidden kt-badge kt-badge--dot kt-badge--notify kt-badge--sm"></span>
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <Card>
          {/** Head */}
          <div className="kt-head--skin-dark">
            {notifications.length !== 0 ? (
              <>
                <div className="card-header">
                  <div className="card-title mb-0 d-flex justify-content-between">
                    <h4 className="card-label mb-0">
                      <FormattedMessage id="MENU.NOTIFICATIONS" />
                    </h4>
                    <button
                      className="btn btn-sm font-weight-bold btn-font-primary mr-n4"
                      onClick={clearAll}
                    >
                      <FormattedMessage id="NOTIFICATION.CLEAR_ALL" />
                    </button>
                  </div>
                </div>
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "100vh",
                    position: "relative",
                    boxShadow: "inset #7b707040 0px 4px 9px 0px",
                  }}
                >
                  <div
                    className="kt-notification"
                    style={{ maxHeight: "40vh", position: "relative" }}
                  >
                    <div
                      className="kt-notification kt-scroll"
                      data-scroll="true"
                      data-height="300"
                      data-mobile-height="200"
                    >
                      {notifications.map((notification, index) =>
                        notification.type === "share" ? (
                          <div
                            className={`kt-notification__item ${
                              notification.status ? "" : "bg-light"
                            }`}
                            key={notification.id + index.toString()}
                          >
                            <div
                              className={`kt-notification__item-icon bg-${
                                colors[notification.color]?.iconColor
                              } justify-content-center kt-radius-50 ml-n2 mr-3 kt-iconbox-2 ${
                                notification.status ? "" : "kt-opacity-6"
                              }`}
                            >
                              <i
                                className={getIconClassName(
                                  notification.category_name
                                )}
                              />
                            </div>
                            <div className="kt-notification__item-details">
                              <div className="kt-notification__item-title">
                                <Link
                                  to="/invite_page"
                                  className={`wrap-overflow ${
                                    notification.status ? "" : "kt-opacity-6"
                                  }`}
                                >
                                  {notification.description}
                                </Link>
                                {notification.status && (
                                  <i
                                    className={`fas fa-circle kt-icon-size-xs ml-2 kt-font-${
                                      colors[notification.color]?.iconColor
                                    }`}
                                  />
                                )}
                              </div>
                              <div className="kt-notification__item-time wrap-overflow">
                                <FormattedMessage id="CALENDAR.SHARED_BY" />:{" "}
                                {notification.from}
                              </div>
                            </div>
                            <div>
                              <CustomTooltip
                                title={intl.formatMessage({
                                  id: "CALENDAR.SHARE.REJECT",
                                })}
                              >
                                <span
                                  className="pr-3 cursor-pointer"
                                  onClick={() =>
                                    reminderClickAction(
                                      notification,
                                      "rejected"
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="flaticon2-cancel-music kt-font-sm" />
                                </span>
                              </CustomTooltip>
                              <CustomTooltip
                                title={intl.formatMessage({
                                  id: "CALENDAR.SHARE.ACCEPT",
                                })}
                              >
                                <span
                                  onClick={() =>
                                    reminderClickAction(
                                      notification,
                                      "accepted"
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="flaticon2-check-mark" />
                                </span>
                              </CustomTooltip>
                            </div>
                          </div>
                        ) : notification.type === "group" ? (
                          <div
                            className={`kt-notification__item ${
                              notification.status ? "" : "bg-light"
                            }`}
                            key={notification.id + index}
                          >
                            <div
                              className={`kt-notification__item-icon bg-${
                                colors[notification.color]?.iconColor
                              } justify-content-center kt-radius-50 ml-n2 mr-3 kt-iconbox-2 ${
                                notification.status ? "" : "kt-opacity-6"
                              }`}
                            >
                              <i className="flaticon2-group kt-font-light" />
                            </div>
                            <div className="kt-notification__item-details">
                              <div className="kt-notification__item-title">
                                <Link
                                  to="/groups"
                                  className={`wrap-overflow ${
                                    notification.status ? "" : "kt-opacity-6"
                                  }`}
                                >
                                  {notification.group_name}
                                </Link>
                                {notification.status && (
                                  <i
                                    className={`fas fa-circle kt-icon-size-xs ml-2 kt-font-${
                                      colors[notification.color]?.iconColor
                                    }`}
                                  />
                                )}
                              </div>
                              <div className="kt-notification__item-time wrap-overflow">
                                <FormattedMessage id="CALENDAR.SHARED_BY" />:{" "}
                                {notification.from}
                              </div>
                            </div>
                            <div>
                              <CustomTooltip
                                title={intl.formatMessage({
                                  id: "NOTIFICATION.CLEAR_TOOLTIP",
                                })}
                              >
                                <span
                                  onClick={() => clearOne(notification)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="flaticon2-check-mark" />
                                </span>
                              </CustomTooltip>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`kt-notification__item ${
                              notification.status ? "" : "bg-light"
                            }`}
                            key={notification.id + index}
                          >
                            <div
                              className={`kt-notification__item-icon bg-${
                                colors[notification.color]?.iconColor
                              } justify-content-center kt-radius-50 ml-n2 mr-3 kt-iconbox-2 ${
                                notification.status ? "" : "kt-opacity-6"
                              }`}
                            >
                              <i
                                className={getIconClassName(
                                  notification.category_name
                                )}
                              />
                            </div>
                            <div className="kt-notification__item-details">
                              <div className="kt-notification__item-title">
                                <Link
                                  to={`/calendar/${notification.id}/${notification.event_date}`}
                                  className={`wrap-overflow ${
                                    notification.status ? "" : "kt-opacity-6"
                                  }`}
                                >
                                  {notification.description}
                                </Link>
                                {notification.status && (
                                  <i
                                    className={`fas fa-circle kt-icon-size-xs ml-2 kt-font-${
                                      colors[notification.color]?.iconColor
                                    }`}
                                  />
                                )}
                              </div>
                              <div className="kt-notification__item-time wrap-overflow">
                                {notification.group_name} -{" "}
                                {notification.category_name}
                              </div>
                            </div>
                            <div>
                              <CustomTooltip
                                title={intl.formatMessage({
                                  id: "NOTIFICATION.SNOOZE_TOOLTIP",
                                })}
                              >
                                <span
                                  className="pr-3 cursor-pointer"
                                  onClick={() => snoozeReminder(notification)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="flaticon2-time" />
                                </span>
                              </CustomTooltip>
                              <CustomTooltip
                                title={intl.formatMessage({
                                  id: "NOTIFICATION.CLEAR_TOOLTIP",
                                })}
                              >
                                <span
                                  onClick={() => clearOne(notification)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="flaticon2-check-mark" />
                                </span>
                              </CustomTooltip>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </PerfectScrollbar>
              </>
            ) : (
              <div
                className="kt-grid kt-grid--ver"
                style={{ minHeight: "75px" }}
              >
                <div className="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
                  <div className="kt-grid__item kt-grid__item--middle kt-align-center">
                    All caught up!
                    <br />
                    No new notifications.
                  </div>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(UserNotifications);
